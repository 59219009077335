import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import SchedulerForm from './SchedulerForm'

class SchedulerEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список планировщика ', link: '/scheduler' },
                {
                    title: 'Редактировать планировщик',
                    link: '/scheduler/update',
                },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование планировщика"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <SchedulerForm
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default SchedulerEdit
