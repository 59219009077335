import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookForm from '../HandbookForm'

class EmployeeUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Количество сотрудников',
                    link: '/handbooks/employee_quantity',
                },
                {
                    title: 'Редактировать количество сотрудников',
                    link: '/handbooks/employee_quantity/update',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.match.params.id}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookForm
                            title="Редактирование количества сотрудников"
                            method="post"
                            api="/handbook/employee_quantity/update"
                            redirect="/handbooks/employee_quantity"
                            one="/handbook/employee_quantity/one"
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default EmployeeUpdate
