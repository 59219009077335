import React, { Component } from 'react'
import {
    Button,
    Card,
    Col,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Progress,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import axios from 'axios'
import toastr from 'toastr'
import Dropzone from 'react-dropzone'
import 'toastr/build/toastr.min.css'
import user from './../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LocalizationElementField from '../../components/Common/LocalizationElementField'
import Select from 'react-select'
import AvSelect from '../../components/Common/AvSelect'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
        c
    ) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

class HandbookMainModuleNameForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: true,
            name: '',
            sort: 500,
            module: [],
            isUpdate: this.props.id,
            valueLang: '',
            file: null,
            uploadedFile: null,

            activeTab: 1,
            activeTabProgress: 1,
            langElement: null,
        }

        if (
            typeof this.props.fields != 'undefined' &&
            this.props.fields.length > 0
        ) {
            this.props.fields.forEach((element) => {
                this.state[element.name] = ''
            })
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.toggleTab.bind(this)
        this.handleSelectChangePictogram = this.handleSelectChangePictogram.bind(
            this
        )
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name,
                        sort: data.sort,
                        module: [{ id: data.module, name: data.module }],
                        uploadedFile: data.preview_picture,
                    })

                    if (
                        typeof this.props.fields != 'undefined' &&
                        this.props.fields.length > 0
                    ) {
                        this.props.fields.forEach((element) => {
                            this.setState({
                                [element.name]: data[element.name],
                            })
                        })
                    }
                })
        }
    }

    handleAcceptedFile = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                file: file,
                uploadedFile: null,
            })
        })
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab,
                })
            }
        }
    }

    updateData = (value) => {
        this.setState({ langElement: value })
    }

    errorTab(errors) {
        let pos = 0

        errors.forEach((element) => {
            pos = element.indexOf('lang')

            if (pos == -1) {
                this.toggleTab(1)
                return
            } else {
                this.toggleTab(2)
                return
            }
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        if (name === 'name') {
            this.setState({
                valueLang: value,
            })
        }

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })
        this.errorTab(errors)

        if (!errors.length) {
            const fd = new FormData()

            const data = {
                createdBy: user.userId,
                name: this.state.values.name,
                active: this.state.isActive,
                sort: this.state.sort,
                module: getIdFromSelect(this.state.module)[0],
                previewPicture: this.state.file || '',
            }

            if (this.state.langElement) {
                data['lang'] = this.state.langElement
            }

            if (
                typeof this.props.fields != 'undefined' &&
                this.props.fields.length > 0
            ) {
                this.props.fields.forEach((element) => {
                    data[element.name] = this.state[element.name]
                })
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            if (this.state.uploadedFile) {
                data.previewPicture = this.state.uploadedFile.id
            }

            if (data.previewPicture === null) {
                data.previewPicture = ''
            }

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    if (
                        typeof data[key] === 'object' &&
                        data[key] !== null &&
                        key !== 'previewPicture'
                    ) {
                        data[key].forEach((obj, k) => {
                            Object.entries(obj).forEach((item) => {
                                fd.append(`${key}[${k}][${item[0]}]`, item[1])
                            })
                        })
                    } else {
                        fd.append(key, data[key])
                    }
                }
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: fd,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push(this.props.redirect)
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    handleSelectChangePictogram(key, value, child) {
        const ids = getIdFromSelect(value)
        let file = null
        let _this = this

        if (ids.length) {
            const requests = ids.map((id) => {
                return axios.get(`/handbook/pictogram/one?id=${id}`)
            })

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            if (request.data.data.file.path) {
                                file = request.data.data.file
                            }
                        })
                    })
                )
                .finally(() => {
                    if (file !== null) {
                        var xhr = new XMLHttpRequest()
                        xhr.open('GET', file.path, true)
                        xhr.responseType = 'blob'
                        xhr.onload = function (e) {
                            if (this.status == 200) {
                                let filenew = new File(
                                    [this.response],
                                    file.name + '.' + file.type,
                                    { type: this.response.type }
                                )

                                Object.assign(filenew, {
                                    preview: URL.createObjectURL(filenew),
                                })

                                _this.setState({
                                    file: filenew,
                                    uploadedFile: null,
                                })
                            }
                        }
                        xhr.send()
                    }
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    className="needs-validation"
                    name="handbookForm"
                    id="handbook-form"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <Card>
                        <div
                            id="basic-pills-wizard"
                            className="twitter-bs-wizard"
                        >
                            <CardHeader>
                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li
                                        className={
                                            this.state.activeTab === 1
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(1)
                                            }}
                                        >
                                            Поля
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.state.activeTab === 2
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(2)
                                            }}
                                        >
                                            Локализация
                                        </Link>
                                    </li>
                                </ul>
                            </CardHeader>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={1}>
                                    <CardHeader>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h4 className="card-title mb-0">
                                                    {this.props.title}
                                                </h4>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="custom-control custom-switch">
                                                    <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        name="isActive"
                                                        id="isActive"
                                                        checked={
                                                            this.state.isActive
                                                        }
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        htmlFor="isActive"
                                                    >
                                                        Активен
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <AvField
                                            label="Наименование"
                                            name="name"
                                            placeholder="Введите наименование"
                                            value={this.state.name}
                                            onInput={this.handleInputChange}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        'Обязательно для заполнения',
                                                },
                                            }}
                                        />

                                        <AvField
                                            label="Сортировка"
                                            name="sort"
                                            placeholder="Введите планируемый объем инвестиций"
                                            value={this.state.sort}
                                            onInput={this.handleInputChange}
                                            validate={{
                                                pattern: {
                                                    value: '^[1-9][0-9]*$',
                                                    errorMessage:
                                                        'Неверное значение. Введите только целые числа.',
                                                },
                                            }}
                                        />

                                        <AvSelect
                                            label="Модуль"
                                            name="module"
                                            value={this.state.module}
                                            api={
                                                '/handbook/main_module_name/module_list'
                                            }
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            errorMessage="Обязательно для заполнения"
                                        />
                                    </CardBody>

                                    <Card>
                                        <CardHeader>
                                            <h4 className="card-title mb-0">
                                                Файл
                                            </h4>
                                        </CardHeader>

                                        <CardBody>
                                            <Dropzone
                                                maxFiles={1}
                                                multiple={false}
                                                onDrop={(acceptedPreview) =>
                                                    this.handleAcceptedFile(
                                                        acceptedPreview
                                                    )
                                                }
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                }) => (
                                                    <div
                                                        className={
                                                            this.state.file ||
                                                            this.state
                                                                .uploadedFile
                                                                ? 'dropzone dropzone-single dz-max-files-reached'
                                                                : 'dropzone dropzone-single'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                            </div>
                                                            <h4>
                                                                Перетащите
                                                                картинку сюда
                                                                или кликните для
                                                                загрузки
                                                            </h4>
                                                        </div>

                                                        {this.state.file && (
                                                            <div className="dz-preview dz-preview-single">
                                                                <div className="dz-preview-cover">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        className="dz-preview-img"
                                                                        style={{
                                                                            width:
                                                                                'auto',
                                                                            height:
                                                                                '100%',
                                                                            overflow:
                                                                                'hidden',
                                                                        }}
                                                                        alt={
                                                                            this
                                                                                .state
                                                                                .file
                                                                                .name
                                                                        }
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .file
                                                                                .preview
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state
                                                            .uploadedFile && (
                                                            <div className="dz-preview dz-preview-single">
                                                                <div className="dz-preview-cover">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        className="dz-preview-img"
                                                                        style={{
                                                                            width:
                                                                                'auto',
                                                                            height:
                                                                                '100%',
                                                                            overflow:
                                                                                'hidden',
                                                                        }}
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .uploadedFile
                                                                                .path
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Dropzone>

                                            <Col>
                                                <CardBody>
                                                    <AvSelect
                                                        label="Выбрать пиктограмму"
                                                        name="pictogram"
                                                        value={
                                                            this.state.pictogram
                                                        }
                                                        api="/handbook/pictogram/input_list"
                                                        handleSelectChange={
                                                            this
                                                                .handleSelectChangePictogram
                                                        }
                                                    />
                                                </CardBody>
                                            </Col>

                                            <AvField
                                                type="hidden"
                                                name="file"
                                                value={
                                                    this.state.file ||
                                                    this.state.uploadedFile
                                                }
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>

                                <TabPane tabId={2}>
                                    <CardBody>
                                        <LocalizationElementField
                                            elementId={this.props.id}
                                            updateData={this.updateData}
                                            module="handbook"
                                            field="name"
                                            defaultLang="ru"
                                            defaultValue={this.state.valueLang}
                                            langElement={this.state.langElement}
                                        />
                                    </CardBody>
                                </TabPane>
                            </TabContent>

                            <CardFooter>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default HandbookMainModuleNameForm
