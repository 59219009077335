import React, { Component } from 'react'
import { object } from 'prop-types'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import HandbookList from '../HandbookList'

class Colors extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Цвета', link: '/handbooks/colors' },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookList
                            type="colors"
                            create="Добавить цвет"
                            history={this.props.history}
                            customColumns={[
                                {
                                    label: 'Цвет',
                                    field: 'color',
                                    sort: '',
                                    width: 150,
                                    render: (value) => {
                                        return (
                                            <span style={{ color: value }}>
                                                {value}
                                            </span>
                                        )
                                    },
                                },
                            ]}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Colors.propTypes = {
    history: object,
}

export default Colors
