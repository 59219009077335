import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Progress,
} from 'reactstrap'
import Select from 'react-select'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import AvSelect from '../../../components/Common/AvSelect'
import AvMultipleInput from '../../../components/Common/AvMultipleInput'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import user from '../../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LocalizationElementField from '../../../components/Common/LocalizationElementField'

const getIdFromSelect = (arr, one = false) => {
    if (!one) {
        arr = arr || []
        const ids = []
        arr.map((el) => ids.push(el.id))
        return ids
    } else {
        arr = arr || []
        let value = null
        if (arr.length && arr[0] !== null) {
            value = arr[0].id
        }

        if (value == '') return null

        return value
    }
}

class UserForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: null,
            password: null,
            confirm_password: null,
            photo: null,
            role: null,
            status: null,
            name: null,
            surname: null,
            company_name: null,
            position: null,
            phone: null,
            name_legal_entity: null,
            corporate_email: null,
            organization_registr_number: null,
            organization_address: null,
            confirmation_legal_entity: true,
            confirmation_mailing: false,
            contentDisplay: true,
            gender: null,
            paymentCurrency: null,
            lang: null,
            user_country: null,
            user_category: null,
            country: [],
            industry: [],
            package: [],
            subscriptionMonth: null,
            subscriptionWorkplaces: null,
            message: null,
            isUpdate: this.props.id,
            optionsStatus: [
                { name: 'Ожидание', id: 'wait' },
                { name: 'Активный', id: 'active' },
                { name: 'Неактивный', id: 'inactive' },
            ],
            statusNameMap: {
                wait: 'Ожидание',
                active: 'Активный',
                inactive: 'Неактивный',
            },
            optionsRoleRequestStatus: [
                { name: 'Запрос принят', id: 'accepted' },
                { name: 'Ожидает подтверждения', id: 'wait' },
                { name: 'Подтвержден', id: 'confirmend' },
                { name: 'Отклонен', id: 'denied' },
            ],
            statusRoleRequestNameMap: {
                accepted: 'Запрос принят',
                wait: 'Ожидает подтверждения',
                confirmend: 'Подтвержден',
                denied: 'Отклонен',
            },
            roleRequest: null,
            roleRequestStatus: null,
            confirmend: false,
            denied: false,
            optionsWorkplaces: [],
            optionsMonth: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleToggleChange = this.handleToggleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        email: data.email,
                        photo: data.photo,
                        role: data.role,
                        status: data.status,
                        name: data.name,
                        surname: data.surname,
                        company_name: data.company_name,
                        position: data.position,
                        phone: data.phone,
                        name_legal_entity: data.name_legal_entity,
                        corporate_email: data.corporate_email,
                        organization_registr_number:
                            data.organization_registr_number,
                        organization_address: data.organization_address,
                        confirmation_legal_entity:
                            data.confirmation_legal_entity,
                        confirmation_mailing: data.confirmation_mailing,
                        contentDisplay: data.content_display,
                        gender: data.gender,
                        paymentCurrency: data.payment_currency,
                        lang: data.lang,
                        user_country: data.user_country,
                        user_category: data.user_category,
                        country: data.country,
                        industry: data.industry,
                        package: data.package,
                        subscriptionMonth: [
                            {
                                name: data.subscription_month,
                                id: data.subscription_month,
                            },
                        ],
                        subscriptionWorkplaces: [
                            {
                                name: data.subscription_workplaces,
                                id: data.subscription_workplaces,
                            },
                        ],
                        message: data.message,
                        roleRequestStatus: data.role_request_status,
                        roleRequest: data.role_rquest,
                    })

                    this.handleSelectChange('role', [data.role])
                    this.handleSelectChange('roleRequest', [data.role_rquest])
                    this.handleSelectChange('status', [
                        {
                            name: this.state.statusNameMap[data.status],
                            id: data.status,
                        },
                    ])
                    this.handleSelectChange('gender', [data.gender])
                    this.handleSelectChange('paymentCurrency', [data.payment_currency])

                    if (data.lang.id) {
                        this.handleSelectChange('lang', [data.lang])
                    }

                    this.handleSelectChange('user_country', [data.user_country])
                    this.handleSelectChange('user_category', [
                        data.user_category,
                    ])
                    this.handleSelectChange('country', data.country)
                    this.handleSelectChange('industry', data.industry)
                    this.handleSelectChange('package', [data.package])
                    this.handleSelectChange('subscriptionMonth', [
                        {
                            name: data.subscription_month,
                            id: data.subscription_month,
                        },
                    ])
                    this.handleSelectChange('subscriptionWorkplaces', [
                        {
                            name: data.subscription_workplaces,
                            id: data.subscription_workplaces,
                        },
                    ])

                    if (
                        typeof this.props.fields !== 'undefined' &&
                        this.props.fields.length > 0
                    ) {
                        this.props.fields.forEach((element) => {
                            this.setState({
                                [element.name]: data[element.name],
                            })
                        })
                    }
                })
        }

        const month = []
        const workplaces = []

        axios
            .get('/package/data_list')
            .then((response) => {
                month.push({ id: '', name: '(не выбрано)' })
                workplaces.push({ id: '', name: '(не выбрано)' })

                for (const m in response.data.month) {
                    month.push({
                        id: response.data.month[m],
                        name: response.data.month[m],
                    })
                }

                for (const w in response.data.workplaces) {
                    workplaces.push({
                        id: response.data.workplaces[w],
                        name: response.data.workplaces[w],
                    })
                }

                this.setState({
                    optionsMonth: month,
                    optionsWorkplaces: workplaces,
                })
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleToggleChange(event) {
        const target = event.target
        document
            .querySelectorAll('[data-toggle="button"]')
            .forEach((element) => {
                if (target.dataset.name === element.dataset.name) {
                    if (target.classList.contains('btn-primary')) {
                        target.classList.add('btn-success')
                        target.classList.remove('btn-primary')

                        this.setState({
                            [element.dataset.name]: true,
                        })
                    } else if (target.classList.contains('btn-success')) {
                        target.classList.add('btn-primary')
                        target.classList.remove('btn-success')

                        this.setState({
                            [element.dataset.name]: false,
                        })
                    }
                } else {
                    element.classList.add('btn-primary')
                    element.classList.remove('btn-success')
                    this.setState({
                        [element.dataset.name]: false,
                    })
                }
            })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()

            axios
                .post(this.props.api, fd)
                .then((response) => {
                    if (response.status === 200) {
                        toastr.success(
                            `Пользователь <b>${this.state.values.name}</b> успешно обновлен`
                        )
                        this.props.history.push('/user_list')
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (
                            err.response &&
                            err.response.status === 409 &&
                            err.response.data.message == 'unique'
                        ) {
                            toastr.error(
                                `<b>Символьный код должен быть уникальным!</b>`
                            )
                        } else if (err.response.data.message) {
                            toastr.error(
                                `<b>` + err.response.data.message + `</b>`
                            )
                        }
                        const errors = err.response.data.errors
                        for (var key in errors) {
                            toastr.error(
                                `<b>` + key + ':' + errors[key] + `</b>`
                            )
                        }
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            createdBy: user.userId,
            email: this.state.email,
            role: getIdFromSelect(this.state.role, true),
            roleRequest: getIdFromSelect(this.state.roleRequest, true),
            status: getIdFromSelect(this.state.status, true),
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            name: this.state.name,
            surname: this.state.surname,
            company_name: this.state.company_name,
            position: this.state.position,
            phone: this.state.phone,
            corporate_email: this.state.corporate_email,
            name_legal_entity: this.state.name_legal_entity,
            organization_registr_number: this.state.organization_registr_number,
            organization_address: this.state.organization_address,
            confirmation_legal_entity: this.state.confirmation_legal_entity,
            confirmation_mailing: this.state.confirmation_mailing,
            contentDisplay: this.state.contentDisplay,
            gender: getIdFromSelect(this.state.gender, true),
            paymentCurrency: getIdFromSelect(this.state.paymentCurrency, true),
            lang: getIdFromSelect(this.state.lang, true),
            user_country: getIdFromSelect(this.state.user_country, true),
            user_category: getIdFromSelect(this.state.user_category, true),
            country: getIdFromSelect(this.state.country),
            industry: getIdFromSelect(this.state.industry),
            package: getIdFromSelect(this.state.package, true),
            message: this.state.message,
            confirmend: this.state.confirmend,
            denied: this.state.denied,
            subscriptionMonth: getIdFromSelect(
                this.state.subscriptionMonth,
                true
            ),
            subscriptionWorkplaces: getIdFromSelect(
                this.state.subscriptionWorkplaces,
                true
            ),
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = user.userId

            if (this.state.photo) {
                data.photo = this.state.photo.id
            }
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'photo'
                ) {
                    data[key].map((el) => fd.append(`${key}[]`, el))
                } else {
                    fd.append(key, data[key])
                }
            }
        }

        return data
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="role_group">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0"></div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="E-mail"
                                name="email"
                                placeholder="Введите E-mail"
                                value={this.state.email}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvSelect
                                label="Статус"
                                name="status"
                                options={this.state.optionsStatus}
                                value={this.state.status}
                                errorMessage="Обязательно для заполнения"
                                handleSelectChange={this.handleSelectChange}
                            />

                            <AvSelect
                                label="Роль"
                                name="role"
                                api="/role/input_list"
                                value={this.state.role}
                                errorMessage="Обязательно для заполнения"
                                handleSelectChange={this.handleSelectChange}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Пароль</h4>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Пароль"
                                name="password"
                                placeholder="Введите пароль"
                                value=""
                                onInput={this.handleInputChange}
                                validate={{
                                    pattern: {
                                        value:
                                            '^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,72}$',
                                        errorMessage:
                                            'Пароль должен быть не меньше 6 символов и содержать заглавные и строчные буквы.',
                                    },
                                }}
                            />
                            <AvField
                                label="Подтверждение пароля"
                                name="confirm_password"
                                placeholder="Введите подтверждение пароля"
                                value=""
                                onInput={this.handleInputChange}
                                validate={{
                                    pattern: {
                                        value:
                                            '^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,72}$',
                                        errorMessage:
                                            'Пароль должен быть не меньше 6 символов и содержать заглавные и строчные буквы.',
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Запрос на доступ
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Row>
                                <Col>
                                    <AvSelect
                                        label="Роль"
                                        name="roleRequest"
                                        api="/role/input_list"
                                        value={this.state.roleRequest}
                                        errorMessage="Обязательно для заполнения"
                                        handleSelectChange={
                                            this.handleSelectChange
                                        }
                                    />
                                </Col>
                                <Col>
                                    <div>
                                        <h5 className="font-size-14 mb-4">
                                            Статус
                                        </h5>
                                        {this.state.roleRequestStatus
                                            ? this.state
                                                  .statusRoleRequestNameMap[
                                                  this.state.roleRequestStatus
                                              ]
                                            : ''}
                                    </div>
                                </Col>
                                <Col>
                                    <h5 className="font-size-14 mb-4"></h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex">
                                            <div className="custom-control custom-switch">
                                                <button
                                                    type="button"
                                                    data-name="confirmend"
                                                    className="waves-effect waves-light btn btn-primary"
                                                    data-toggle="button"
                                                    onClick={
                                                        this.handleToggleChange
                                                    }
                                                >
                                                    Утвердить
                                                </button>
                                            </div>

                                            <div className="custom-control custom-switch ml-4">
                                                <button
                                                    type="button"
                                                    data-name="denied"
                                                    className="waves-effect waves-light btn btn-primary"
                                                    data-toggle="button"
                                                    onClick={
                                                        this.handleToggleChange
                                                    }
                                                >
                                                    Отклонить
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Настройки</h4>
                        </CardHeader>

                        <CardBody>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvField
                                            label="Имя"
                                            name="name"
                                            placeholder="Введите имя"
                                            value={this.state.name}
                                            onInput={this.handleInputChange}
                                            // validate={{
                                            //     pattern: {
                                            //         value:
                                            //             '^[а-яА-ЯёЁa-zA-Z]+$',
                                            //         errorMessage:
                                            //             'Только латиница и кириллица.',
                                            //     },
                                            // }}
                                        />
                                    </Col>
                                    <Col>
                                        <AvField
                                            label="Фамилия"
                                            name="surname"
                                            placeholder="Введите фамилию"
                                            value={this.state.surname}
                                            onInput={this.handleInputChange}
                                            // validate={{
                                            //     pattern: {
                                            //         value:
                                            //             '^[а-яА-ЯёЁa-zA-Z]+$',
                                            //         errorMessage:
                                            //             'Только латиница и кириллица.',
                                            //     },
                                            // }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvField
                                            label="Наименование компании / организации"
                                            name="company_name"
                                            placeholder="Введите наименование компании / организации"
                                            value={this.state.company_name}
                                            onInput={this.handleInputChange}
                                        />
                                    </Col>
                                    <Col>
                                        <AvField
                                            label="Должность"
                                            name="position"
                                            placeholder="Введите должность"
                                            value={this.state.position}
                                            onInput={this.handleInputChange}
                                            validate={{
                                                pattern: {
                                                    value:
                                                        '^[а-яА-ЯёЁa-zA-Z\\s]+$',
                                                    errorMessage:
                                                        'Только латиница и кириллица.',
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvField
                                            label="Номер телефона"
                                            name="phone"
                                            placeholder="Введите телефон"
                                            value={this.state.phone}
                                            onInput={this.handleInputChange}
                                        />
                                    </Col>
                                    <Col>
                                        <AvField
                                            label="Корпоративный e-mail"
                                            name="corporate_email"
                                            placeholder="Введите корпоративный e-mail"
                                            value={this.state.corporate_email}
                                            onInput={this.handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvField
                                            label="Регистрационный номер компании"
                                            name="organization_registr_number"
                                            placeholder="Введите регистрационный номер компании"
                                            value={
                                                this.state
                                                    .organization_registr_number
                                            }
                                            onInput={this.handleInputChange}
                                        />
                                    </Col>
                                    <Col>
                                        <AvField
                                            label="Адрес нахождения организации"
                                            name="organization_address"
                                            placeholder="Введите адрес нахождения организации"
                                            value={
                                                this.state.organization_address
                                            }
                                            onInput={this.handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <AvField
                                label="Сообщение"
                                name="message"
                                type="textarea"
                                cols="40"
                                rows="5"
                                placeholder="Введите сообщение"
                                value={this.state.message}
                                onInput={this.handleInputChange}
                            />

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Обращение (Пол)"
                                            name="gender"
                                            api="/handbook/gender/input_list"
                                            value={this.state.gender}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <AvSelect
                                            label="Языковые предпочтения"
                                            name="lang"
                                            api="/localization/lang/input_list?main=true"
                                            value={this.state.lang}
                                            errorMessage="Обязательно для заполнения"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Страна"
                                            name="user_country"
                                            api="/handbook/user_country/input_list"
                                            value={this.state.user_country}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <AvSelect
                                            label="Категория"
                                            name="user_category"
                                            api="/handbook/user_category/input_list"
                                            value={this.state.user_category}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Целевые рынки (страны)"
                                            name="country"
                                            value={this.state.country}
                                            api="/handbook/country/input_list"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            multiple
                                        />
                                    </Col>
                                    <Col>
                                        <AvSelect
                                            label="Отрасли"
                                            name="industry"
                                            value={this.state.industry}
                                            api="/handbook/industry/input_list"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            multiple
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <AvField
                                    label="Название юридического лица"
                                    name="name_legal_entity"
                                    placeholder="Введите название юридического лица"
                                    value={this.state.name_legal_entity}
                                    onInput={this.handleInputChange}
                                    // validate={{
                                    //     pattern: {
                                    //         value: '^[а-яА-ЯёЁa-zA-Z0-9\\s\-!№;%:?*.,@#$<>«»\'"`”(){}\[\]]{0,240}$',
                                    //         errorMessage:
                                    //             'Только латиница и кириллица.',
                                    //     },
                                    // }}
                                />
                            </FormGroup>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Подтверждения</h4>
                        </CardHeader>

                        <CardBody>
                            <div className="custom-control custom-switch">
                                <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="confirmation_legal_entity"
                                    id="confirmation_legal_entity"
                                    checked={
                                        this.state.confirmation_legal_entity
                                    }
                                    onChange={this.handleInputChange}
                                />
                                <Label
                                    className="custom-control-label"
                                    htmlFor="confirmation_legal_entity"
                                >
                                    Подтверждение пользователем, что он является
                                    уполномоченным представителем указанного
                                    юридического лица или индивидуального
                                    предпринимателя
                                </Label>
                            </div>

                            <div className="custom-control custom-switch">
                                <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="confirmation_mailing"
                                    id="confirmation_mailing"
                                    checked={this.state.confirmation_mailing}
                                    onChange={this.handleInputChange}
                                />
                                <Label
                                    className="custom-control-label"
                                    htmlFor="confirmation_mailing"
                                >
                                    Согласие на получение информационной
                                    рассылки “Export.Link”
                                </Label>
                            </div>
                            <div className="custom-control custom-switch">
                                <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="contentDisplay"
                                    id="contentDisplay"
                                    checked={this.state.contentDisplay}
                                    onChange={this.handleInputChange}
                                />
                                <Label
                                    className="custom-control-label"
                                    htmlFor="contentDisplay"
                                >
                                    Отображение только того контента, который
                                    входит в пакет пользователя
                                </Label>
                            </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Предпочтительная подписка
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Тарифный план"
                                            name="package"
                                            value={this.state.package}
                                            api="/package/input_list"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <AvSelect
                                            label="Период подписки"
                                            name="subscriptionMonth"
                                            value={this.state.subscriptionMonth}
                                            options={this.state.optionsMonth}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <AvSelect
                                            label="Количество рабочих мест"
                                            name="subscriptionWorkplaces"
                                            value={
                                                this.state
                                                    .subscriptionWorkplaces
                                            }
                                            options={
                                                this.state.optionsWorkplaces
                                            }
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Валюта платежа"
                                            name="paymentCurrency"
                                            api="/handbook/payment_currency/input_list"
                                            value={this.state.paymentCurrency}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </CardBody>
                    </Card>

                    <CardFooter>
                        <div className="d-flex align-items-center justify-content-end">
                            <Button
                                color="success"
                                className="waves-effect waves-light"
                            >
                                Сохранить{' '}
                                <i className="ri-check-line align-middle ml-1"></i>
                            </Button>
                        </div>
                    </CardFooter>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default UserForm
