import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookFormRelatedAndFile from '../HandbookFormRelatedAndFile'

class RegionCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список регионов', link: '/handbooks/region' },
                { title: 'Добавить регион', link: '/handbooks/region/create' },
            ],
            select: {
                options: [],
                label: 'Выберите страну',
                placeholder: 'Выберите опцию',
                errorMessage: 'Обязательно для заполнения',
                name: 'country',
                api: '/handbook/country/input_list',
            },
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый регион"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookFormRelatedAndFile
                            title="Создание региона"
                            method="post"
                            api="/handbook/region/create"
                            redirect="/handbooks/region"
                            history={this.props.history}
                            select={this.state.select}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RegionCreate
