import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    Button,
} from 'reactstrap'

import AvSelect from '../../components/Common/AvSelect'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import toastr from 'toastr'
import { translit, formatData, uuidv4, getDateToday } from '../../utils/utils'
import PropTypes from 'prop-types'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

const getFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push({ id: el.id, name: el.name }))
    return ids
}

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class AnalyticsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список аналитики', link: '/analytics' },
            ],

            isActive: true,
            publiclyAvailable: false,
            code: '',
            name_ru: '',
            name_en: null,
            // title_ru: '',
            // title_en: '',
            // text_ru: EditorState.createEmpty(),
            // text_en: EditorState.createEmpty(),
            names: [],
            names_options: [],
            date_publication: '',
            rubric_analytics: [],
            country: [],
            region: [],
            industry: [],
            sub_industry: [],
            // analytics_task: [],
            graphs: [],
            lang: [],

            previewPicture: null,
            uploadedPreview: null,
            substrate: '',
            uploadedSubstrate: '',

            regionOptions: [],
            subIndustryOptions: [],

            isUpdate: this.props.id,
            analytics_sources: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSelectChangePictogram = this.handleSelectChangePictogram.bind(
            this
        )
        this.addInputSignNames = this.addInputSignNames.bind(this)
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios.get(`/analytics/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data

                this.setState({
                    isActive: data.active,
                    publiclyAvailable: data.publicly_available,
                    name_ru: data.name_ru,
                    name_en: data.name_en,
                    // title_ru: data.title_ru || '',
                    // title_en: data.title_en || '',
                    // text_ru: this.getEditorContent(data.text_ru || ''),
                    // text_en: this.getEditorContent(data.text_en || ''),
                    code: data.code,
                    names: data.names,
                    date_publication: data.date_publication.replace(' ', 'T'),
                    rubric_analytics: data.rubric,
                    country: data.countries,
                    region: data.regions,
                    industry: data.industry,
                    sub_industry: data.sub_industry,
                    // analytics_task: data.task,
                    graphs: data.graphs,
                    uploadedPreview: data.preview_picture,
                    uploadedSubstrate: data.substrate,
                    isLoadingSelectNames: false,
                    analytics_sources: data.analytics_sources,
                })

                if (data.lang.id) {
                    this.setState({ lang: [data.lang] })
                } else {
                    this.setState({ lang: [{ id: 'null', name: 'Все' }] })
                }

                this.handleSelectChange('country', data.countries, {
                    key: 'region',
                    options: 'regionOptions',
                })
                this.handleSelectChange('industry', data.industry, {
                    key: 'sub_industry',
                    options: 'subIndustryOptions',
                })
            })
        } else {
            this.setState({ date_publication: getDateToday() })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        if (name === 'name_ru' && !this.state.isUpdate) {
            this.setState({
                code: translit(value.trim()),
            })
        }
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    addInputSignNames(event) {
        event.preventDefault()

        const target = event.target
        const value = target.value
        const id = uuidv4()

        this.state.names.push({ id: id, name: value })

        this.setState({
            names: this.state.names,
            names_options: [{ id: id, name: value }],
        })
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    handleSelectChangePictogram(key, value, child) {
        const ids = getIdFromSelect(value)
        let file = null
        const _this = this

        if (ids.length) {
            const requests = ids.map((id) => {
                return axios.get(`/handbook/pictogram/one?id=${id}`)
            })

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            console.log()
                            if (request.data.data.file.path) {
                                file = request.data.data.file
                            }
                        })
                    })
                )
                .finally(() => {
                    if (file !== null) {
                        var xhr = new XMLHttpRequest()
                        xhr.open('GET', file.path, true)
                        xhr.responseType = 'blob'
                        xhr.onload = function (e) {
                            if (this.status === 200) {
                                const filenew = new File(
                                    [this.response],
                                    file.name + '.' + file.type,
                                    { type: this.response.type }
                                )

                                Object.assign(filenew, {
                                    preview: URL.createObjectURL(filenew),
                                })

                                _this.setState({
                                    previewPicture: filenew,
                                    uploadedPreview: null,
                                })
                            }
                        }
                        xhr.send()
                    }
                })
        }
    }

    handleAcceptedPreview = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                previewPicture: file,
                uploadedPreview: '',
            })
        })
    }

    handleAcceptedSubstrate = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                substrate: file,
                uploadedSubstrate: '',
            })
        })
    }

    handleSubmit(event, errors, values) {
        for (const i in values) {
            if (values[i] === 'value') {
                values[i] = null
            }
        }

        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/analytics/update'
                : '/analytics/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/analytics')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message === 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    const errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            createdBy: jsonUser.userId,
            code: this.state.code,
            name_ru: this.state.name_ru,
            name_en: this.state.name_en,
            // title_ru: this.state.title_ru,
            // title_en: this.state.title_en,
            // text_ru: draftToHtml(convertToRaw(this.state.text_ru.getCurrentContent())),
            // text_en: draftToHtml(convertToRaw(this.state.text_en.getCurrentContent())),
            names: getFromSelect(this.state.names),
            date_publication: this.state.date_publication.replace('T', ' '),
            previewPicture: this.state.previewPicture,
            substrate: this.state.substrate,
            rubric_analytics: getIdFromSelect(this.state.rubric_analytics),
            country: getIdFromSelect(this.state.country),
            region: getIdFromSelect(this.state.region),
            industry: getIdFromSelect(this.state.industry),
            sub_industry: getIdFromSelect(this.state.sub_industry),
            // analytics_task: getIdFromSelect(this.state.analytics_task),
            graphs: getIdFromSelect(this.state.graphs),
            analytics_sources: getIdFromSelect(this.state.analytics_sources),
            active: this.state.isActive,
            publiclyAvailable: this.state.publiclyAvailable,
            lang: getIdFromSelect(this.state.lang)[0],
        }
        // todo move to utils
        for (const i in data) {
            if (data[i] === 'null') {
                data[i] = null
            }
            if (data[i] === null) {
                data[i] = ''
            }
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            if (this.state.uploadedPreview) {
                data.previewPicture = this.state.uploadedPreview.id
            }

            if (this.state.uploadedSubstrate) {
                data.substrate = this.state.uploadedSubstrate.id
            }
        }

        for (const key in data) {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'previewPicture' &&
                    key !== 'substrate'
                ) {
                    if (data[key][0] && typeof data[key][0] === 'object') {
                        data[key].forEach((obj, k) => {
                            Object.entries(obj).forEach((item) => {
                                fd.append(`${key}[${k}][${item[0]}]`, item[1])
                            })
                        })
                    } else {
                        data[key].map((el) => fd.append(`${key}[]`, el))
                    }
                } else {
                    fd.append(key, data[key])
                }
            }
        }

        return fd
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="associations"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="publiclyAvailable"
                                                id="publiclyAvailable"
                                                checked={
                                                    this.state.publiclyAvailable
                                                }
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="publiclyAvailable"
                                            >
                                                Сделать общедоступными
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название внутреннее"
                                name="name_ru"
                                placeholder="Введите название внутреннее"
                                value={this.state.name_ru}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            {/* <AvField
                label="Название Англ."
                name="name_en"
                placeholder="Введите название Англ."
                value={this.state.name_en}
                onInput={this.handleInputChange}
              /> */}
                        </CardBody>

                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">
                                    Составное наименование
                                </h4>
                            </CardHeader>

                            <CardBody>
                                <AvSelect
                                    label="Название публичное"
                                    name="names"
                                    value={this.state.names}
                                    optionsNew={this.state.names_options}
                                    api={[
                                        '/handbook/country/input_list',
                                        '/handbook/region/input_list',
                                        '/handbook/analytics_name/input_list',
                                        '/handbook/time_periods/input_list',
                                    ]}
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                    isLoading={this.state.isLoadingSelectNames}
                                    multiple
                                />
                                <FormGroup>
                                    <Row>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value="."
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (точка)
                                        </Button>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value=","
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (запятая)
                                        </Button>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value="-"
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (тире)
                                        </Button>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value=":"
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (двоеточие)
                                        </Button>
                                    </Row>
                                </FormGroup>
                            </CardBody>
                        </Card>

                        <CardBody>
                            <AvField
                                label="Символьный код"
                                name="code"
                                placeholder="Введите символьный код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z0-9--]{4,255}$',
                                        errorMessage: 'Неверный символьный код',
                                    },
                                }}
                            />
                        </CardBody>

                        {/* <CardBody>
              <AvField
                label="Заголовок Рус."
                name="title_ru"
                placeholder="Введите заголовок Рус."
                value={this.state.title_ru}
                onInput={this.handleInputChange}
              />

              <AvField
                label="Заголовок Англ."
                name="title_en"
                placeholder="Введите заголовок Англ."
                value={this.state.title_en}
                onInput={this.handleInputChange}
              />
            </CardBody> */}

                        <CardBody>
                            <FormGroup>
                                <Label>Дата публикации</Label>
                                <Input
                                    className="form-control"
                                    type="datetime-local"
                                    defaultValue={this.state.date_publication}
                                    onInput={(event) =>
                                        this.setState({
                                            date_publication: formatData(
                                                event.target.value
                                            ),
                                        })
                                    }
                                />
                            </FormGroup>
                        </CardBody>

                        <CardBody>
                            <AvSelect
                                label="Где показывать"
                                name="lang"
                                optionNew={[{ id: 'null', name: 'Все' }]}
                                value={this.state.lang}
                                api="/localization/lang/input_list?main=true"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>

                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">
                                    Картинка для анонса
                                </h4>
                            </CardHeader>

                            <CardBody>
                                <Dropzone
                                    maxFiles={1}
                                    multiple={false}
                                    onDrop={(acceptedPreview) =>
                                        this.handleAcceptedPreview(
                                            acceptedPreview
                                        )
                                    }
                                    accept="image/*"
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            className={
                                                this.state.previewPicture ||
                                                this.state.uploadedPreview
                                                    ? 'dropzone dropzone-single dz-max-files-reached'
                                                    : 'dropzone dropzone-single'
                                            }
                                            style={{ textAlign: 'center' }}
                                        >
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                </div>
                                                <h4>
                                                    Перетащите картинку сюда или
                                                    кликните для загрузки
                                                </h4>
                                            </div>

                                            {this.state.previewPicture && (
                                                <div className="dz-preview dz-preview-single">
                                                    <div className="dz-preview-cover">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="dz-preview-img"
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                            alt={
                                                                this.state
                                                                    .previewPicture
                                                                    .name
                                                            }
                                                            src={
                                                                this.state
                                                                    .previewPicture
                                                                    .preview
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.uploadedPreview && (
                                                <div className="dz-preview dz-preview-single">
                                                    <div className="dz-preview-cover">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="dz-preview-img"
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                            src={
                                                                this.state
                                                                    .uploadedPreview
                                                                    .path
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>

                                <Col>
                                    <CardBody>
                                        <AvSelect
                                            label="Выбрать пиктограмму"
                                            name="pictogram"
                                            value={this.state.pictogram}
                                            api="/handbook/pictogram/input_list"
                                            handleSelectChange={
                                                this.handleSelectChangePictogram
                                            }
                                        />
                                    </CardBody>
                                </Col>

                                <AvField
                                    type="hidden"
                                    name="previewPicture"
                                    value={
                                        this.state.previewPicture ||
                                        this.state.uploadedPreview
                                    }
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage:
                                                'Обязательно для заполнения',
                                        },
                                    }}
                                />
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">Подложка</h4>
                            </CardHeader>

                            <CardBody>
                                <Dropzone
                                    maxFiles={1}
                                    multiple={false}
                                    onDrop={(acceptedSubstrate) =>
                                        this.handleAcceptedSubstrate(
                                            acceptedSubstrate
                                        )
                                    }
                                    accept="image/*"
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            className={
                                                this.state.substrate ||
                                                this.state.uploadedSubstrate
                                                    ? 'dropzone dropzone-single dz-max-files-reached'
                                                    : 'dropzone dropzone-single'
                                            }
                                            style={{ textAlign: 'center' }}
                                        >
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                </div>
                                                <h4>
                                                    Перетащите картинку сюда или
                                                    кликните для загрузки
                                                </h4>
                                            </div>

                                            {this.state.substrate && (
                                                <div className="dz-preview dz-preview-single">
                                                    <div className="dz-preview-cover">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="dz-preview-img"
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                            alt={
                                                                this.state
                                                                    .substrate
                                                                    .name
                                                            }
                                                            src={
                                                                this.state
                                                                    .substrate
                                                                    .preview
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.uploadedSubstrate && (
                                                <div className="dz-preview dz-preview-single">
                                                    <div className="dz-preview-cover">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="dz-preview-img"
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                            src={
                                                                this.state
                                                                    .uploadedSubstrate
                                                                    .path
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>

                                {/*
                <div
                  className="dropzone-previews mt-3"
                  id="file-previews"
                >
                  {this.state.substrate &&       
                      <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={"1-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={this.state.substrate.name}
                              src={this.state.substrate.preview}
                            />
                          </Col>
                          <Col>
                            <span className="text-muted font-weight-bold">{this.state.substrate.name}</span>
                          </Col>
                        </Row>
                        <div className="mt-2">
                          <span className="text-danger" style={{cursor: 'pointer'}} onClick={() => this.setState({ substrate: '' })}>Удалить</span>
                        </div>
                      </div>
                    </Card>    
                  }

                  {this.state.uploadedSubstrate &&       
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={"1-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={this.state.uploadedSubstrate.name}
                              src={this.state.uploadedSubstrate.preview}
                            />
                          </Col>
                          <Col>
                            <span className="text-muted font-weight-bold">{this.state.uploadedSubstrate.name}</span>
                          </Col>
                        </Row>
                        <div className="mt-2">
                          <span className="text-danger" style={{cursor: 'pointer'}} onClick={() => this.setState({ uploadedSubstrate: '' })}>Удалить</span>
                        </div>
                      </div>
                    </Card>    
                  }
                </div>
                */}

                                <AvField
                                    type="hidden"
                                    name="substrate"
                                    value={
                                        this.state.substrate ||
                                        this.state.uploadedSubstrate
                                    }
                                />
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <AvSelect
                                                label="Страна"
                                                name="country"
                                                child={{
                                                    key: 'region',
                                                    options: 'regionOptions',
                                                }}
                                                value={this.state.country}
                                                api="/handbook/country/input_list"
                                                handleSelectChange={
                                                    this.handleSelectChange
                                                }
                                                multiple
                                            />
                                        </Col>

                                        <Col>
                                            <AvSelect
                                                label="Регион"
                                                name="region"
                                                value={this.state.region}
                                                options={
                                                    this.state.regionOptions
                                                }
                                                handleSelectChange={
                                                    this.handleSelectChange
                                                }
                                                isDisabled={
                                                    !this.state.regionOptions
                                                        .length
                                                }
                                                multiple
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>

                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <AvSelect
                                                label="Отрасль"
                                                name="industry"
                                                child={{
                                                    key: 'sub_industry',
                                                    options:
                                                        'subIndustryOptions',
                                                }}
                                                value={this.state.industry}
                                                api="/handbook/industry/input_list"
                                                handleSelectChange={
                                                    this.handleSelectChange
                                                }
                                                multiple
                                            />
                                        </Col>

                                        <Col>
                                            <AvSelect
                                                label="Подотрасль"
                                                name="sub_industry"
                                                child={{
                                                    key: 'production',
                                                    options:
                                                        'productionOptions',
                                                }}
                                                value={this.state.sub_industry}
                                                options={
                                                    this.state
                                                        .subIndustryOptions
                                                }
                                                handleSelectChange={
                                                    this.handleSelectChange
                                                }
                                                isDisabled={
                                                    !this.state
                                                        .subIndustryOptions
                                                        .length
                                                }
                                                multiple
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>

                                <AvSelect
                                    label="Рубрика"
                                    name="rubric_analytics"
                                    api="/handbook/rubric_analytics/input_list"
                                    value={this.state.rubric_analytics}
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                    multiple
                                />

                                {/* <AvSelect
                  label="Задачи"
                  name="analytics_task"
                  api="/handbook/analytics_task/input_list"
                  value={this.state.analytics_task}
                  handleSelectChange={this.handleSelectChange}
                  multiple
                /> */}

                                <AvSelect
                                    label="Графики"
                                    name="graphs"
                                    api="/graphs/input_list"
                                    value={this.state.graphs}
                                    handleSelectChange={this.handleSelectChange}
                                    multiple
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <AvSelect
                                    label="Источники аналитики"
                                    name="analytics_sources"
                                    api="/handbook/analytics_source/input_list?module=analytics"
                                    value={this.state.analytics_sources}
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                    multiple
                                />
                            </CardBody>
                        </Card>

                        {/* <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Тело Рус.</h4>
              </CardHeader>

              <CardBody>
                <Editor
                  editorState={this.state.text_ru}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange.bind(this, 'text_ru')}
                />

                <AvField
                  type="hidden"
                  name="text_ru"
                  value={draftToHtml(convertToRaw(this.state.text_ru.getCurrentContent())).replace('<p></p>', '')}
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Тело Англ.</h4>
              </CardHeader>

              <CardBody>
                <Editor
                  editorState={this.state.text_en}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange.bind(this, 'text_en')}
                />

                <AvField
                  type="hidden"
                  name="text_en"
                  value={draftToHtml(convertToRaw(this.state.text_en.getCurrentContent())).replace('<p></p>', '')}
                />
              </CardBody>
            </Card> */}

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

AnalyticsForm.propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
    name: PropTypes.string,
}

export default AnalyticsForm
