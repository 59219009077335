import {
    SET_NEWS_ENTRIES_QUANTITY,
    SET_NEWS_PAGE,
    SET_NEWS_SEARCH,
    SET_MAX_NEWS,
    SET_NEWS_SORT,
    IS_NEED_REFRESH,
} from './actions'

const initialState = {
    page: 1,
    entryQuantity: 20,
    search: '',
    maxCount: 1,
    currentSortBy: 'date_publication',
    currentSortOrder: 'asc',
    date_update: '',
    date_publication: 'asc',
    name: '',
    active: '',
    isNeedRefresh: true,
}

export const newsPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEWS_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        case SET_NEWS_SEARCH:
            return {
                ...state,
                search: action.payload,
            }
        case SET_NEWS_ENTRIES_QUANTITY:
            return {
                ...state,
                entryQuantity: action.payload,
            }
        case SET_MAX_NEWS:
            return {
                ...state,
                maxCount: action.payload,
            }
        case SET_NEWS_SORT:
            return {
                ...state,
                ...action.payload,
            }
        case IS_NEED_REFRESH:
            return {
                ...state,
                isNeedRefresh: action.payload,
            }
        default:
            return state
    }
}
