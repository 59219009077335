import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import RoleGroupForm from './RoleGroupForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class RoleGroupCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список групп', link: '/user_role_group' },
                { title: 'Добавить группу', link: '/user_role_group/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новая группа"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <RoleGroupForm
                            title="Создание группы"
                            typeForm="add"
                            method="post"
                            api="/role_group/create"
                            redirect="/user_role_group"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RoleGroupCreate
