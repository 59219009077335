import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import PackageForm from './PackageForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class PackageAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список пакетов', link: '/user_package' },
                { title: 'Добавить пакет', link: '/user_package/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый пакет"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <PackageForm
                            title="Создание пакета"
                            typeForm="add"
                            method="post"
                            api="/package/create"
                            redirect="/user_package"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default PackageAdd
