import React, { Component } from 'react'
import { MDBDataTable, MDBPagination } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DropdownControls from '../../components/Common/DropdownControls'
import axios from 'axios'
import { logoutUser } from '../../store/actions'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './style.css'
import store from '../../store'
import {
    setMax,
    setPage,
    setQuantity,
    setSearch,
    setSort,
    isNeedRefresh,
} from '../../store/events-page/actionsCreators'
import { connect } from 'react-redux'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class Events extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список мероприятий', link: '/events' },
            ],
            isNeedRerender: false,
            rows: [],
        }
    }

    refreshData = () => {
        const pageStore = store.getState().eventsPageReducer
        axios
            .get(
                `/events/list?` +
                    `quantity=${pageStore.entryQuantity}&` +
                    `page=${pageStore.page}&` +
                    `search=${pageStore.search}&` +
                    `sort_by=${pageStore.currentSortBy || ''}&` +
                    `sort_order=${pageStore.currentSortOrder || ''}`
            )
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data
                    store.dispatch(setMax(response.data.maxCount))

                    for (let i = 0; i < data.length; i++) {
                        data[i].controls = (
                            <DropdownControls
                                id={data[i].id}
                                redirect="/events/"
                                history={this.props.history}
                                actions={{
                                    update: `/events/update/${data[i].id}/`,
                                    delete: '/events/delete',
                                }}
                            />
                        )

                        if (data[i].active) {
                            data[i].active = 'Да'
                        } else {
                            data[i].active = 'Нет'
                        }
                    }

                    this.setState({ rows: data })
                }
            })
            .catch((err) => {
                // let message;
                // if (err.response && err.response.status) {
                //   switch (err.response.status) {
                //     case 401: message = "Пользователь с введенными данными не найден"; break;
                //     case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
                //     default: message = err[1]; break;
                //   }
                //   window.location.href = '/logout';
                // }
                // throw message;
            })
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        if (store.getState().eventsPageReducer.isNeedRefresh) {
            store.dispatch(isNeedRefresh(false))
            this.refreshData()
        }
    }

    onChangePage = (current) => {
        store.dispatch(setPage(Number(current)))
        store.dispatch(isNeedRefresh(true))
        // this.setState({ isNeedRerender: true });
    }

    onChangePerPage = (value) => {
        store.dispatch(setQuantity(Number(value.target.value)))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
        this.setState({ isNeedRerender: true })
    }

    onChangeSearch = (value) => {
        store.dispatch(setSearch(value.target.value))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Дата обновления',
                    field: 'date_update',
                    sort: store.getState().eventsPageReducer.date_update,
                    width: 150,
                },
                {
                    label: 'Дата начала мероприятия',
                    field: 'event_start_date',
                    sort: store.getState().eventsPageReducer.event_start_date,
                    width: 150,
                },
                {
                    label: 'Дата окончания мероприятия',
                    field: 'event_end_date',
                    sort: store.getState().eventsPageReducer.event_end_date,
                    width: 150,
                },
                {
                    label: 'Наименование',
                    field: 'name',
                    sort: store.getState().eventsPageReducer.name,
                    width: 150,
                },
                {
                    label: 'Активность',
                    field: 'active',
                    sort: store.getState().eventsPageReducer.active,
                    width: 150,
                },
                {
                    label: '',
                    field: 'controls',
                    sort: 'disabled',
                    width: 150,
                },
            ],
            rows: this.state.rows,
        }

        const Table = () => {
            if (this.state.isNeedRerender) {
                setTimeout(() => {
                    this.setState({ isNeedRerender: false })
                }, 10)
                return <></>
            }

            return (
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    sortable
                    btn
                    displayEntries={false}
                    searching={false}
                    onSort={(res) => {
                        if (res.direction && res.column) {
                            const data = {
                                currentSortBy: res.column,
                                currentSortOrder: res.direction,
                                [res.column]:
                                    res.direction === 'asc' ? 'desc' : 'asc',
                            }
                            for (const key in store.getState()
                                .eventsPageReducer) {
                                if (
                                    key === 'date_update' ||
                                    key === 'event_start_date' ||
                                    key === 'event_end_date' ||
                                    key === 'name' ||
                                    key === 'active'
                                ) {
                                    if (key !== res.column) {
                                        data[key] = ''
                                    }
                                }
                            }

                            store.dispatch(setSort(data))
                            store.dispatch(isNeedRefresh(true))
                        }
                    }}
                    entries={store.getState().eventsPageReducer.entryQuantity}
                    data={data}
                    info={false}
                    paginationLabel={['']}
                />
            )
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список мероприятий"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Link
                                            to="/events/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать мероприятие
                                        </Link>
                                    </CardHeader>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <div>{'Show entries'}</div>
                                            <select
                                                className="browser-default custom-select"
                                                style={{ width: '100px' }}
                                                value={
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .entryQuantity
                                                }
                                                onChange={this.onChangePerPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                            </select>
                                        </div>
                                        <div
                                            style={{
                                                margin: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <input
                                                style={{ width: '150px' }}
                                                value={
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .search
                                                }
                                                onChange={this.onChangeSearch}
                                                placeholder={'search'}
                                            />
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className={'events-table'}>
                                            {Table()}
                                        </div>
                                        <MDBPagination>
                                            <Pagination
                                                defaultPageSize={
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .entryQuantity
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .entryQuantity
                                                }
                                                current={
                                                    store.getState()
                                                        .eventsPageReducer.page
                                                }
                                                defaultCurrent={
                                                    store.getState()
                                                        .eventsPageReducer.page
                                                }
                                                onChange={this.onChangePage}
                                                total={
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                        <MDBPagination>
                                            {`Показаны ${
                                                1 +
                                                (store.getState()
                                                    .eventsPageReducer.page -
                                                    1) *
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .entryQuantity
                                            }` +
                                                ` - ${
                                                    1 +
                                                        store.getState()
                                                            .eventsPageReducer
                                                            .page *
                                                            store.getState()
                                                                .eventsPageReducer
                                                                .entryQuantity >
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .maxCount
                                                        ? store.getState()
                                                              .eventsPageReducer
                                                              .maxCount
                                                        : store.getState()
                                                              .eventsPageReducer
                                                              .page *
                                                          store.getState()
                                                              .eventsPageReducer
                                                              .entryQuantity
                                                } записи из ${
                                                    store.getState()
                                                        .eventsPageReducer
                                                        .maxCount
                                                }`}
                                        </MDBPagination>
                                    </CardBody>

                                    <CardFooter>
                                        <Link
                                            to="/events/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать мероприятие
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => state.eventsPageReducer
export default connect(mapStateToProps, {
    setPage,
    setQuantity,
    setSort,
    setSearch,
    setMax,
    isNeedRefresh,
})(Events)