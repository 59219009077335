import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookFormRelatedAndFile from '../HandbookFormRelatedAndFile'

class RegionUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список регионов', link: '/handbooks/region' },
                {
                    title: 'Редактировать регион',
                    link: '/handbooks/region/update',
                },
            ],
            select: {
                options: [],
                label: 'Выберите страну',
                placeholder: 'Выберите опцию',
                errorMessage: 'Обязательно для заполнения',
                name: 'country',
                api: '/handbook/country/input_list',
            },
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.match.params.id}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookFormRelatedAndFile
                            title="Редактирование региона"
                            method="post"
                            api="/handbook/region/update"
                            one="/handbook/region/one"
                            redirect="/handbooks/region"
                            select={this.state.select}
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RegionUpdate
