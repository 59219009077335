import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookLangAndFileForm from '../HandbookLangAndFileForm'

class GraphConstantsCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Список констант',
                    link: '/handbooks/graph_constants',
                },
                {
                    title: 'Добавить константу',
                    link: '/handbooks/graph_constants/create',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Добавить константу"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookLangAndFileForm
                            title="Создание константы"
                            method="post"
                            api="/handbook/graph_constants/create"
                            redirect="/handbooks/graph_constants"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default GraphConstantsCreate
