import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Input,
    InputGroupAddon,
    Label,
    Row
} from 'reactstrap';
import {isEmpty, formatValueToUrlParam, formatValue, uuidv4} from "../../utils/utils";
import AvSelectV2 from "../../components/Common/AvSelectV2";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import Dropzone from 'react-dropzone'
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import user from '../../store/user';
import AvSelect from "../../components/Common/AvSelect";

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: true,
            demoAvailable: false,
            name: '',
            name_public: [],
            type: '',
            data: [],
            state: uuidv4(),
            langConstOptions: [],
            isUpdate: this.props.id,
            errorMessage: !!"Обязательно для заполнения",
            file: null,
            uploadedFile: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputGroupChange = this.handleInputGroupChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectChangeLangConstant = this.handleSelectChangeLangConstant.bind(this);
        this.addInputSignNames = this.addInputSignNames.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.handleSelectChangePictogram = this.handleSelectChangePictogram.bind(
            this
        )
    }

    componentWillMount() {
        if (this.state.isUpdate) {
            axios.get(`/roadmap-template/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data;

                this.setState({
                    isActive: data.active,
                    demoAvailable: data.demo_available,
                    name: data.name,
                    name_public: data.name_public || [],
                    type: data.type,
                    data: data.data || [],
                    uploadedFile: data.preview_picture,
                });
            });
        }

        // Загружаем опции для языковых констант.
        [
            '/handbook/roadmap/input_list',
        ].forEach((element) => {
            axios.get(element).then((response) => {
                let data = [];

                for (let d in response.data.data) {
                    data.push(response.data.data[d]);
                }

                this.state.langConstOptions = this.state.langConstOptions.concat(data);

                this.setState({langConstOptions: this.state.langConstOptions});
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {

            });
        });
    }

    handleAcceptedFile = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                file: file,
                uploadedFile: null,
            })
        })
    }

    handleSelectChangePictogram(key, value, child) {
        const ids = getIdFromSelect(value)
        let file = null
        let _this = this

        if (ids.length) {
            const requests = ids.map((id) => {
                return axios.get(`/handbook/pictogram/one?id=${id}`)
            })

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            if (request.data.data.file.path) {
                                file = request.data.data.file
                            }
                        })
                    })
                )
                .finally(() => {
                    if (file !== null) {
                        var xhr = new XMLHttpRequest()
                        xhr.open('GET', file.path, true)
                        xhr.responseType = 'blob'
                        xhr.onload = function (e) {
                            if (this.status == 200) {
                                let filenew = new File(
                                    [this.response],
                                    file.name + '.' + file.type,
                                    { type: this.response.type }
                                )

                                Object.assign(filenew, {
                                    preview: URL.createObjectURL(filenew),
                                })

                                _this.setState({
                                    file: filenew,
                                    uploadedFile: null,
                                })
                            }
                        }
                        xhr.send()
                    }
                })
        }
    }

    /**
     * Добавление символов к языковой константе.
     *
     * @param variable переменная
     * @param value значение переменной
     */
    addInputSignNames(variable, value) {
        variable.push({id: uuidv4(), name: value});

        this.setState({state: uuidv4()});
    }

    /**
     * Обработчик изменения инпута.
     *
     * @param event
     */
    handleInputChange(event) {
        const target = event.target;
        const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    /**
     * Обрабочтик изменения input группы.
     *
     * @param event
     */
    handleInputGroupChange(event) {
        const target = event.target;

        this.state.data[target.dataset.index][target.name] = target.value;
        this.setState({data: this.state.data});
    }

    /**
     * Обработчик изменения select.
     *
     * @param props
     */
    handleSelectChange(props) {
        let key = props['name'];
        let value = props['value'];
        let dataName = props['data-name'];
        let index = props['data-index'];

        if (!isEmpty(dataName) && typeof index !== "boolean") {
            this.state[dataName][index][key] = value?.[0]?.id;
            this.setState({[dataName]: this.state[dataName]});
        } else {
            this.state[key] = value?.[0]?.id;
        }
    }

    handleSelectChangeLangConstant(props) {
        let value = props['value'];

        //Очищаем от лишних полей, оставляя "id" и "name"
        if (!isEmpty(value)) {
            value.map((e, i) => {
                value[i] = {"id": e.id, "name": e.name};
            });
        }

        if (!isEmpty(props['data-index']) && typeof props['data-index'] !== "boolean" && !isEmpty(props['data-name'])) {
            this.state[props['data-name']][props['data-index']][props['data-name']] = value;
        } else {
            this.state[props['name']] = value;
        }
    }

    /**
     * Обработчик отправки формы.
     *
     * @param event
     * @param errors
     * @param values
     */
    handleSubmit(event, errors, values) {
        this.setState({errors, values});

        if (!this.state.errors.length) {
            const fd = this.getFormData();
            const url = this.state.isUpdate ? '/roadmap-template/update' : '/roadmap-template/create';

            axios.post(url, fd).then(response => {
                if (response.status === 200) {
                    this.props.history.push('/roadmap-template');
                }
            }).catch(err => {
                if (err.response.status === 409 && err.response.data.message == 'unique') {
                    toastr.error(`<b>Кодовое имя модуля должно быть уникальным</b>`);
                } else if (err.response.data.message) {
                    toastr.error(`<b>` + err.response.data.message + `</b>`);
                }
                let errors = err.response.data.errors;
                for (var key in errors) {
                    toastr.error(`<b>` + key + ':' + errors[key] + `</b>`);
                }
                console.log(err.message)
            });
        }
    };

    /**
     * Добавляет элементы формы в группу.
     *
     * @param type тип группы элементов
     * @param dataType тип свойства data
     */
    addGroup(type, dataType = []) {
        this.state.data.push(
            {
                type: type,
                data: dataType
            }
        );

        this.setState({data: this.state.data});
    }

    /**
     * Удаляет группу элементов формы.
     *
     * @param i индекс группы
     */
    deleteGroup(i) {
        this.state.data.splice(i, 1);
        this.setState({data: this.state.data});
    }

    /**
     * Возвращает данные формы для отправки запроса.
     *
     * @returns {{country, data: ([]|[string]|*), name: string, active}}
     */
    getFormData() {
        const fd = new FormData()
        const data = {
            createdBy: user.userId,
            active: this.state.isActive,
            demoAvailable: this.state.demoAvailable,
            name: this.state.name,
            name_public: this.state.name_public,
            type: this.state.type,
            data: this.state.data,
            previewPicture: this.state.file || '',
        };

        if (this.state.isUpdate) {
            data.id = this.props.id;
            data.updatedBy = user.userId;
        }

        if (this.state.uploadedFile) {
            data.previewPicture = this.state.uploadedFile.id
        }

        if (data.previewPicture === null) {
            data.previewPicture = ''
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'previewPicture'
                ) {
                    fd.append(`${key}`, JSON.stringify(data[key]));
                } else {
                    fd.append(key, data[key])
                }
            }
        }

        return fd;
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="working_days">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">{this.props.title}</h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={this.handleInputChange}
                                            />
                                            <Label className="custom-control-label" htmlFor="isActive">
                                                Активен
                                            </Label>
                                        </div>
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="demoAvailable"
                                                id="demoAvailable"
                                                checked={
                                                    this.state.demoAvailable
                                                }
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="demoAvailable"
                                            >
                                                Доступна демоверсия
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование шаблона"
                                name="name"
                                placeholder="Введите значение"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Обязательно для заполнения'
                                    }
                                }}
                            />

                            <AvSelectV2
                                label="Тип"
                                name='type'
                                data-key='id'
                                value={formatValue(this.state.type)}
                                options={[
                                    {"id": "country", "name": "Страновой"},
                                    {"id": "regional", "name": "Региональный"}
                                ]}
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Файл
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Dropzone
                                maxFiles={1}
                                multiple={false}
                                onDrop={(acceptedPreview) =>
                                    this.handleAcceptedFile(
                                        acceptedPreview
                                    )
                                }
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                }) => (
                                    <div
                                        className={
                                            this.state.file ||
                                            this.state
                                                .uploadedFile
                                                ? 'dropzone dropzone-single dz-max-files-reached'
                                                : 'dropzone dropzone-single'
                                        }
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input
                                                {...getInputProps()}
                                            />
                                            <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>
                                                Перетащите
                                                картинку сюда
                                                или кликните для
                                                загрузки
                                            </h4>
                                        </div>

                                        {this.state.file && (
                                            <div className="dz-preview dz-preview-single">
                                                <div className="dz-preview-cover">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        className="dz-preview-img"
                                                        style={{
                                                            width:
                                                                'auto',
                                                            height:
                                                                '100%',
                                                            overflow:
                                                                'hidden',
                                                        }}
                                                        alt={
                                                            this
                                                                .state
                                                                .file
                                                                .name
                                                        }
                                                        src={
                                                            this
                                                                .state
                                                                .file
                                                                .preview
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {this.state
                                            .uploadedFile && (
                                            <div className="dz-preview dz-preview-single">
                                                <div className="dz-preview-cover">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        className="dz-preview-img"
                                                        style={{
                                                            width:
                                                                'auto',
                                                            height:
                                                                '100%',
                                                            overflow:
                                                                'hidden',
                                                        }}
                                                        src={
                                                            this
                                                                .state
                                                                .uploadedFile
                                                                .path
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Dropzone>

                            <Col>
                                <CardBody>
                                    <AvSelect
                                        label="Выбрать пиктограмму"
                                        name="pictogram"
                                        value={
                                            this.state.pictogram
                                        }
                                        api="/handbook/pictogram/input_list"
                                        handleSelectChange={
                                            this
                                                .handleSelectChangePictogram
                                        }
                                    />
                                </CardBody>
                            </Col>

                            <AvField
                                type="hidden"
                                name="file"
                                value={
                                    this.state.file ||
                                    this.state.uploadedFile
                                }
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Составное наименование
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <AvSelectV2
                                label="Публичный заголовок"
                                name="name_public"
                                data-key='id'
                                value={this.state.name_public}
                                options={this.state.langConstOptions}
                                optionsNew={uuidv4()}
                                handleSelectChange={this.handleSelectChangeLangConstant}
                                errorMessage="Обязательно для заполнения"
                                multiple
                            />
                            <FormGroup>
                                <Row>
                                    <Button
                                        onClick={() => this.addInputSignNames(this.state.name_public, '.')}
                                        color="info"
                                        style={{margin: '10px'}}
                                    >
                                        (точка)
                                    </Button>
                                    <Button
                                        onClick={() => this.addInputSignNames(this.state.name_public, ',')}
                                        color="info"
                                        style={{margin: '10px'}}
                                    >
                                        (запятая)
                                    </Button>
                                    <Button
                                        onClick={() => this.addInputSignNames(this.state.name_public, '-')}
                                        color="info"
                                        style={{margin: '10px'}}
                                    >
                                        (тире)
                                    </Button>
                                    <Button
                                        onClick={() => this.addInputSignNames(this.state.name_public, ':')}
                                        color="info"
                                        style={{margin: '10px'}}
                                    >
                                        (двоеточие)
                                    </Button>
                                </Row>
                            </FormGroup>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                                {this.state.data.map((obj, i) => {
                                        return (
                                            <div key={i} style={{borderBottom: "1px solid #efefef", marginTop: "10px"}}>

                                                <>
                                                    {obj?.type === 'lang_constant' && (
                                                        <Card>
                                                            <CardHeader>
                                                                <h4 className="card-title mb-0">
                                                                    Составное наименование константы
                                                                </h4>
                                                            </CardHeader>

                                                            <CardBody>
                                                                <AvSelectV2
                                                                    label="Публичный заголовок константы"
                                                                    name='data'
                                                                    data-name='data'
                                                                    data-index={i}
                                                                    data-key='id'
                                                                    value={this.state.data[i].data}
                                                                    options={this.state.langConstOptions}
                                                                    optionsNew={uuidv4()}
                                                                    handleSelectChange={this.handleSelectChangeLangConstant}
                                                                    errorMessage="Обязательно для заполнения"
                                                                    multiple
                                                                />
                                                                <FormGroup>
                                                                    <Row>
                                                                        <Button
                                                                            onClick={() => this.addInputSignNames(this.state.data[i].data, '.')}
                                                                            color="info"
                                                                            style={{margin: '10px'}}
                                                                        >
                                                                            (точка)
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => this.addInputSignNames(this.state.data[i].data, ',')}
                                                                            color="info"
                                                                            style={{margin: '10px'}}
                                                                        >
                                                                            (запятая)
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => this.addInputSignNames(this.state.data[i].data, '-')}
                                                                            color="info"
                                                                            style={{margin: '10px'}}
                                                                        >
                                                                            (тире)
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => this.addInputSignNames(this.state.data[i].data, ':')}
                                                                            color="info"
                                                                            style={{margin: '10px'}}
                                                                        >
                                                                            (двоеточие)
                                                                        </Button>
                                                                    </Row>
                                                                </FormGroup>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </>

                                                <>
                                                    {obj?.type === 'infoblock' && (
                                                        <AvGroup>
                                                            <Row style={{width: '100%'}}>
                                                            <Col>
                                                                <AvSelectV2
                                                                    label="Инфоблок"
                                                                    name='data'
                                                                    data-name='data'
                                                                    data-index={i}
                                                                    data-key='id'
                                                                    value={formatValue(obj.data?.id)}
                                                                    api={'/infoblock/input_list'}
                                                                    handleSelectChange={(props) => {
                                                                        obj.data.id = props['value']?.[0]?.id;
                                                                    }}
                                                                    errorMessage="Обязательно для заполнения"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <AvField
                                                                    label="Лимит"
                                                                    name={"limit" + i}
                                                                    type="number"
                                                                    placeholder="Введите значение"
                                                                    value={obj.data?.filters?.limit || ''}
                                                                    onInput={(event) => {
                                                                        obj.data.filters = {'limit': parseInt(event.target.value)};
                                                                    }}
                                                                    validate={{
                                                                        required: {
                                                                            value: true,
                                                                            errorMessage: 'Обязательно для заполнения'
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                            </Row>
                                                        </AvGroup>
                                                    )}
                                                </>

                                                <Row style={{width: '100%'}}>
                                                    <Col>
                                                        {i != 0 || !this.state.errorMessage ? (
                                                            <div style={{margin: "10px 0"}}>
                                                                <button title={'Удалить'} className="btn btn-primary" onClick={this.deleteGroup.bind(this, i)}><i className="mdi mdi-close"></i></button>
                                                            </div>
                                                        ) : (null)
                                                        }
                                                    </Col>
                                                </Row>


                                            </div>
                                        )
                                    }
                                )}
                                <div className="icon-demo-content row">
                                    <div className="col-xl-3 col-lg-4 col-sm-6"
                                         onClick={() => this.addGroup('lang_constant')}>
                                        <i className="mdi mdi-plus"></i>
                                        <span>Добавить языковую константу</span>
                                    </div>
                                </div>

                                <div className="icon-demo-content row">
                                    <div className="col-xl-3 col-lg-4 col-sm-6"
                                         onClick={() => this.addGroup('infoblock', {})}>
                                        <i className="mdi mdi-plus"></i>
                                        <span>Добавить инфоблок</span>
                                    </div>
                                </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1"/>
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default Form
