import React, { Component } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TagForm from './TagForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class TagAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список тегов', link: '/tag' },
                { title: 'Добавить тег', link: '/tag/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый тег"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <TagForm
                            title="Создание тега"
                            typeForm="add"
                            method="post"
                            api="/tag/create"
                            redirect="/tag"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default TagAdd
