import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    Button,
} from 'reactstrap'

import Dropzone from 'react-dropzone'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class LangForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: true,
            isMain: false,
            name: '',
            code: '',
            picture: null,
            uploadedPicture: null,

            isUpdate: this.props.id,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        // if (name === 'name' && !this.state.isUpdate) {
        //   this.setState({
        //     code: translit(value)
        //   });
        // }
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios
                .get(`/localization/lang/one?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        isMain: data.main,
                        code: data.code,
                        name: data.name,
                        uploadedPicture: data.picture,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/localization/lang/update'
                : '/localization/lang/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        toastr.success(
                            `Язык <b>${this.state.values.name}</b> успешно создан`
                        )
                        this.props.history.push('/languages')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message === 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            createdBy: jsonUser.userId,
            name: this.state.name,
            code: this.state.code,
            active: this.state.isActive,
            main: this.state.isMain,
            picture: this.state.picture,
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            if (this.state.uploadedPicture) {
                data.picture = this.state.uploadedPicture.id
            }
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'picture'
                ) {
                    data[key].map((el) => fd.append(`${key}[]`, el))
                } else {
                    fd.append(key, data[key])
                }
            }
        }

        return fd
    }

    handleAcceptedPicture = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                picture: file,
                uploadedPicture: null,
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="lang">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isMain"
                                                id="isMain"
                                                checked={this.state.isMain}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isMain"
                                            >
                                                Главный язык
                                            </Label>
                                        </div>

                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <Row>
                                <Col>
                                    <AvField
                                        label="Наименование"
                                        name="name"
                                        placeholder="Введите наименование"
                                        value={this.state.name}
                                        onInput={this.handleInputChange}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                        }}
                                    />

                                    <AvField
                                        label="Код языка"
                                        name="code"
                                        placeholder="Введите код"
                                        value={this.state.code}
                                        onInput={this.handleInputChange}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                            pattern: {
                                                value: '^[a-z0-9--]{2,30}$',
                                                errorMessage: 'Неверный код',
                                            },
                                        }}
                                    />

                                    <Card>
                                        <CardHeader>
                                            <h4 className="card-title mb-0">
                                                Картинка для языка
                                            </h4>
                                        </CardHeader>

                                        <CardBody>
                                            <Dropzone
                                                maxFiles={1}
                                                multiple={false}
                                                onDrop={(acceptedPreview) =>
                                                    this.handleAcceptedPicture(
                                                        acceptedPreview
                                                    )
                                                }
                                                accept="image/*"
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                }) => (
                                                    <div
                                                        className={
                                                            this.state
                                                                .picture ||
                                                            this.state
                                                                .uploadedPicture
                                                                ? 'dropzone dropzone-single dz-max-files-reached'
                                                                : 'dropzone dropzone-single'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                            </div>
                                                            <h4>
                                                                Перетащите
                                                                картинку сюда
                                                                или кликните для
                                                                загрузки
                                                            </h4>
                                                        </div>

                                                        {this.state.picture && (
                                                            <div className="dz-preview dz-preview-single">
                                                                <div className="dz-preview-cover">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        className="dz-preview-img"
                                                                        style={{
                                                                            width:
                                                                                'auto',
                                                                            height:
                                                                                '100%',
                                                                            overflow:
                                                                                'hidden',
                                                                        }}
                                                                        alt={
                                                                            this
                                                                                .state
                                                                                .picture
                                                                                .name
                                                                        }
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .picture
                                                                                .preview
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state
                                                            .uploadedPicture && (
                                                            <div className="dz-preview dz-preview-single">
                                                                <div className="dz-preview-cover">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        className="dz-preview-img"
                                                                        style={{
                                                                            width:
                                                                                'auto',
                                                                            height:
                                                                                '100%',
                                                                            overflow:
                                                                                'hidden',
                                                                        }}
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .uploadedPicture
                                                                                .path
                                                                        }
                                                                        alt={''}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Dropzone>

                                            <AvField
                                                type="hidden"
                                                name="picture"
                                                value={
                                                    this.state.picture ||
                                                    this.state.uploadedPicture
                                                }
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage:
                                                            'Обязательно для заполнения',
                                                    },
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default LangForm
