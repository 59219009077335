import LangConstant from '../pages/LangConstant/index'
import LangConstantAdd from '../pages/LangConstant/add'
import LangConstantEdit from '../pages/LangConstant/edit'

const langConstantRoutes = [
    { path: '/lang/:module/update/:id', component: LangConstantEdit },
    { path: '/lang/:module/create', component: LangConstantAdd },
    { path: '/lang/:module', component: LangConstant },
]

export { langConstantRoutes }
