import React, { Component } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import PurchaserForm from './PurchaserForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class PurchaserAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список закупщиков', link: '/purchaser' },
                { title: 'Добавить закупщика', link: '/purchaser/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый закупщик"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <PurchaserForm title="Создание закупщика" />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default PurchaserAdd
