import React, {Component} from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  Label,
  Row
} from 'reactstrap';
import {isEmpty, formatValueToUrlParam, formatValue} from "../../../utils/utils";
import AvSelectV2 from "../../../components/Common/AvSelectV2";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import user from '../../../store/user';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
      name: '',
      country: [],
      dateHolidays: [],
      isUpdate: this.props.id,
      errorMessage: !!"Обязательно для заполнения",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputGroupChange = this.handleInputGroupChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
  }

  componentWillMount() {
    if (this.state.isUpdate) {
      axios.get(`/business_calendar/unofficial_holidays/one?id=${this.props.id}`).then((response) => {
        const data = response.data.data;

        this.setState({
          isActive: data.active,
          name: data.name,
          country: data.country,
          dateHolidays: data.date_holidays || [],
        });
      });
    } else {
      this.addGroup();
    }
  }

  /**
   * Обработчик изменения инпута.
   *
   * @param event
   */
  handleInputChange(event) {
    const target = event.target;
    const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

  /**
   * Обрабочтик изменения input группы.
   *
   * @param event
   */
  handleInputGroupChange(event) {
    const target = event.target;

    this.state.dateHolidays[target.dataset.index][target.name] = target.value;
    this.setState({dateHolidays: this.state.dateHolidays});
  }

  /**
   * Обработчик изменения select.
   *
   * @param props
   */
  handleSelectChange(props) {
    let key = props['name'];
    let value = props['value'];
    let dataName = props['data-name'];
    let index = props['data-index'];

    if (!isEmpty(dataName) && typeof index !== "boolean") {
      this.state[dataName][index][key] = value;
      this.setState({[dataName]: this.state[dataName]});
    } else {
      this.state[key] = value;
      this.setState({key: value});
    }
  }

  /**
   * Обработчик отправки формы.
   *
   * @param event
   * @param errors
   * @param values
   */
  handleSubmit(event, errors, values) {
    this.setState({errors, values});

    if (!this.state.errors.length) {
      const fd = this.getFormData();
      const url = this.state.isUpdate ? '/business_calendar/unofficial_holidays/update' : '/business_calendar/unofficial_holidays/create';

      axios.post(url, fd).then(response => {
        if (response.status === 200) {
          this.props.history.push('/business_calendar/unofficial_holidays');
        }
      }).catch(err => {
        if (err.response.status === 409 && err.response.data.message == 'unique') {
          toastr.error(`<b>Страна должна быть уникальной!</b>`);
        }else if (err.response.data.message) {
          toastr.error(`<b>` + err.response.data.message + `</b>`);
        }
        let errors = err.response.data.errors;
        for (var key in errors) {
          toastr.error(`<b>` + key + ':' + errors[key] + `</b>`);
        }
        console.log(err.message)
      });
    }
  };

  /**
   * Добавляет элементы формы в группу.
   */
  addGroup() {
    this.state.dateHolidays.push(
        {
          date: '',
          holidays_id: [],
          region_id: [],
          industry_id: [],
          sub_industry_id: [],
        }
    );

    this.setState({dateHolidays: this.state.dateHolidays});
  }

  /**
   * Удаляет группу элементов формы.
   *
   * @param i индекс группы
   */
  deleteGroup(i) {
    this.state.dateHolidays.splice(i, 1);
    this.setState({dateHolidays: this.state.dateHolidays});
  }

  /**
   * Возвращает данные формы для отправки запроса.
   *
   * @returns {{country, dateHolidays: ([]|[string]|*), createdBy: string, name: string, active}}
   */
  getFormData() {
    let dateHolidays = [];
    this.state.dateHolidays.forEach((e, i) => {
      dateHolidays[i] = {
        date: '',
        holidays_id: [],
        region_id: [],
        industry_id: [],
        sub_industry_id: [],
      };

      dateHolidays[i]['date'] = e.date;

      let getSelectValue = (variable) => {
        if (!isEmpty(e[variable])) {
          e[variable].forEach((e) => {
            if (e !== null) {
              if (typeof e === "object") {
                dateHolidays[i][variable].push(e.id);
              } else {
                dateHolidays[i][variable].push(e);
              }
            }
          });
        } else {
          e[variable] = [];
        }
      }

      getSelectValue('holidays_id');
      getSelectValue('industry_id');
      getSelectValue('region_id');
      getSelectValue('sub_industry_id');
    });

    let countryId  = (this.state.country.constructor === Array) ? this.state.country[0].id : this.state.country.id;

    const data = {
      createdBy: user.userId,
      active: this.state.isActive,
      name: this.state.name,
      country:  countryId,
      dateHolidays: dateHolidays,
    };

    if (this.state.isUpdate) {
      data.id = this.props.id;
      data.updatedBy = user.userId;
    }

    return data;
  }

  render() {
    return (
      <React.Fragment>
        <AvForm onSubmit={this.handleSubmit} ref="form" id="working_days">
          <Card>
            <CardHeader>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="card-title mb-0">{this.props.title}</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="custom-control custom-switch">
                    <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="isActive"
                        id="isActive"
                        checked={this.state.isActive}
                        onChange={this.handleInputChange}
                      />
                      <Label className="custom-control-label" htmlFor="isActive">
                        Активен
                      </Label>
                  </div>
                </div>
              </div>
            </CardHeader>

            <CardBody>
              <AvField
                label="Наименование"
                name="name"
                placeholder="Введите заголовок"
                value={this.state.name}
                onInput={this.handleInputChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Обязательно для заполнения'
                  }
                }}
              />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <AvSelectV2
                  label="Страна"
                  name="country"
                  api="/handbook/country/input_list"
                  value={this.state.country}
                  handleSelectChange={this.handleSelectChange}
                  typeValueMulti="one"
                  errorMessage="Обязательно для заполнения"
              />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <AvGroup>
                <Label>
                  Неофициальные праздники
                </Label>
                {this.state.dateHolidays.map((val, i) =>
                    {
                      return (
                          <div key={i} style={{borderBottom: "1px solid #efefef", marginTop: "10px"}}>
                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvField
                                    label="Дата"
                                    name='date'
                                    data-index={i}
                                    placeholder="Введите дату в формате ДД.ММ.ГГ"
                                    value={val.date}
                                    onInput={this.handleInputGroupChange}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Обязательно для заполнения'
                                      },
                                      pattern: {
                                        value: '^(0[1-9]|1[0-9]|2[0-9]|3[01])[.](0[1-9]|1[012])[.](2[0-9]|3[0-9])$',
                                        errorMessage: 'Неверный формат даты “ДД.ММ.ГГ”'
                                      }
                                    }}
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvSelectV2
                                    label="Праздники и события"
                                    name='holidays_id'
                                    data-name='dateHolidays'
                                    data-index={i}
                                    data-key='id'
                                    api="/handbook/holidays_and_events/input_list"
                                    value={formatValue(val.holidays_id)}
                                    handleSelectChange={this.handleSelectChange}
                                    typeValueMulti="one"
                                    errorMessage="Обязательно для заполнения"
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvSelectV2
                                    label="Регион"
                                    name='region_id'
                                    data-name='dateHolidays'
                                    data-index={i}
                                    data-key='id'
                                    isDisabled={isEmpty(this.state.country)}
                                    value={formatValue(val.region_id)}
                                    api={'/handbook/region/input_list' + formatValueToUrlParam(this.state.country, 'country')}
                                    handleSelectChange={this.handleSelectChange}
                                    checkValueInOptions={true}
                                    multiple
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvSelectV2
                                    label="Отрасль"
                                    name='industry_id'
                                    data-name='dateHolidays'
                                    data-index={i}
                                    data-key='id'
                                    api='/handbook/industry/input_list'
                                    value={formatValue(val.industry_id)}
                                    handleSelectChange={this.handleSelectChange}
                                    multiple
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvSelectV2
                                    label="Подотрасль"
                                    name='sub_industry_id'
                                    data-name='dateHolidays'
                                    data-index={i}
                                    data-key='id'
                                    isDisabled={isEmpty(val.industry_id)}
                                    value={formatValue(val.sub_industry_id)}
                                    api={!isEmpty(val.industry_id) ? '/handbook/sub_industry/input_list' + formatValueToUrlParam(val.industry_id, 'industry') : ''}
                                    handleSelectChange={this.handleSelectChange}
                                    checkValueInOptions={true}
                                    clearValue={isEmpty(val.industry_id)}
                                    multiple
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                {i != 0 || !this.state.errorMessage ? (
                                    <FormGroup>
                                      <Label htmlFor={'Удалить'}>
                                        {'Удалить'}
                                      </Label>
                                      <InputGroupAddon addonType="append" onClick={this.deleteGroup.bind(this,i)}>
                                        <div className="btn btn-primary" style={{zIndex: 0}}><i className="mdi mdi-close"></i></div>
                                      </InputGroupAddon>
                                    </FormGroup>
                                ) : (null)
                                }
                              </Col>
                            </Row>
                          </div>
                      )
                    }

                )}
                <div className="icon-demo-content row">
                  <div className="col-xl-3 col-lg-4 col-sm-6" onClick={this.addGroup}>
                    <i className="mdi mdi-plus"></i>
                    <span>Добавить</span>
                  </div>
                </div>
              </AvGroup>
            </CardBody>
          </Card>

          <Card>
            <CardFooter>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1" />
                <div className="flex-shrink-0">
                  <Button
                    color="success"
                    className="waves-effect waves-light"
                  >
                    Сохранить <i className="ri-check-line align-middle ml-1"></i>
                  </Button>
                </div>
              </div>
            </CardFooter>
          </Card>
        </AvForm>
      </React.Fragment>
    )
  }
}

export default Form
