import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Progress,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../../components/Common/AvSelect'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import user from '../../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LocalizationElementField from '../../../components/Common/LocalizationElementField'
import PackageDataTable from '../../../components/Common/PackageDataTable'
import PackageDataTableUsd from '../../../components/Common/PackageDataTableUsd'

class PackageForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: true,
            name: '',
            isUpdate: this.props.id,
            valueLang: '',
            data: [],
            dataUsd: [],
            sort: 500,
            activeTab: 1,
            activeTabProgress: 1,
            langElement: null,
            main: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.toggleTab.bind(this)
        this.packageData = React.createRef()
        this.packageDataUsd = React.createRef()
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name,
                        sort: data.sort,
                        data: data.data,
                        dataUsd: data.data_usd || [
                            { id: 1, 1: '', 2: '' },
                            { id: 2, 1: '', 2: '' },
                        ],
                        main: data.main,
                    })

                    if (
                        typeof this.props.fields != 'undefined' &&
                        this.props.fields.length > 0
                    ) {
                        this.props.fields.forEach((element) => {
                            this.setState({
                                [element.name]: data[element.name],
                            })
                        })
                    }
                })
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab,
                })
            }
        }
    }

    updateData = (value) => {
        this.setState({ langElement: value })
    }

    errorTab(errors) {
        let pos = 0

        errors.forEach((element) => {
            pos = element.indexOf('lang')

            if (pos == -1) {
                this.toggleTab(1)
                return
            } else {
                this.toggleTab(2)
                return
            }
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        if (name === 'name') {
            this.setState({
                valueLang: value,
            })
        }

        this.setState({
            [name]: value,
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })
        this.errorTab(errors)

        if (!errors.length) {
            let packageData = this.packageData.current.getGraphsData()
            let packageDataUsd = this.packageDataUsd.current.getGraphsData()

            const data = {
                createdBy: user.userId,
                name: this.state.values.name,
                active: this.state.isActive,
                sort: this.state.sort,
                data: packageData.data,
                dataUsd: packageDataUsd.data,
                main: this.state.main,
            }

            if (this.state.langElement) {
                data['lang'] = this.state.langElement
            }

            if (
                typeof this.props.fields != 'undefined' &&
                this.props.fields.length > 0
            ) {
                this.props.fields.forEach((element) => {
                    data[element.name] = this.state[element.name]
                })
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: data,
                })
                .then((response) => {
                    if (response.status === 200) {
                        toastr.success(
                            `Пакет <b>${this.state.values.name}</b> успешно обновлен`
                        )
                        this.props.history.push('/user_package')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 422 &&
                        err.response.data.errors
                    ) {
                        let errors = err.response.data.errors
                        for (var key in errors) {
                            toastr.error(`<b>` + errors[key] + `</b>`)
                        }
                    }
                    console.log(err.message)
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="package">
                    <Card>
                        <div
                            id="basic-pills-wizard"
                            className="twitter-bs-wizard"
                        >
                            <CardHeader>
                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li
                                        className={
                                            this.state.activeTab === 1
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(1)
                                            }}
                                        >
                                            Поля
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.state.activeTab === 2
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(2)
                                            }}
                                        >
                                            Локализация
                                        </Link>
                                    </li>
                                </ul>
                            </CardHeader>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={1}>
                                    <CardHeader>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h4 className="card-title mb-0">
                                                    {this.props.title}
                                                </h4>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="custom-control custom-switch">
                                                    <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        name="isActive"
                                                        id="isActive"
                                                        checked={
                                                            this.state.isActive
                                                        }
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        htmlFor="isActive"
                                                    >
                                                        Активен
                                                    </Label>
                                                </div>
                                            </div>
 
                                            <div className="custom-control custom-switch ml-4">
                                                <Input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    name="main"
                                                    id="main"
                                                    checked={
                                                        this.state.main
                                                    }
                                                    onChange={
                                                        this
                                                            .handleInputChange
                                                    }
                                                />
                                                <Label
                                                    className="custom-control-label"
                                                    htmlFor="main"
                                                >
                                                    Главный
                                                </Label>
                                            </div>
                                            
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <AvField
                                            label="Наименование"
                                            name="name"
                                            placeholder="Введите наименование"
                                            value={this.state.name}
                                            onInput={this.handleInputChange}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        'Обязательно для заполнения',
                                                },
                                            }}
                                        />
                                        <AvField
                                            label="Сортировка"
                                            name="sort"
                                            placeholder="Введите сортировку"
                                            value={this.state.sort}
                                            onInput={this.handleInputChange}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        'Обязательно для заполнения',
                                                },
                                                pattern: {
                                                    value: '^[0-9]+$',
                                                    errorMessage:
                                                        'Неверный формат, только цифры',
                                                },
                                            }}
                                        />
                                    </CardBody>

                                    <Card>
                                        <CardHeader>
                                            <h4 className="card-title mb-0">
                                                Тарифный план
                                            </h4>
                                        </CardHeader>

                                        <CardBody>
                                            <PackageDataTable
                                                ref={this.packageData}
                                                value={this.state.data}
                                            />
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardHeader>
                                            <h4 className="card-title mb-0">
                                                Тарифный план USD
                                            </h4>
                                        </CardHeader>

                                        <CardBody>
                                            <PackageDataTableUsd
                                                ref={this.packageDataUsd}
                                                value={this.state.dataUsd}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>

                                <TabPane tabId={2}>
                                    <CardBody>
                                        <LocalizationElementField
                                            elementId={this.props.id}
                                            updateData={this.updateData}
                                            module="package"
                                            field="name"
                                            defaultLang="ru"
                                            defaultValue={this.state.valueLang}
                                            langElement={this.state.langElement}
                                        />
                                    </CardBody>
                                </TabPane>
                            </TabContent>

                            <CardFooter>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default PackageForm
