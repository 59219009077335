import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import PackageForm from './PackageForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class PackageEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список пакетов', link: '/user_package' },
                { title: 'Редактировать пакет', link: '/user_package/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        const data = {
            columns: [],
            rows: [],
            package: this.state.package || {},
            packageName: this.state.package || { name: '' },
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование пакета"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <PackageForm
                            title={
                                'Редактирование пакета: ' +
                                data.packageName.name
                            }
                            id={this.state.id}
                            data={data.package}
                            typeForm="edit"
                            history={this.props.history}
                            method="post"
                            api="/package/update"
                            redirect="/user_package"
                            one="/package/one"
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default PackageEdit
