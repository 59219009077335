import React, { Component, createRef } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
} from 'reactstrap'
import Select from 'react-select'
import {isEmpty, formatValueToUrlParam, formatValue} from "../../utils/utils";
import AvSelectV2 from "../../components/Common/AvSelectV2"
import AvSelect from '../../components/Common/AvSelect'
import AvMultipleInput from '../../components/Common/AvMultipleInput'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { Editor } from 'react-draft-wysiwyg'
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateFromHTML } from 'draft-js-import-html'
import { renameToSelect } from '../../helpers/helpers'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import toastr from 'toastr'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.filter((el) => el).map((el) => ids.push(el.id))
    return ids
}

const formatData = (dates) => {
    let date = new Date(dates)
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        'T' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
    )
}

const translit = (str) => {
    var space = '-'
    var link = ''
    var transl = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'sh',
        ъ: space,
        ы: 'y',
        ь: space,
        э: 'e',
        ю: 'yu',
        я: 'ya',
    }

    if (str != '') {
        str = str.toLowerCase().substr(0, 100)
    }

    for (var i = 0; i < str.length; i++) {
        if (/[а-яё]/.test(str.charAt(i))) {
            link += transl[str.charAt(i)]
        } else if (/[a-z0-9]/.test(str.charAt(i))) {
            link += str.charAt(i)
        } else {
            if (link.slice(-1) !== space) link += space
        }
    }

    return link
}

const getDateToday = () => {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy = today.getFullYear()
    const hours =
        today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    const minutes =
        today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
    const seconds =
        today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()

    return (
        yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes + ':' + seconds
    )
}

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class ObjectsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список объектов', link: '/objects' },
            ],

            isActive: true,
            publiclyAvailable: false,
            nameRu: '',
            nameEn: '',
            code: '',
            lang: [],
            country: [],
            region: [],
            industry: [],
            subIndustry: [],
            production: [],
            graphs: [],
            locationMap: '',
            date: '',
            source: [''],
            commentRu: EditorState.createEmpty(),
            commentEn: EditorState.createEmpty(),
            adminComment: EditorState.createEmpty(),
            productionVolume: '',
            productionVolumeMax: '',
            investmentSites: [],
            objectCategory: [],
            objectStatus: [],
            objectType: [],
            projects: [],
            units: [],
            contractManufacturing: false,
            objectForSale: false,
            owner: '',
            site: '',
            previewPicture: null,
            uploadedPreview: null,
            subIndustryOptions: [],

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSelectChangeTypePictogram = this.handleSelectChangeTypePictogram.bind(
            this
        )
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios.get(`/objects/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data

                this.setState({
                    isActive: data.active,
                    publiclyAvailable: data.publicly_available,
                    nameRu: data.name_ru || this.state.nameRu,
                    nameEn: data.name_en || this.state.nameEn,
                    code: data.code || this.state.code,
                    country: data.country.id ? [data.country] : [],
                    region: data.regions || this.state.region,
                    industry: data.industry || this.state.industry,
                    subIndustry: data.sub_industry || this.state.subIndustry,
                    production: data.production || this.state.production,
                    graphs: data.graphs || this.state.graphs,
                    locationMap: data.location_map || this.state.locationMap,
                    date: data.date.replace(' ', 'T'),
                    source: data.source.split(','),
                    commentRu: this.getEditorContent(data.comment_ru || ''),
                    commentEn: this.getEditorContent(data.comment_en || ''),
                    adminComment: this.getEditorContent(
                        data.admin_comment || ''
                    ),
                    uploadedPreview:
                        data.preview_picture || this.state.uploadedPreview,
                    productionVolume:
                        data.production_volume || this.state.productionVolume,
                    productionVolumeMax:
                        data.production_volume_max ||
                        this.state.productionVolumeMax,
                    investmentSites:
                        data.investment_sites || this.state.investment_sites,
                    objectCategory:
                        [data.object_category] || this.state.objectCategory,
                    objectStatus:
                        [data.object_status] || this.state.objectStatus,
                    objectType: [data.object_type] || this.state.objectType,
                    projects: data.projects || this.state.projects,
                    units: data.units.id ? [data.units] : [],
                    contractManufacturing:
                        data.contract_manufacturing ||
                        this.state.contractManufacturing,
                    objectForSale:
                        data.object_for_sale || this.state.objectForSale,
                    owner: data.owner || this.state.owner,
                    site: data.site || this.state.site,
                })

                if (data.lang.id) {
                    this.setState({ lang: [data.lang] })
                } else {
                    this.setState({ lang: [{ id: 'null', name: 'Все' }] })
                }

                this.handleSelectChange('country', [data.country])
                this.handleSelectChange('industry', data.industry, {
                    key: 'sub_industry',
                    options: 'subIndustryOptions',
                })
            })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        if (name === 'nameRu' && !this.state.isUpdate) {
            this.setState({
                code: translit(value.trim()),
            })
        }
    }

    handleSelectChange(key, value, child) {
        if (typeof value === "undefined") {
            value = key['value'];
            key = key['name'];
        }

        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/objects/update'
                : '/objects/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/objects')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    handleAcceptedPreview = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                previewPicture: file,
                uploadedPreview: null,
            })
        })
    }

    handleSelectChangeTypePictogram(key, value, child) {
        const ids = getIdFromSelect(value)
        let file = null
        let _this = this

        this.setState({
            [key]: value,
        })

        if (ids.length) {
            const requests = ids.map((id) => {
                return axios.get(`/handbook/object_type/one?id=${id}`)
            })

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            if (request.data.data.file.path) {
                                file = request.data.data.file
                            }
                        })
                    })
                )
                .finally(() => {
                    if (file !== null) {
                        var xhr = new XMLHttpRequest()
                        xhr.open('GET', file.path, true)
                        xhr.responseType = 'blob'
                        xhr.onload = function (e) {
                            if (this.status == 200) {
                                let filenew = new File(
                                    [this.response],
                                    file.name + '.' + file.type,
                                    { type: this.response.type }
                                )

                                Object.assign(filenew, {
                                    preview: URL.createObjectURL(filenew),
                                })

                                _this.setState({
                                    previewPicture: filenew,
                                    uploadedPreview: null,
                                })
                            }
                        }
                        xhr.send()
                    }
                })
        }
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            createdBy: jsonUser.userId,
            code: this.state.code,
            nameRu: this.state.nameRu,
            nameEn: this.state.nameEn,
            active: this.state.isActive,
            publiclyAvailable: this.state.publiclyAvailable,
            lang: getIdFromSelect(this.state.lang)[0],
            country: getIdFromSelect(this.state.country)[0],
            region: getIdFromSelect(this.state.region),
            industry: getIdFromSelect(this.state.industry),
            subIndustry: getIdFromSelect(this.state.subIndustry) || null,
            production: getIdFromSelect(this.state.production) || null,
            graphs: getIdFromSelect(this.state.graphs) || null,
            locationMap: this.state.locationMap,
            date: this.state.date.replace('T', ' '),
            source: this.state.source,
            commentRu: draftToHtml(
                convertToRaw(this.state.commentRu.getCurrentContent())
            ),
            commentEn: draftToHtml(
                convertToRaw(this.state.commentEn.getCurrentContent())
            ),
            adminComment: draftToHtml(
                convertToRaw(this.state.adminComment.getCurrentContent())
            ),
            previewPicture: this.state.previewPicture,
            term: this.state.term,
            productionVolume: this.state.productionVolume,
            productionVolumeMax: this.state.productionVolumeMax,
            investmentSites:
                getIdFromSelect(this.state.investmentSites) || null,
            objectCategory: getIdFromSelect(this.state.objectCategory)[0],
            objectStatus: getIdFromSelect(this.state.objectStatus)[0],
            objectType: getIdFromSelect(this.state.objectType)[0],
            projects: getIdFromSelect(this.state.projects) || null,
            units: getIdFromSelect(this.state.units)[0] || null,
            contractManufacturing: this.state.contractManufacturing || false,
            objectForSale: this.state.objectForSale || false,
            owner: this.state.owner || null,
            site: this.state.site || null,
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            if (this.state.uploadedPreview) {
                data.previewPicture = this.state.uploadedPreview.id
            }
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'previewPicture'
                ) {
                    data[key].map((el) => fd.append(`${key}[]`, el))
                } else {
                    if (data[key] && data[key] !== '') {
                        fd.append(key, data[key])
                    }
                }
            }
        }

        return fd
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }

    updateData = (value) => {
        this.setState({ source: value })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="associations"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="publiclyAvailable"
                                                id="publiclyAvailable"
                                                checked={
                                                    this.state.publiclyAvailable
                                                }
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="publiclyAvailable"
                                            >
                                                Сделать общедоступными
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название Рус."
                                name="nameRu"
                                placeholder="Введите название Рус."
                                value={this.state.nameRu}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Название Англ."
                                name="nameEn"
                                placeholder="Введите название Англ."
                                value={this.state.nameEn}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Символьный код"
                                name="code"
                                placeholder="Введите символьный код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z0-9--]{4,255}$',
                                        errorMessage: 'Неверный символьный код',
                                    },
                                }}
                            />

                            <AvSelect
                                label="Где показывать"
                                name="lang"
                                optionNew={[{ id: 'null', name: 'Все' }]}
                                value={this.state.lang}
                                api="/localization/lang/input_list?main=true"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvSelect
                                label="Категория объекта"
                                name="objectCategory"
                                value={this.state.objectCategory}
                                api="/handbook/object_category/input_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvSelect
                                label="Статус объекта"
                                name="objectStatus"
                                value={this.state.objectStatus}
                                api="/handbook/object_status/input_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvField
                                label="Дата актуализации"
                                name="date"
                                type="datetime-local"
                                value={this.state.date}
                                onInput={(event) =>
                                    this.setState({
                                        date: formatData(event.target.value),
                                    })
                                }
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <Row>
                                <Col>
                                    <AvSelect
                                        label="Страна"
                                        name="country"
                                        value={this.state.country}
                                        api="/handbook/country/input_list"
                                        handleSelectChange={
                                            this.handleSelectChange
                                        }
                                        errorMessage="Обязательно для заполнения"
                                    />
                                </Col>

                                <Col>
                                    <AvSelectV2
                                        label="Регион"
                                        name='region'
                                        isDisabled={isEmpty(this.state.country)}
                                        value={this.state.region}
                                        api={'/handbook/region/input_list' + formatValueToUrlParam(this.state.country, 'country')}
                                        errorMessage="Обязательно для заполнения"
                                        handleSelectChange={this.handleSelectChange}
                                        checkValueInOptions={true}
                                        multiple
                                    />
                                </Col>
                            </Row>

                            <AvField
                                label="Координаты на карте"
                                name="locationMap"
                                placeholder="(формат - Координата X:Координата Y)"
                                value={this.state.locationMap}
                                onInput={this.handleInputChange}
                                validate={{
                                    pattern: {
                                        value: '^([0-9-.])+:([0-9-.])+$',
                                        errorMessage: 'Неверные координаты',
                                    },
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvSelect
                                label="Вид объекта"
                                name="objectType"
                                value={this.state.objectType}
                                api="/handbook/object_type/input_list"
                                handleSelectChange={
                                    this.handleSelectChangeTypePictogram
                                }
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvMultipleInput
                                updateData={this.updateData}
                                value={this.state.source}
                                options={{
                                    label: 'Источник',
                                    name: 'source',
                                    errorMessage: 'Обязательно для заполнения',
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Картинка для анонса
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Dropzone
                                maxFiles={1}
                                multiple={false}
                                onDrop={(acceptedPreview) =>
                                    this.handleAcceptedPreview(acceptedPreview)
                                }
                                accept="image/*"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className={
                                            this.state.previewPicture ||
                                            this.state.uploadedPreview
                                                ? 'dropzone dropzone-single dz-max-files-reached'
                                                : 'dropzone dropzone-single'
                                        }
                                        style={{ textAlign: 'center' }}
                                    >
                                        <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>
                                                Перетащите картинку сюда или
                                                кликните для загрузки
                                            </h4>
                                        </div>

                                        {this.state.previewPicture && (
                                            <div className="dz-preview dz-preview-single">
                                                <div className="dz-preview-cover">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        className="dz-preview-img"
                                                        style={{
                                                            width: 'auto',
                                                            height: '100%',
                                                            overflow: 'hidden',
                                                        }}
                                                        alt={
                                                            this.state
                                                                .previewPicture
                                                                .name
                                                        }
                                                        src={
                                                            this.state
                                                                .previewPicture
                                                                .preview
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {this.state.uploadedPreview && (
                                            <div className="dz-preview dz-preview-single">
                                                <div className="dz-preview-cover">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        className="dz-preview-img"
                                                        style={{
                                                            width: 'auto',
                                                            height: '100%',
                                                            overflow: 'hidden',
                                                        }}
                                                        src={
                                                            this.state
                                                                .uploadedPreview
                                                                .path
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Dropzone>

                            <AvField
                                type="hidden"
                                name="previewPicture"
                                value={
                                    this.state.previewPicture ||
                                    this.state.uploadedPreview
                                }
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Отрасль"
                                            name="industry"
                                            child={{
                                                key: 'sub_industry',
                                                options: 'subIndustryOptions',
                                            }}
                                            value={this.state.industry}
                                            api="/handbook/industry/input_list"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            errorMessage="Обязательно для заполнения"
                                            multiple
                                        />
                                    </Col>

                                    <Col>
                                        <AvSelect
                                            label="Подотрасль"
                                            name="subIndustry"
                                            value={this.state.subIndustry}
                                            options={
                                                this.state.subIndustryOptions
                                            }
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            isDisabled={
                                                !this.state.subIndustryOptions
                                                    .length
                                            }
                                            multiple
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <AvSelect
                                label="Инвестплощадка"
                                name="investmentSites"
                                value={this.state.investmentSites}
                                api="/investment_sites/input_list"
                                handleSelectChange={this.handleSelectChange}
                                multiple
                                isClearable
                            />

                            <AvSelect
                                label="Связанные проекты"
                                name="projects"
                                value={this.state.projects}
                                api="/projects/input_list"
                                handleSelectChange={this.handleSelectChange}
                                multiple
                            />

                            <AvSelect
                                label="Продукция"
                                name="production"
                                value={this.state.production}
                                api="/handbook/production/input_list"
                                handleSelectChange={this.handleSelectChange}
                                multiple
                            />

                            <AvSelect
                                label="Графики"
                                name="graphs"
                                value={this.state.graphs}
                                api="/graphs/input_list"
                                handleSelectChange={this.handleSelectChange}
                                multiple
                            />

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvField
                                            label="Фактический объем выпуска продукции/год"
                                            name="productionVolume"
                                            placeholder="Введите планируемый объем выпуска"
                                            value={this.state.productionVolume}
                                            onInput={this.handleInputChange}
                                            validate={{
                                                pattern: {
                                                    value: '^[0-9]+$',
                                                    errorMessage:
                                                        'Неверное значение. Введите только целые числа.',
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <AvField
                                            label="Максимальный объем выпуска продукции/год"
                                            name="productionVolumeMax"
                                            placeholder="Введите планируемый объем выпуска"
                                            value={
                                                this.state.productionVolumeMax
                                            }
                                            onInput={this.handleInputChange}
                                            validate={{
                                                pattern: {
                                                    value: '^[0-9]+$',
                                                    errorMessage:
                                                        'Неверное значение. Введите только целые числа.',
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <AvSelect
                                            label="Объем выпуска продукции/год - Размерность"
                                            name="units"
                                            value={this.state.units}
                                            api="/handbook/units/input_list"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <Card>
                                <CardBody>
                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="contractManufacturing"
                                            id="contractManufacturing"
                                            checked={
                                                this.state.contractManufacturing
                                            }
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="contractManufacturing"
                                        >
                                            Контрактное производство
                                        </Label>
                                    </div>

                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="objectForSale"
                                            id="objectForSale"
                                            checked={this.state.objectForSale}
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="objectForSale"
                                        >
                                            Объект продается
                                        </Label>
                                    </div>
                                </CardBody>
                            </Card>

                            <AvField
                                label="Собственник"
                                name="owner"
                                placeholder="Введите значение"
                                value={this.state.owner}
                                onInput={this.handleInputChange}
                            />

                            <AvField
                                label="Сайт"
                                name="site"
                                placeholder="Введите значение"
                                value={this.state.site}
                                onInput={this.handleInputChange}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Комментарий (публичный) - рус
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Editor
                                editorState={this.state.commentRu}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange.bind(
                                    this,
                                    'commentRu'
                                )}
                            />

                            <AvField
                                type="hidden"
                                name="commentRu"
                                value={draftToHtml(
                                    convertToRaw(
                                        this.state.commentRu.getCurrentContent()
                                    )
                                ).replace('<p></p>', '')}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Комментарий (публичный) - анг
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Editor
                                editorState={this.state.commentEn}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange.bind(
                                    this,
                                    'commentEn'
                                )}
                            />

                            <AvField
                                type="hidden"
                                name="commentEn"
                                value={draftToHtml(
                                    convertToRaw(
                                        this.state.commentEn.getCurrentContent()
                                    )
                                ).replace('<p></p>', '')}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Комментарий внутренний
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Editor
                                editorState={this.state.adminComment}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange.bind(
                                    this,
                                    'adminComment'
                                )}
                            />

                            <AvField
                                type="hidden"
                                name="adminComment"
                                value={draftToHtml(
                                    convertToRaw(
                                        this.state.adminComment.getCurrentContent()
                                    )
                                ).replace('<p></p>', '')}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default ObjectsForm
