import {
    SET_NEWS_ENTRIES_QUANTITY,
    SET_NEWS_PAGE,
    SET_NEWS_SEARCH,
    SET_MAX_NEWS,
    SET_NEWS_SORT,
    IS_NEED_REFRESH,
} from './actions'

export const setNewsPage = (value) => ({
    type: SET_NEWS_PAGE,
    payload: value,
})

export const setNewsQuantity = (value) => ({
    type: SET_NEWS_ENTRIES_QUANTITY,
    payload: value,
})

export const setNewsSearch = (value) => ({
    type: SET_NEWS_SEARCH,
    payload: value,
})

export const setMaxNews = (value) => ({
    type: SET_MAX_NEWS,
    payload: value,
})

export const setNewsSort = (value) => ({
    type: SET_NEWS_SORT,
    payload: value,
})

export const isNeedRefresh = (value) => ({
    type: IS_NEED_REFRESH,
    payload: value,
})
