import Organizations from '../pages/Organizations/index'
import OrganizationsAdd from '../pages/Organizations/add'
import OrganizationsEdit from '../pages/Organizations/edit'

const organizationsRoutes = [
    { path: '/organizations/update/:id', component: OrganizationsEdit },
    { path: '/organizations/create', component: OrganizationsAdd },
    { path: '/organizations', component: Organizations },
]

export { organizationsRoutes }
