import React, { Component } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import SchedulerForm from './SchedulerForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class SchedulerAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список планировщика ', link: '/scheduler' },
                { title: 'Создать планировщик', link: '/scheduler/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый планировщик"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <SchedulerForm
                            title="Создание планировщика"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default SchedulerAdd
