import Infoblock from '../pages/Infoblock/index'
import InfoblockAdd from '../pages/Infoblock/create'
import InfoblockEdit from '../pages/Infoblock/update'

const infoblockRoutes = [
    { path: '/infoblock/update/:id', component: InfoblockEdit },
    { path: '/infoblock/create', component: InfoblockAdd },
    { path: '/infoblock', component: Infoblock },
]

export { infoblockRoutes }
