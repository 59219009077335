import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookForm from '../HandbookForm'
import HandbookLangAndFileForm from '../HandbookLangAndFileForm'

class IndustryCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список отраслей', link: '/handbooks/industry' },
                {
                    title: 'Добавить отрасль',
                    link: '/handbooks/industry/create',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новая отрасль"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookLangAndFileForm
                            title="Создание отрасли"
                            method="post"
                            api="/handbook/industry/create"
                            redirect="/handbooks/industry"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default IndustryCreate
