import React, { Component } from 'react'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import { Label } from 'reactstrap'
import Select from 'react-select'
import axios from 'axios'
import PropTypes from 'prop-types'

class AvSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            selected: [],
            isMultiple: !!this.props.multiple,
            options: this.props.options || [],
            placeholder: this.props.placeholder || 'Выберите значение',
            isLoading: true,
            typeValueMulti: this.props.typeValueMulti || '',
            isVisible: !this.props.isVisible,
            element: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.isInViewport = this.isInViewport.bind(this)
        this.scroll = this.scroll.bind(this)
    }

    componentDidMount() {
        if (typeof this.props.api === 'object') {
            const data = []
            const value = this.props.value

            this.props.api.forEach((element) => {
                axios
                    .get(element)
                    .then((response) => {
                        if (this.props.optionNew) {
                            for (const op in this.props.optionNew) {
                                data.push(this.props.optionNew[op])
                            }
                        }

                        for (const d in response.data.data) {
                            data.push(response.data.data[d])
                        }
                    })
                    .catch((err) => {
                        console.log(err.message)
                    })
                    .finally(() => {
                        this.setState({ isLoading: false })
                    })
            })

            this.setState({
                options: data,
                value: value,
            })
        } else if (this.props.api) {
            axios
                .get(this.props.api)
                .then((response) => {
                    const data = []
                    const value = this.props.value

                    if (this.props.optionNew) {
                        for (const op in this.props.optionNew) {
                            data.push(this.props.optionNew[op])
                        }
                    }

                    for (const d in response.data.data) {
                        data.push(response.data.data[d])
                    }

                    this.setState({
                        options: data,
                        value: value,
                    })
                })
                .catch((err) => {
                    console.log(err.message)
                })
                .finally(() => {
                    this.setState({ isLoading: false })
                })
        } else {
            this.setState({ isLoading: false })
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value && nextProps.value.length) {
            if (typeof nextProps.value === 'object') {
                nextProps.value.forEach((element, key) => {
                    if (element && !element.name) {
                        const name = this.state.options.find(
                            (el) => el.id === element.id
                        )

                        if (name) {
                            nextProps.value[key].name = name.name
                        }
                    }
                })
            }
        }

        this.setState({
            value: nextProps.value,
        })

        if (nextProps.options && nextProps.options.length) {
            this.setState({
                options: nextProps.options,
            })
        }

        if (nextProps.optionsNew && nextProps.optionsNew.length) {
            let options = this.state.options.concat(nextProps.optionsNew)

            options = options.filter(
                (thing, index, self) =>
                    index === self.findIndex((t) => t.name === thing.name)
            )

            this.setState({
                options: options,
            })
        }
    }

    handleChange(event) {
        const value = this.state.isMultiple ? event : [event]
        this.setState({ value: value })

        if (this.props.handleSelectChange) {
            this.props.handleSelectChange(
                this.props.name,
                value,
                this.props.child
            )
            this.setState({ isLoading: false })
        }
    }

    isInViewport(el) {
        if (!el) return false

        this.setState({ element: el })

        if (!this.state.isVisible) {
            window.addEventListener('scroll', this.scroll, false)
        }
    }

    scroll() {
        if (!this.state.element.getBoundingClientRect()) return false

        const top = this.state.element.getBoundingClientRect().top
        const visible = top + 0 >= 0 && top - 0 <= window.innerHeight

        if (visible) {
            this.setState({ isVisible: true })
            window.removeEventListener('scroll', this.scroll)
        }
    }

    render() {
        let value =
            this.state.value &&
            this.state.value.length &&
            !!this.props.errorMessage
                ? 'value'
                : ''

        // if (this.state.typeValueMulti.length && this.state.value.id) {
        //   value = 'value';
        // }

        if (
            this.state.value &&
            this.state.value.length &&
            !!this.props.errorMessage &&
            Array.isArray(this.state.value) &&
            this.state.value[0].id === ''
        ) {
            value = ''
        }
        // todo refactor
        if (
            this.state.typeValueMulti.length &&
            this.state.value &&
            this.state.value.id
        ) {
            value = 'value'
        }

        if (this.state.value && this.state.value.id) {
            value = this.state.value.id
        }

        const styles = {
            menuList: (provided, state) => ({
                ...provided,
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                width: '100%',
                display: 'block',
                overflowY: 'scroll',
            }),
            menu: (provided, state) => ({
                ...provided,
                height: '160px',
                maxHeight: '160px',
            }),
            option: (provided, state) => ({
                ...provided,
            }),
            singleValue: (provided, state) => {
                return { ...provided }
            },
            ...this.props.customStyles,
        }

        return (
            <React.Fragment>
                <AvGroup>
                    <div ref={this.isInViewport}>
                        <Label htmlFor={this.props.name}>
                            {this.props.label}
                        </Label>
                        {this.state.isVisible && (
                            <AvInput
                                name={this.props.name}
                                type="hidden"
                                ref="input"
                                value={value}
                                required={!!this.props.errorMessage}
                            />
                        )}
                        <Select
                            options={this.state.options}
                            value={this.state.value}
                            styles={styles}
                            name={this.props.name}
                            placeholder={this.state.placeholder}
                            onChange={this.handleChange}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isMulti={this.state.isMultiple}
                            isLoading={
                                this.state.isLoading && !this.props.isDisabled
                            }
                            isDisabled={this.props.isDisabled}
                            isClearable={Boolean(this.props.isClearable)}
                        />
                        <AvFeedback>{this.props.errorMessage}</AvFeedback>
                    </div>
                </AvGroup>
            </React.Fragment>
        )
    }
}

AvSelect.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errorMessage: PropTypes.string,
    isDisabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    name: PropTypes.string,
    handleSelectChange: PropTypes.func,
    child: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    options: PropTypes.array,
    multiple: PropTypes.bool,
    placeholder: PropTypes.string,
    customStyles: PropTypes.object,
    isVisible: PropTypes.bool,
    api: PropTypes.any,
    label: PropTypes.string,
    optionNew: PropTypes.any,
    optionsNew: PropTypes.any,
    typeValueMulti: PropTypes.string,
}

AvSelect.defaultProps = {
    id: null,
    one: null,
    value: [],
    options: [],
    customStyles: {},
}

export default AvSelect
