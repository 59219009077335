import React, { Component } from 'react'
import { MDBDataTable } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DropdownControls from '../../components/Common/DropdownControls'
import axios from 'axios'
import { logoutUser } from '../../store/actions'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список продукции', link: '/products' },
            ],
            rows: [],
        }
    }

    componentDidMount() {
        axios
            .get('/products/list?limit=999&offset=0')
            .then((response) => {
                console.log(response)
                if (response.status >= 200) {
                    const data = response.data.data

                    for (let i = 0; i < data.length; i++) {
                        data[i].controls = (
                            <DropdownControls
                                id={data[i].id}
                                redirect="/products/"
                                history={this.props.history}
                                actions={{
                                    update: `/products/update/${data[i].id}/`,
                                    delete: '/products/delete',
                                }}
                            />
                        )

                        if (data[i].active) {
                            data[i].active_str = 'Да'
                        } else {
                            data[i].active_str = 'Нет'
                        }
                    }

                    this.setState({ rows: data })
                }
            })
            .catch((err) => {
                let message
                if (err.response && err.response.status) {
                    // switch (err.response.status) {
                    //   case 401: message = "Пользователь с введенными данными не найден"; break;
                    //   case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
                    //   default: message = err[1]; break;
                    // }
                    // window.location.href = '/logout';
                    // throw message;
                }
            })
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Дата создания',
                    field: 'date_create',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: 'Дата обновления',
                    field: 'date_update',
                    sort: 'desc',
                    width: 150,
                },
                {
                    label: 'Наименование',
                    field: 'name_ru',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: 'Активность',
                    field: 'active_str',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: '',
                    field: 'controls',
                    sort: 'disabled',
                    width: 150,
                },
            ],
            rows: this.state.rows,
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список продукции"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Link
                                            to="/products/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать продукцию
                                        </Link>
                                    </CardHeader>

                                    <CardBody>
                                        <MDBDataTable
                                            responsive
                                            striped
                                            bordered
                                            btn={true}
                                            entriesOptions={[20, 40, 60]}
                                            entries={20}
                                            data={data}
                                        />
                                    </CardBody>

                                    <CardFooter>
                                        <Link
                                            to="/products/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать продукцию
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Products
