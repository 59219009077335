import React, { Component } from 'react'
import { Table, InputGroupAddon, Row, Col, Card, FormGroup } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import AvSelect from '../../components/Common/AvSelect'

import axios from 'axios'

import './styles/graphs.css'

class GraphsDataTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [
                { id: 1, 1: '', 2: '' },
                { id: 2, 1: '', 2: '' },
            ],
            color: ['', ''],
            options: [],
            options_map: {},
            options_color: [],
            options_map_color: {},
            errorInput: '',
            type: [{ id: null }],
            isReload: true,
        }

        this.appendColumn = this.appendColumn.bind(this)
        this.deleteColumn = this.deleteColumn.bind(this)
        this.appendElement = this.appendElement.bind(this)
        this.deleteElement = this.deleteElement.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSelectChangeColor = this.handleSelectChangeColor.bind(this)
    }

    componentDidMount() {
        const data = []
        const dataColor = []
        const dataMap = {}
        const dataColorMap = {}
        const color = []

        axios
            .get('/handbook/graph_constants/input_list')
            .then((response) => {
                data.push({ id: '', name: '(не выбрано)' })
                dataMap[''] = { id: '', name: '(не выбрано)' }

                for (const d in response.data.data) {
                    data.push(response.data.data[d])
                    dataMap[response.data.data[d].id] = response.data.data[d]
                }

                this.setState({
                    options: data,
                    options_map: dataMap,
                })
            })
            .catch((err) => {
                console.log(err.message)
            })

        axios
            .get('/handbook/colors/input_list')
            .then((response) => {
                for (const d in response.data.data) {
                    dataColor.push(response.data.data[d])
                    dataColorMap[response.data.data[d].color] =
                        response.data.data[d]

                    if (parseInt(d) < 2) {
                        color.push(response.data.data[d].color)
                    }
                }

                if (color.length) {
                    this.setState({
                        color: color,
                    })
                }

                this.setState({
                    options_color: dataColor,
                    options_map_color: dataColorMap,
                })
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.value.length && prevProps.value !== this.props.value) {
            setTimeout(() => {
                this.setState({ data: this.props.value })

                if (
                    this.props.color &&
                    this.props.color.length <
                        Object.keys(this.props.value[0]).length - 1
                ) {
                    // this.state.color.push('');
                    const color = this.state.options_color
                        .map(function (value, index) {
                            return value.color
                        })
                        .slice(0, Object.keys(this.props.value[0]).length - 1)
                    this.setState({ color: color })
                } else {
                    this.setState({ color: this.props.color })
                }
            }, 500)
        }

        if (
            this.props.type &&
            this.props.type.length &&
            prevProps.type &&
            this.props.type[0].id &&
            prevProps.type[0].id !== this.props.type[0].id
        ) {
            this.setState({ type: this.props.type })
        }
    }

    appendColumn(index) {
        const data = this.state.data
            .map((row) => {
                return Object.entries(row).reduce((acc, entry) => {
                    if ((Number(entry[0]) || 0) <= index) {
                        acc[entry[0]] = entry[1]
                    } else {
                        acc[Number(entry[0]) + 1] = entry[1]
                    }
                    return acc
                }, {})
            })
            .map((el) => {
                el[index + 1] = ''
                return el
            })

        const keyColor = Object.keys(this.state.data[0]).length - 1
        let color = this.state.options_color[0].color

        if (this.state.options_color[keyColor]) {
            color = this.state.options_color[keyColor].color
        }

        const colors = [...this.state.color]
        colors.splice(index, 0, color)

        this.setState({
            data: data,
            color: colors,
        })
    }

    deleteColumn(index) {
        const count = Object.keys(this.state.data[0]).length - 1
        if (count <= 2 || index == 1) return

        if (Object.keys(this.state.data[0]).length > 3) {
            this.state.color.splice(index - 1, 1)
        }

        const obj = this.state.data.map((p) => {
            const size = index
            let keyObject = 1
            const newObject = {}
            // let count = Object.keys(p).length - 1;console.log(count);

            // if(count <= 2) return;

            delete p[size]

            newObject.id = p.id

            for (const key in p) {
                if (p.hasOwnProperty(key) && key !== 'id') {
                    newObject[keyObject] = p[key]
                    delete p[key]
                    keyObject++
                }
            }

            return newObject
        })

        this.setState({
            data: obj,
            color: this.state.color,
        })
    }

    appendElement(index) {
        const data = this.state.data.map((el) => {
            if (el.id <= index) {
                return el
            }
            return ++el.id && el
        })

        const insertingObj = Object.entries(data[0]).reduce((acc, el) => {
            if (el[0] !== 'id') {
                acc[el[0]] = ''
                return acc
            }
            acc[el[0]] = index + 1
            return acc
        }, {})

        data.splice(index, 0, insertingObj)

        this.setState({ data: data })
    }

    deleteElement(index) {
        const count = this.state.data.length - 1
        if (count <= 1) return

        this.state.data.splice(index, 1)

        this.setState({ data: this.state.data })
    }

    editColumn(p, k, e) {
        const inputValue = e.target.value
        const obj = p.p
        const objId = obj.id
        const position = k.k
        const values = Object.values(obj)

        // if(values.indexOf(inputValue) == -1){
        // obj[position] = parseInt(inputValue);
        obj[position] = inputValue
        const stateCopy = this.state.data
        stateCopy.map((object, index) => {
            if (object.id == objId) {
                object = obj[position]
            }
        })
        this.setState(stateCopy)
        // console.log(stateCopy,'stateCopystateCopy');
        // }
    }

    handleSelectChange(key, index, object, name, value) {
        var $this = this
        if (value === null) {
            value = [{ id: '', name: '(не выбрано)' }]
            this.setState({ isReload: true })
        }

        this.state.data[key][index] = value.map(function (val, index) {
            if (value.length > 1 && value[index].id == '') {
                $this.setState({ isReload: true })
                return
            }
            return val.id
        })

        this.state.data[key][index] = this.state.data[key][index].filter(
            function (el) {
                return el != null
            }
        )

        // console.log(key);
        // console.log(index);
        // console.log(object);
        // console.log(name);
        // console.log(value);
    }

    handleSelectChangeColor(key, index, object, name, value) {
        this.state.color[key] = value[0].color
    }

    setSelectValue(value, key) {
        let result = []

        if (Array.isArray(value[key])) {
            value[key].map((id, index) => {
                if (this.state.options_map[id]) {
                    result.push(this.state.options_map[id])
                }
            })
        } else {
            result = this.state.options_map[value[key]]
        }

        return result
    }

    getGraphsData() {
        return {
            data: this.state.data,
            color: this.state.color,
        }
    }

    render() {
        const tableStyle = {
            align: 'center',
        }

        let x = 0
        let y = 0
        let c = 0
        let v = 0

        const numbersCount = 0
        // let patternInput = this.state.type[0].id == 'table' ? '^[0-9]+$|^[0-9]+\.[0-9]+$|^\-[0-9]+$|^\-[0-9]+\.[0-9]+$|^\-$' : '^[0-9]+$|^[0-9]+\.[0-9]+$';
        const patternInput =
            '^[0-9]+$|^[0-9]+.[0-9]+$|^-[0-9]+$|^-[0-9]+.[0-9]+$|^-$'

        const colorList = this.state.color.map((p, key) => {
            let valueColor = this.state.options_map_color[p] // || this.state.options_color[key];

            if (valueColor) {
                valueColor = [valueColor]
            }

            c++
            return (
                <td
                    className="grey1 color-for-z-index"
                    style={{ width: '230px' }}
                    key={key + '' + c}
                >
                    <div className="row">
                        <div className="col-lg-10">
                            <AvSelect
                                options={this.state.options_color}
                                label={'Цвет (' + c + ')'}
                                errorMessage={'Обязательно к заполнению'}
                                value={
                                    valueColor || [
                                        { id: '', name: '(не выбрано)' },
                                    ]
                                }
                                handleSelectChange={this.handleSelectChangeColor.bind(
                                    this,
                                    key,
                                    c,
                                    key + c
                                )}
                                name={'color' + key}
                                typeValueMulti="one"
                                key={key + '' + c}
                            />
                        </div>
                        <div className="col-lg-2 icon-demo-content">
                            <i
                                className="mdi mdi-plus"
                                style={{ cursor: 'pointer' }}
                                onClick={this.appendColumn.bind(this, c)}
                            ></i>
                        </div>
                    </div>
                </td>
            )
        })

        const list = this.state.data.map((p, key) => {
            return (
                <tr className="grey2" key={p.id + '' + key}>
                    {Object.keys(p)
                        .filter((k) => k !== 'id')
                        .map((k, vv) => {
                            if (key == 0) {
                                x++
                                return (
                                    <td
                                        className="grey1"
                                        key={p.id + '' + key + k}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <FormGroup
                                                style={{
                                                    width:
                                                        vv !== 0
                                                            ? '200px'
                                                            : '280px',
                                                }}
                                            >
                                                <AvSelect
                                                    options={this.state.options}
                                                    label={
                                                        'Значение для оси X (' +
                                                        x +
                                                        ')'
                                                    }
                                                    errorMessage={
                                                        k != 1
                                                            ? 'Обязательно к заполнению'
                                                            : ''
                                                    }
                                                    value={this.setSelectValue(
                                                        p,
                                                        k
                                                    )}
                                                    handleSelectChange={this.handleSelectChange.bind(
                                                        this,
                                                        key,
                                                        k,
                                                        key + k
                                                    )}
                                                    name={key + k}
                                                    typeValueMulti="one"
                                                    key={p.id + '' + key + k}
                                                    multiple
                                                />
                                                {vv === 0 && (
                                                    <div className="icon-demo-content">
                                                        <i
                                                            className="mdi mdi-plus"
                                                            style={{
                                                                cursor:
                                                                    'pointer',
                                                            }}
                                                            onClick={this.appendElement.bind(
                                                                this,
                                                                key + 1
                                                            )}
                                                        ></i>
                                                    </div>
                                                )}
                                            </FormGroup>

                                            <FormGroup>
                                                <label
                                                    htmlFor="11"
                                                    style={{ opacity: '0' }}
                                                >
                                                    Удалить
                                                </label>
                                                <InputGroupAddon
                                                    addonType="append"
                                                    onClick={this.deleteColumn.bind(
                                                        this,
                                                        k
                                                    )}
                                                >
                                                    <div
                                                        className="btn btn-primary"
                                                        style={{
                                                            marginLeft: '16px',
                                                        }}
                                                    >
                                                        <i className="mdi mdi-close"></i>
                                                    </div>
                                                </InputGroupAddon>
                                            </FormGroup>
                                        </div>
                                    </td>
                                )
                            }

                            if (k == 1) {
                                y++
                                return (
                                    <td
                                        className="grey1"
                                        key={p.id + '' + key + k}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <FormGroup
                                                style={{ width: '280px' }}
                                            >
                                                <AvSelect
                                                    options={this.state.options}
                                                    label={
                                                        'Значение для оси Y (' +
                                                        y +
                                                        ')'
                                                    }
                                                    // errorMessage={'Обязательно к заполнению'}
                                                    value={this.setSelectValue(
                                                        p,
                                                        k
                                                    )}
                                                    handleSelectChange={this.handleSelectChange.bind(
                                                        this,
                                                        key,
                                                        k,
                                                        key + k
                                                    )}
                                                    name={key + k}
                                                    typeValueMulti="one"
                                                    key={p.id + '' + key + k}
                                                    multiple
                                                />
                                                <div className="icon-demo-content">
                                                    <i
                                                        className="mdi mdi-plus"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={this.appendElement.bind(
                                                            this,
                                                            key + 1
                                                        )}
                                                    ></i>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <label
                                                    htmlFor="11"
                                                    style={{ opacity: '0' }}
                                                >
                                                    Удалить
                                                </label>
                                                <InputGroupAddon
                                                    addonType="append"
                                                    onClick={this.deleteElement.bind(
                                                        this,
                                                        key
                                                    )}
                                                >
                                                    <div
                                                        className="btn btn-primary"
                                                        style={{
                                                            marginLeft: '16px',
                                                        }}
                                                    >
                                                        <i className="mdi mdi-close"></i>
                                                    </div>
                                                </InputGroupAddon>
                                            </FormGroup>
                                        </div>
                                    </td>
                                )
                            }

                            v++

                            return (
                                <td className="grey1" key={p.id + '' + k}>
                                    <AvField
                                        // style={{marginTop: '20px'}}
                                        name={key + k}
                                        label={'Значение (' + v + ')'}
                                        placeholder="Введите значение"
                                        suppressContentEditableWarning="true"
                                        contentEditable="true"
                                        onInput={this.editColumn.bind(
                                            this,
                                            { p },
                                            { k }
                                        )}
                                        value={p[k]}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                            pattern: {
                                                value: patternInput,
                                                errorMessage:
                                                    'Неверное значение',
                                            },
                                        }}
                                    />
                                </td>
                            )
                        })}
                </tr>
            )
        })

        return (
            <fieldset>
                <div className="schedule padd-lr">
                    {/*
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-sm-6" style={{marginTop: '24px', marginBottom: '24px'}}>     
                            <InputGroupAddon addonType="append" onClick={this.deleteColumn}>
                                <div className="btn btn-primary" style={{marginRight: '16px'}}>
                                    <i className="mdi mdi-close"></i>
                                </div>
                                <span>Удалить последнее поле по оси X</span>
                            </InputGroupAddon>                
                        </div>

                        <div className="col-xl-3 col-lg-4 col-sm-6" style={{marginTop: '24px', marginBottom: '24px'}}>   
                            <InputGroupAddon addonType="append" onClick={this.deleteElement}>
                                <div className="btn btn-primary" style={{marginRight: '16px'}}>
                                    <i className="mdi mdi-close"></i>
                                </div>
                                <span>Удалить последнее поле по оси Y</span>
                            </InputGroupAddon>                
                        </div>
                    </div>
                    */}

                    <div className={'row'}>
                        <div
                            style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                width: '100%',
                            }}
                        >
                            <Table
                                cellSpacing="3"
                                id="mytable"
                                style={tableStyle}
                            >
                                <div
                                    style={{
                                        width: `${
                                            (list[0].props.children.length -
                                                1) *
                                                200 +
                                            280
                                        }px`,
                                        height: `${
                                            165 * (list.length + 1) + 25
                                        }px`,
                                    }}
                                >
                                    <tbody>
                                        <tr className="grey2">{colorList}</tr>
                                    </tbody>
                                    <tbody>{list}</tbody>
                                </div>
                            </Table>
                        </div>
                    </div>
                </div>
            </fieldset>
        )
    }
}

export default GraphsDataTable
