import React, { Component } from 'react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter, Link } from 'react-router-dom'

// i18n
import { withNamespaces } from 'react-i18next'

import { connect } from 'react-redux'
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader,
} from '../../store/actions'

import axios from 'axios'

import ListConstantLang from '../../components/Common/ListConstantLang'

class SidebarContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            langList: [],
        }
    }

    componentDidMount() {
        this.initMenu()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu()
            }
        }
    }

    initMenu() {
        new MetisMenu('#side-menu')

        var matchingMenuItem = null
        var ul = document.getElementById('side-menu')
        var items = ul.getElementsByTagName('a')
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }

        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem)
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add('active')
        const parent = item.parentElement

        if (parent) {
            parent.classList.add('mm-active')
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add('mm-show')

                const parent3 = parent2.parentElement

                if (parent3) {
                    parent3.classList.add('mm-active') // li
                    parent3.childNodes[0].classList.add('mm-active') // a
                    const parent4 = parent3.parentElement

                    if (parent4) {
                        parent4.classList.add('mm-active')
                        parent4.classList.add('mm-show')
                        const parent5 = parent4.parentElement

                        if (parent5) {
                            parent5.classList.add('mm-active')
                        }
                    }
                }
            }
            return false
        }
        return false
    }

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Menu')}</li>

                        <li>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="ri-dashboard-line"></i>
                                <span className="ml-1">
                                    {this.props.t('Dashboard')}
                                </span>
                            </Link>
                        </li>

                        <li className="menu-title">Пользователи</li>

                        <li>
                            <Link to="/user_list" className="waves-effect">
                                <i className="ri-account-circle-line" />
                                <span className="ml-1">
                                    {this.props.t('Users')}
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/roles/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    Управление доступом
                                </span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link
                                        to="/user_role_group"
                                        className="waves-effect"
                                    >
                                        <i className="ri-table-2"></i>
                                        <span className="ml-1">Группы</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/user_role"
                                        className="waves-effect"
                                    >
                                        <i className="ri-table-2"></i>
                                        <span className="ml-1">Роли</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/user_package" className="waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Тарифный план</span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/subscription/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    Управление подписками
                                </span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link
                                        to="/subscription"
                                        className="waves-effect"
                                    >
                                        <i className="ri-table-2"></i>
                                        <span className="ml-1">Подписки</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/contract"
                                        className="waves-effect"
                                    >
                                        <i className="ri-table-2"></i>
                                        <span className="ml-1">Договоры</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="menu-title">Локализация</li>

                        <li>
                            <Link to="/languages" className="waves-effect">
                                <i className="ri-account-circle-line" />
                                <span className="ml-1">Языки </span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/lang/main"
                                className="has-arrow waves-effect"
                            >
                                <span className="ml-1">Настройки</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/lang/main">
                                        Языковые константы (основные)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/lang/landing">
                                        Языковые константы (лендинг)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/lang/study_industry">
                                        Языковые константы (Исследование
                                        отрасли)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/lang/study_region">
                                        Языковые константы (Исследование
                                        региона)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/lang/about">
                                        Языковые константы (О платформе)
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="menu-title">
                            {this.props.t('Purchasers')}
                        </li>

                        <li>
                            <Link to="/purchaser" className="waves-effect">
                                <i className="ri-account-circle-line" />
                                <span className="ml-1">
                                    {this.props.t('PurchasersList')}
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    {this.props.t('PurchasersSettings')}
                                </span>
                            </Link>
                        </li>

                        <li className="menu-title">
                            {this.props.t('Content')}
                        </li>

                        <li>
                            <Link
                                to="/handbooks/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    {this.props.t('Handbooks')}
                                </span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                {/* <li> */}
                                {/*    <Link to="/handbooks/purchaser_status"> */}
                                {/*        {this.props.t('PurchaserStatus')} */}
                                {/*    </Link> */}
                                {/* </li> */}
                                {/* <li> */}
                                {/*    <Link to="/handbooks/purchaser_type"> */}
                                {/*        {this.props.t('PurchaserType')} */}
                                {/*    </Link> */}
                                {/* </li> */}
                                <li>
                                    <Link to="/handbooks/main_module_name/">
                                        Наименования главных модулей
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/industry">
                                        Отрасль
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/sub_industry">
                                        Подотрасль
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/production">
                                        Продукция
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/delivery_terms">
                                        Условия доставки
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/payment_terms">
                                        Условия оплаты
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/trademark">
                                        Торговая марка
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/employee_quantity">
                                        Количество сотрудников
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/revenue">
                                        Годовая выручка
                                    </Link>
                                </li>
                                {/* <li> */}
                                {/*    <Link to="/handbooks/import_geo"> */}
                                {/*        География импорта */}
                                {/*    </Link> */}
                                {/* </li> */}
                                {/* <li> */}
                                {/*    <Link to="/handbooks/products"> */}
                                {/*        Продукция */}
                                {/*    </Link> */}
                                {/* </li> */}
                                <li>
                                    <Link to="/handbooks/country/">
                                        {this.props.t('Country')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/region/">
                                        {this.props.t('Region')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/gender/">
                                        {this.props.t('Gender')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/subject/">
                                        Тематика
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/rubric/">Рубрики</Link>
                                </li>
                                {/* <li> */}
                                {/*    <Link to="/handbooks/site_language/"> */}
                                {/*        {this.props.t('SiteLanguage')} */}
                                {/*    </Link> */}
                                {/* </li> */}
                                <li>
                                    <Link to="/handbooks/pictogram">
                                        Пиктограммы
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/type_event/">
                                        Тип мероприятий
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/user_country/">
                                        Страны (пользователи)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/organization_type/">
                                        Тип структуры/организации
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/user_category/">
                                        Категории (пользователи)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/type_associations/">
                                        Тип ассоциаций
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/type_experts/">
                                        Тип экспертов
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/experts_business_size/">
                                        Размер бизнеса
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/rubric_analytics/">
                                        Рубрики (Аналитика)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/analytics_task/">
                                        Задачи (Аналитика)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/graph_name/">
                                        Наименование графиков
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/graph_constants/">
                                        Константы графиков
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/analytics_name/">
                                        Наименование аналитики
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/analytics_source/">
                                        Источники аналитики
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/time_periods/">
                                        Периоды
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/units/">
                                        Единицы измерения
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/discharges/">
                                        Разряды
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/colors/">Цвета</Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/type_investment_sites/">
                                        Тип инвестплощадки
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/investment_sites_specialization/">
                                        Специализация инвестплощадки
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/project_category/">
                                        Категория проекта
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/project_status/">
                                        Статус проекта
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/project_type/">
                                        Вид проекта
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/type_of_work/">
                                        Вид работ
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/object_type/">
                                        Вид объекта
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/currency/">
                                        Валюты
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/payment_currency/">
                                        Валюты платежа
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/object_category/">
                                        Категория объекта
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/object_status/">
                                        Статус объекта
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/trade_partner_type/">
                                        Тип торгового партнера
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/holidays_and_events/">
                                        Праздники и события
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/readiness/">
                                        Виды готовности
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/handbooks/roadmap/">
                                        Константы дорожных карт
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/tag" className="waves-effect">
                                <i className="ri-table-2" />
                                <span className="ml-1">
                                    {this.props.t('Tag')}
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/news/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    {this.props.t('News')}
                                </span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/news">RU</Link>
                                </li>
                                <li>
                                    <Link to="/news_en">EN</Link>
                                </li>

                                <li>
                                    <Link
                                        to="/lang/news"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/news">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/events/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    {this.props.t('Events')}
                                </span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/events">
                                        {this.props.t('Events')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/events"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/events">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/associations/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Ассоциации</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/associations">Ассоциации</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/associations"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/associations">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                              to="/experts/"
                              className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Профессиональные провайдеры услуг</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/experts">Профессиональные провайдеры услуг</Link>
                                </li>
                                <li>
                                    <Link
                                      to="/lang/experts"
                                      className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                      className="sub-menu"
                                      aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/experts">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/projects/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Проекты</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/projects">Проекты</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/projects"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/projects">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/objects/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Объекты</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/objects">Объекты</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/objects"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/objects">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/investment_sites/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Инвестплощадки</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/investment_sites">
                                        Инвестплощадки
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/investment_sites"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/investment_sites">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/organizations/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Организации</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/organizations">Организации</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/organizations"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/organizations">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/products/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Продукция</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/products">Продукция</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/products"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/products">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/business_calendar/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">
                                    Календарь деловой активности
                                </span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/business_calendar/events">
                                        События
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/business_calendar/working_days">
                                        Рабочие/нерабочие дни
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/business_calendar/official_holidays">
                                        Официальные праздники
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/business_calendar/unofficial_holidays">
                                        Неофициальные праздники
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/business_calendar_events"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/business_calendar_events">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/infoblock/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Инфоблоки</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/infoblock">Инфоблоки</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/infoblock"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/infoblock">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/module-filters" className="waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Фильтры модулей</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/roadmap-template" className="waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Шаблоны дорожной карты</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/graphs" className="waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Графики </span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/analytics/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Аналитика</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/analytics">Аналитика</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/analytics"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/analytics">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link
                                to="/trade_partners/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Торговые партнеры</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/trade_partners">
                                        Торговые партнеры
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/lang/trade_partners"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/trade_partners">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    
                        <li>
                            <Link
                                to="/user_lists/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Мои списки</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link
                                        to="/lang/user_lists"
                                        className="has-arrow waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                    <ul
                                        className="sub-menu"
                                        aria-expanded="false"
                                    >
                                        <li>
                                            <Link to="/lang/user_lists">
                                                Языковые константы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="menu-title">Параметры</li>

                        <li>
                            <Link to="/settings" className="waves-effect">
                                <i className="ri-share-line" />
                                <span className="ml-1">Настройки платформы</span>
                            </Link>
                        </li>

                        {/* <li>
                            <Link
                                to="/settings/"
                                className="has-arrow waves-effect"
                            >
                                <i className="ri-table-2"></i>
                                <span className="ml-1">Параметры</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link
                                        to="/settings"
                                        className="waves-effect"
                                    >
                                        <span className="ml-1">Настройки</span>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                    
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state.Layout }
}

export default withRouter(
    connect(mapStateToProps, {
        changeLayout,
        changeSidebarTheme,
        changeSidebarType,
        changeLayoutWidth,
        changePreloader,
    })(withNamespaces()(SidebarContent))
)
