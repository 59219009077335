import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
} from 'reactstrap'
import AvSelect from '../../components/Common/AvSelect'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class PurchaserForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список закупщиков', link: '/purchaser' },
            ],
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        const fd = new FormData(document.getElementById('purchaser'))
        fd.append('createdBy', jsonUser.userId)

        axios
            .post('/purchaser/create', fd)
            .then((response) => {
                console.log(response)
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    // TODO validate select, add multiple select, maybe select2
    render() {
        const select = {
            user: [
                { value: 'chocolate', label: 'Chocolate' },
                { value: 'strawberry', label: 'Strawberry' },
                { value: 'vanilla', label: 'Vanilla' },
            ],

            status: [
                { value: 0, label: 'Не активен' },
                { value: 1, label: 'Активен' },
            ],
        }

        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="purchaser">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="isActive"
                                            defaultChecked
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="isActive"
                                            onClick={() => {
                                                this.setState({
                                                    toggleSwitch: !this.state
                                                        .toggleSwitch,
                                                })
                                            }}
                                        >
                                            Активен
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvSelect
                                options={select.user}
                                label="Пользователь"
                                placeholder="Выберите пользователя"
                                errorMessage="Выберите пользователя"
                                name="user"
                            />

                            <Row>
                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Статус"
                                        placeholder="Выберите статус"
                                        errorMessage="Выберите статус"
                                        name="purchaserStatus"
                                    />
                                </Col>

                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Тип"
                                        placeholder="Выберите тип"
                                        errorMessage="Выберите тип"
                                        name="type"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AvField
                                        label="Наименование"
                                        name="name"
                                        placeholder="Введите наименование"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                            pattern: {
                                                value: '^[А-Яа-я]+$',
                                                errorMessage:
                                                    'Может содержать только кириллицу',
                                            },
                                        }}
                                    />
                                </Col>

                                <Col>
                                    <AvField
                                        label="Наименование английское"
                                        name="nameEng"
                                        placeholder="Введите наименование английское"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                            pattern: {
                                                value: '^[A-Za-z]+$',
                                                errorMessage:
                                                    'Может содержать только английские символы',
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <AvField
                                label="ИНН (БНН...)"
                                name="itn"
                                placeholder="Введите ИНН (БНН ...)"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[0-9]+$',
                                        errorMessage:
                                            'Введите числовое значение',
                                    },
                                }}
                            />

                            <Row>
                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Страна"
                                        placeholder="Выберите страну"
                                        errorMessage="Выберите страну"
                                        name="country"
                                    />
                                </Col>

                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Регионы деятельности"
                                        placeholder="Выберите регионы"
                                        errorMessage="Выберите регионы"
                                        name="region"
                                        multiple
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Отрасли"
                                        placeholder="Выберите Отрасли"
                                        errorMessage="Выберите Отрасли"
                                        name="industry"
                                    />
                                </Col>

                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Подотрасли"
                                        placeholder="Выберите подотрасли"
                                        name="subIndustry"
                                        multiple
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AvField
                                        label="Общий телефон"
                                        name="tel"
                                        placeholder="Введите телефон"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                        }}
                                    />
                                </Col>

                                <Col>
                                    <AvField
                                        label="Общая электронная почта"
                                        name="email"
                                        placeholder="Введите Email"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                            email: {
                                                value: true,
                                                errorMessage:
                                                    'Некорректный Email',
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label htmlFor="comment">
                                    Короткий комментарий
                                </Label>
                                <textarea
                                    id="comment"
                                    name="comment"
                                    className="form-control"
                                    rows="3"
                                ></textarea>
                            </FormGroup>

                            <AvField
                                label="Сайт"
                                type="url"
                                name="www"
                                placeholder="Введите адрес сайта"
                            />

                            <FormGroup>
                                <Label htmlFor="logo">
                                    Логотип закупщика {/* //TODO dropzone ?? */}
                                </Label>
                                <div className="custom-control">
                                    <CustomInput
                                        type="file"
                                        className="custom-file-input"
                                        name="logo"
                                        id="logo"
                                    />
                                    <Label
                                        className="custom-file-label"
                                        htmlFor="customFile"
                                    >
                                        Выберите файл
                                    </Label>
                                </div>
                            </FormGroup>

                            <AvSelect
                                options={select.status}
                                label='"Зонтичная" торговая марка'
                                placeholder="Выберите торговую марку"
                                name="trademark"
                            />

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="description">
                                            Описание закупщика
                                        </Label>
                                        <textarea
                                            id="description"
                                            name="description"
                                            className="form-control"
                                            rows="4"
                                        ></textarea>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="descriptionEng">
                                            Описание закупщика английское
                                        </Label>
                                        <textarea
                                            id="descriptionEng"
                                            name="descriptionEng"
                                            className="form-control"
                                            rows="4"
                                        ></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="year">
                                            Год основания компании{' '}
                                            {/* //TODO year input */}
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="year"
                                            id="year"
                                            name="year"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Кол-во сотрудников"
                                        placeholder="Выберите кол-во сотрудников"
                                        name="employees"
                                    />
                                </Col>

                                <Col>
                                    <AvSelect
                                        options={select.status}
                                        label="Годовая выручка"
                                        placeholder="Выберите годовую выручку"
                                        name="revenue"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Инфраструктура закупщика
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="shopQuantity">
                                            Магазины
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            id="shopQuantity"
                                            name="shopQuantity"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="wwwShopQuantity">
                                            Интернет-магазины
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            id="wwwShopQuantity"
                                            name="wwwShopQuantity"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="warehouse">
                                            Склады
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            id="warehouse"
                                            name="warehouse"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvSelect
                                options={select.status}
                                label="География импорта"
                                placeholder="Выберите географию импорта"
                                name="importGeo"
                                multiple
                            />

                            <AvSelect
                                options={select.status}
                                label="Возможные условия поставок"
                                placeholder="Выберите условия поставок"
                                name="deliveryTerms"
                                multiple
                            />

                            <AvSelect
                                options={select.status}
                                label="Возможные условия оплаты"
                                placeholder="Выберите условия оплаты"
                                name="paymentTerms"
                                multiple
                            />

                            <AvSelect
                                options={select.status}
                                label="Собственные торговые марки"
                                placeholder="Выберите торговые марки"
                                name="ownTrademarks"
                                multiple
                            />

                            <FormGroup>
                                <Label htmlFor="needProduction">
                                    Контрактное производство
                                </Label>
                                <div className="custom-control custom-switch pt-2">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="needProduction"
                                    />
                                    <Label
                                        className="custom-control-label"
                                        htmlFor="needProduction"
                                        onClick={() => {
                                            this.setState({
                                                toggleSwitch: !this.state
                                                    .toggleSwitch,
                                            })
                                        }}
                                    >
                                        Требуется
                                    </Label>
                                </div>
                            </FormGroup>

                            <AvSelect
                                options={select.status}
                                label="Интересующая продукция"
                                placeholder="Выберите продукцию"
                                name="intresProducts"
                                multiple
                            />

                            <AvSelect
                                options={select.status}
                                label="Интересующая торговая марка"
                                placeholder="Выберите торговые марки"
                                name="intresMark"
                                multiple
                            />

                            <AvSelect
                                options={select.status}
                                label="Связь с другими закупщиками"
                                placeholder="Выберите закупщиков"
                                name="intresMark2"
                                multiple
                            />

                            <AvSelect
                                options={select.status}
                                label="Работа с экспортерами"
                                placeholder="Выберите экспортеров"
                                name="intresMark3"
                                multiple
                            />

                            <FormGroup>
                                <Label htmlFor="ownTrademarks">
                                    Место на карте
                                </Label>
                                <div>
                                    Карта с поисковиком, которая сохраняет
                                    координаты на вход принимает тоже координаты
                                </div>
                            </FormGroup>
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="blacklist"
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="blacklist"
                                            onClick={() => {
                                                this.setState({
                                                    toggleSwitch: !this.state
                                                        .toggleSwitch,
                                                })
                                            }}
                                        >
                                            Состоит в черном списке
                                        </Label>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default PurchaserForm
