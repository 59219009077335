import NewsEn from '../pages/NewsEn/index'
import NewsAddEn from '../pages/NewsEn/add'
import NewsEditEn from '../pages/NewsEn/edit'

const newsRoutesEn = [
    { path: '/news_en/update/:id', component: NewsEditEn },
    { path: '/news_en/create', component: NewsAddEn },
    { path: '/news_en', component: NewsEn },
]

export { newsRoutesEn }
