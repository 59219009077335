import React, { Component } from 'react'
import { MDBDataTable, MDBPagination } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Button,
    FormGroup,
} from 'reactstrap'
import { Link } from "react-router-dom";
import AvSelect from '../../components/Common/AvSelect'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DropdownControls from '../../components/Common/DropdownControls';
import axios from 'axios'
import { logoutUser } from '../../store/actions';
import {
  AvForm,
  AvGroup,
  AvField,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './style.css'
import store from '../../store'
import {
    setMax,
    setPage,
    setQuantity,
    setSearch,
    setSort,
    isNeedRefresh,
} from '../../store/roadmap-template-page/actionsCreators'
import { PaginationAmountEntityShow } from '../../components/Common/PaginationAmountEntityShow'
import { connect } from 'react-redux'
import { PaginationPageSizeSelect } from '../../components/Common/PaginationPageSizeSelect'
import { SearchWidget } from '../../components/Common/SearchWidget'

const user = localStorage.getItem('authUser');
let jsonUser = JSON.parse(user);

const getIdFromSelect = (arr) => {
  arr = arr || []
  const ids = []
  arr.map((el) => ids.push(el.id))
  return ids
}

class RoadmapTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "ExportLink", link : "/" },
        { title : "Шаблоны дорожной карты", link : "/roadmap-template" },
      ],
      country: null,
      region: null,
      industry: null,
      subIndustry: null,
      regionOptions: [],
      subIndustryOptions: [],
      isNeedRenerder: false,
      rows: []
    };

    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  refreshData = () => {
    const pageStore = store.getState().roadmapTemplatePageReducer
    axios
        .get(
            `/roadmap-template/list?` +
                `quantity=${pageStore.entryQuantity}&` +
                `page=${pageStore.page}&` +
                `search=${pageStore.search}&` +
                `sort_by=${pageStore.currentSortBy || ''}&` +
                `sort_order=${pageStore.currentSortOrder || ''}`
        )
        .then(response => {
          if (response.status >= 200) {
            const data = response.data.data;
    
            for (let i = 0; i < data.length; i++) {
              data[i].controls = <DropdownControls 
              id={data[i].id} 
              nameFile={data[i].name} 
              country={this.state.country}
              region={this.state.region} 
              industry={this.state.industry}
              subIndustry={this.state.subIndustry}
              data={this.state.rows} 
              actions={{
                update: `/roadmap-template/update/${data[i].id}/`,
                word: `/roadmap-template/pdf_generate`,
                delete: `/roadmap-template/delete`
              }} />;
    
              if (data[i].active) {
                data[i].active = 'Да';
              } else {
                data[i].active = 'Нет';
              }
            }
    
            this.setState({rows: data})
          }
        })
        .catch((err) => {
            // let message;
            // if (err.response && err.response.status) {
            //   switch (err.response.status) {
            //     case 401: message = "Пользователь с введенными данными не найден"; break;
            //     case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
            //     default: message = err[1]; break;
            //   }
            //   window.location.href = '/logout';
            // }
            // throw message;
        })
    }

  componentDidMount() {
      this.refreshData()
  }

  onChangePage = (current) => {
    store.dispatch(setPage(Number(current)))
    store.dispatch(isNeedRefresh(true))
    // this.setState({ isNeedRenerder: true });
  }

  onChangePerPage = (value) => {
    store.dispatch(setQuantity(Number(value)))
    store.dispatch(setPage(1))
    store.dispatch(isNeedRefresh(true))
    this.setState({ isNeedRenerder: true })
  }

  onChangeSearch = (value) => {
    store.dispatch(setSearch(value))
    store.dispatch(setPage(1))
    store.dispatch(isNeedRefresh(true))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (store.getState().roadmapTemplatePageReducer.isNeedRefresh) {
          store.dispatch(isNeedRefresh(false))
          this.refreshData()
      }
      
      if (
        prevState.country !== this.state.country
        || prevState.region !== this.state.region
        || prevState.industry !== this.state.industry
        || prevState.subIndustry !== this.state.subIndustry
      ) {
        for (let i = 0; i < this.state.rows.length; i++) {
          this.state.rows[i].controls = <DropdownControls 
          id={this.state.rows[i].id} 
          nameFile={this.state.rows[i].name} 
          country={this.state.country}
          region={this.state.region} 
          industry={this.state.industry}
          subIndustry={this.state.subIndustry}
          actions={{
            update: `/roadmap-template/update/${this.state.rows[i].id}/`,
            word: `/roadmap-template/pdf_generate`,
            delete: `/roadmap-template/delete`
          }} />; 
        }
      }
  }

  handleSelectChange(key, value, child) {
    this.setState({
        [key]: value,
    })

    if (child) {
        const ids = getIdFromSelect(value)

        const requests = ids.map((id) => {
            return axios.get(
                `/handbook/${child.key}/input_list?${key}[]=${id}`
            )
        })

        const data = []

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    responses.map((request) => {
                        request.data.data.map((item) => data.push(item))
                    })
                })
            )
            .finally(() => {
                this.setState({
                    [child.options]: data,
                })
            })
    }
  }

  render() {
    const defaultColumns = [
      {
        label: "Дата создания",
        field: "date_create",
        sort: store.getState().roadmapTemplatePageReducer.date_create,
        width: 150
      },
      {
        label: "Дата изменения",
        field: "date_update",
        sort: store.getState().roadmapTemplatePageReducer.date_update,
        width: 150
      },
      {
        label: "Наименование",
        field: "name",
        sort: store.getState().roadmapTemplatePageReducer.name,
        width: 150
      },
      {
        label: "Автор",
        field: "user_name",
        sort: 'disabled',
        width: 150
      },
      {
        label: "Последнее изменение",
        field: "user_update",
        sort: 'disabled',
        width: 150
      },
      {
        label: "Активность",
        field: "active",
        sort: store.getState().roadmapTemplatePageReducer.active,
        width: 150
      },
      {
        label: '',
        field: 'controls',
        sort: 'disabled',
        width: 150
      }
    ];

    const data = {
      columns: this.props.columns || defaultColumns,
      rows: this.state.rows
    };

    const Table = () => {
      if (this.state.isNeedRenerder) {
          setTimeout(() => {
              this.setState({ isNeedRenerder: false })
          }, 10)
          return <></>
      }

      return (
          <MDBDataTable
              responsive
              striped
              bordered
              sortable
              btn
              displayEntries={false}
              searching={false}
              onSort={(res) => {
                  if (res.direction && res.column) {
                      const data = {
                          currentSortBy: res.column,
                          currentSortOrder: res.direction,
                          [res.column]:
                              res.direction === 'asc' ? 'desc' : 'asc',
                      }
                      for (const key in store.getState()
                          .roadmapTemplatePageReducer) {
                          if (
                              key === 'date_update' ||
                              key === 'date_create' ||
                              key === 'name' ||
                              key === 'active'
                          ) {
                              if (key !== res.column) {
                                  data[key] = ''
                              }
                          }
                      }console.log(123);
                      store.dispatch(setSort(data))
                      store.dispatch(isNeedRefresh(true))
                  }
              }}
              entries={store.getState().roadmapTemplatePageReducer.entryQuantity}
              data={data}
              info={false}
              paginationLabel={['']}
          />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title="Шаблоны дорожной карты" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>

                  <CardHeader>   
                    <AvForm>
                      <FormGroup>
                          <Row>
                            <Col>   
                              <Link to="/roadmap-template/create" className="btn btn-primary waves-effect waves-light">
                                Добавить
                              </Link> 
                            </Col>
                            <Col>
                              <AvSelect
                                  label="Страна"
                                  name="country"
                                  child={{
                                      key: 'region',
                                      options: 'regionOptions',
                                  }}
                                  value={this.state.country}
                                  api="/handbook/country/input_list"
                                  handleSelectChange={
                                      this.handleSelectChange
                                  }
                              />
                            </Col>
                            <Col>
                              <AvSelect
                                  label="Регион"
                                  name="region"
                                  value={this.state.region}
                                  options={this.state.regionOptions}
                                  handleSelectChange={
                                      this.handleSelectChange
                                  }
                                  isDisabled={
                                      !this.state.regionOptions.length
                                  }
                              />
                            </Col>
                            <Col>
                              <AvSelect
                                  label="Отрасль"
                                  name="industry"
                                  child={{
                                      key: 'sub_industry',
                                      options: 'subIndustryOptions',
                                  }}
                                  value={this.state.industry}
                                  api="/handbook/industry/input_list"
                                  handleSelectChange={
                                      this.handleSelectChange
                                  }
                              />
                            </Col>
                            <Col>
                              <AvSelect
                                  label="Подотрасль"
                                  name="subIndustry"
                                  value={this.state.subIndustry}
                                  options={
                                      this.state.subIndustryOptions
                                  }
                                  handleSelectChange={
                                      this.handleSelectChange
                                  }
                                  isDisabled={
                                      !this.state.subIndustryOptions
                                          .length
                                  }
                              />
                            </Col>
                          </Row>
                      </FormGroup>
                    </AvForm>
                  </CardHeader>

                  <div
                      style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                      }}
                  >
                      <div
                          style={{
                              padding: '20px',
                              boxSizing: 'border-box',
                          }}
                      >
                          <PaginationPageSizeSelect
                              onChangePerPage={
                                  this.onChangePerPage
                              }
                              value={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .entryQuantity
                              }
                          />
                      </div>
                      <div
                          style={{
                              margin: '20px',
                              boxSizing: 'border-box',
                          }}
                      >
                          <SearchWidget
                              value={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .search
                              }
                              onChangeSearch={
                                  this.onChangeSearch
                              }
                              placeholder={'search'}
                          />
                      </div>
                  </div>
                  <CardBody>
                      <div className={'roadmap-template-table'}>
                          {Table()}
                      </div>
                      <MDBPagination>
                          <Pagination
                              defaultPageSize={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .entryQuantity
                              }
                              pageSize={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .entryQuantity
                              }
                              current={
                                  store.getState()
                                      .roadmapTemplatePageReducer.page
                              }
                              defaultCurrent={
                                  store.getState()
                                      .roadmapTemplatePageReducer.page
                              }
                              onChange={this.onChangePage}
                              total={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .maxCount
                              }
                          />
                      </MDBPagination>
                      <MDBPagination>
                          <PaginationAmountEntityShow
                              page={
                                  store.getState()
                                      .roadmapTemplatePageReducer.page
                              }
                              pageSize={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .entryQuantity
                              }
                              count={
                                  store.getState()
                                      .roadmapTemplatePageReducer
                                      .maxCount
                              }
                          />
                      </MDBPagination>
                  </CardBody>

                  <CardFooter>
                    <Link to="/roadmap-template/create" className="btn btn-primary waves-effect waves-light">
                      Добавить
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => state.roadmapTemplatePageReducer
export default connect(mapStateToProps, {
    setPage,
    setQuantity,
    setSort,
    setSearch,
    setMax,
    isNeedRefresh,
})(RoadmapTemplate)