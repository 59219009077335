import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication Module
import Account from './auth/register/reducer'
import Login from './auth/login/reducer'
import Forget from './auth/forgetpwd/reducer'

// pages
import { newsPageReducer } from './news-page/reducer'
import { newsEnPageReducer } from './news-en-page/reducer'
import { tradePartnersPageReducer } from './trade-partners-page/reducer'
import { objectsPageReducer } from './objects-page/reducer'
import { projectPageReducer } from './project-page/reducer'
import { associationsPageReducer } from './associations-page/reducer'
import { investmentSitesPageReducer } from './investment-sites-page/reducer'
import { analyticsReducer } from './analytics-page/reducer'
import { eventsPageReducer } from './events-page/reducer'
import { expertsPageReducer } from './experts-page/reducer'
import { graphsPageReducer } from './graphs-page/reducer'
import { schedulerPageReducer } from './scheduler-page/reducer'
import { handbookReducer } from './handbook-pages/reducer'
import { userPageReducer } from './user-page/reducer'
import { contractPageReducer } from './contract-page/reducer'
import { constantPageReducer } from './constant-page/reducer'
import { subscriptionPageReducer } from './subscription-page/reducer'
import { infoblockPageReducer } from './infoblock-page/reducer'
import { calendarEventsPageReducer } from './calendar-events-page/reducer' 
import { moduleFiltersPageReducer } from './module-filters-page/reducer'
import { roadmapTemplatePageReducer } from './roadmap-template-page/reducer'

const rootReducer = combineReducers({
    // public
    Layout,

    // Authentication
    Account,
    Login,
    Forget,

    // Pages
    newsPageReducer,
    newsEnPageReducer,
    tradePartnersPageReducer,
    objectsPageReducer,
    projectPageReducer,
    associationsPageReducer,
    investmentSitesPageReducer,
    analyticsReducer,
    eventsPageReducer,
    expertsPageReducer,
    graphsPageReducer,
    schedulerPageReducer,
    userPageReducer,
    contractPageReducer,
    constantPageReducer,
    subscriptionPageReducer,
    infoblockPageReducer,
    calendarEventsPageReducer,
    moduleFiltersPageReducer,
    roadmapTemplatePageReducer,

    // handbooks
    handbookReducer,
})

export default rootReducer
