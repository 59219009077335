import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import PurchaserForm from './PurchaserForm'

class PurchaserEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список закупщиков', link: '/purchaser' },
                { title: 'Редактировать закупщика', link: '/purchaser/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        const data = {
            columns: [],
            rows: [],
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование закупщика"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <PurchaserForm
                            title={'Редактирование закупщика: ' + this.state.id}
                            id={this.state.id}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default PurchaserEdit
