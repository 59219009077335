import React from 'react'
import PropTypes from 'prop-types'

const PaginationPageSizeSelect = ({
    title,
    value,
    values,
    onChangePerPage,
}) => {
    const options = values.map((val) => {
        return (
            <option value={val} key={val}>
                {val}
            </option>
        )
    })

    return (
        <React.Fragment>
            <div>{title}</div>
            <select
                className="browser-default custom-select"
                style={{ width: '100px' }}
                value={value}
                onChange={(e) => onChangePerPage(e.target.value)}
            >
                {options}
            </select>
        </React.Fragment>
    )
}

PaginationPageSizeSelect.defaultProps = {
    title: 'Show entries',
    value: 20,
    values: [20, 40, 60],
    onChangePerPage: () => {},
}

PaginationPageSizeSelect.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.number),
    onChangePerPage: PropTypes.func,
}

export { PaginationPageSizeSelect }
