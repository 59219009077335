import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import UserForm from './UserForm'
import PropTypes from 'prop-types'

// const user = localStorage.getItem('authUser')
// const jsonUser = JSON.parse(user)

class UserListUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список пользователей', link: '/user_list' },
                {
                    title: 'Редактировать пользователя',
                    link: '/user_list/update',
                },
            ],

            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        const data = {
            columns: [],
            rows: [],
            user: this.state.user || {},
            userName: this.state.user || { name: '' },
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование пользователя"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <UserForm
                            title={
                                'Редактирование пользователя: ' +
                                data.userName.name
                            }
                            id={this.state.id}
                            data={data.user}
                            typeForm="edit"
                            history={this.props.history}
                            method="post"
                            api="/user/update"
                            redirect="/user_list"
                            one="/user/one"
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

UserListUpdate.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
}

export default UserListUpdate
