import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookForm from '../HandbookForm'

class CurrencyCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Валюты', link: '/handbooks/currency' },
                {
                    title: 'Добавить валюту',
                    link: '/handbooks/currency/create',
                },
            ],
            fields: [
                {
                    label: 'Код',
                    name: 'code',
                    placeholder: 'Введите код',
                    validate: {
                        required: {
                            value: true,
                            errorMessage: 'Обязательно для заполнения',
                        },
                        pattern: {
                            value: '^[A-Z]+$',
                            errorMessage:
                                'Неверный код (Только заглавные латинские символы)',
                        },
                    },
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Валюта"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookForm
                            title="Создание валюты"
                            method="post"
                            api="/handbook/currency/create"
                            redirect="/handbooks/currency"
                            fields={this.state.fields}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default CurrencyCreate
