import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../../components/Common/AvSelect'
import AvMultipleInput from '../../../components/Common/AvMultipleInput'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import user from '../../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

class WorkingDaysForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: true,
            name: '',
            country: {},
            workingDays: [],
            nonWorkingDays: [],
            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios
                .get(`/business_calendar/working_days/one?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name,
                        country: data.country,
                        workingDays:
                            data.working_days || this.state.workingDays,
                        nonWorkingDays:
                            data.non_working_days || this.state.nonWorkingDays,
                    })

                    this.handleSelectChange('country', [data.country])
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value) {
        this.setState({
            [key]: value[0],
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/business_calendar/working_days/update'
                : '/business_calendar/working_days/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push(
                            '/business_calendar/working_days'
                        )
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(`<b>Должна быть уникальной!</b>`)
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const data = {
            createdBy: user.userId,
            active: this.state.isActive,
            name: this.state.name,
            country: this.state.country.id,
            workingDays: this.state.workingDays,
            nonWorkingDays: this.state.nonWorkingDays,
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = user.userId
        }

        return data
    }

    updateWorkingDays = (value) => {
        this.setState({ workingDays: value })
    }

    updateNonWorkingDays = (value) => {
        this.setState({ nonWorkingDays: value })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="working_days"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            id="isActive"
                                            checked={this.state.isActive}
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="isActive"
                                        >
                                            Активен
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование"
                                name="name"
                                placeholder="Введите заголовок"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvSelect
                                label="Страна"
                                name="country"
                                api="/handbook/country/input_list"
                                value={this.state.country}
                                handleSelectChange={this.handleSelectChange}
                                typeValueMulti="one"
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvMultipleInput
                                updateData={this.updateWorkingDays}
                                value={this.state.workingDays}
                                options={{
                                    label: 'Рабочие дни (“ДД.ММ.ГГ”)',
                                    name: 'workingDays',
                                    pattern: {
                                        value:
                                            '^(0[1-9]|1[0-9]|2[0-9]|3[01])[.](0[1-9]|1[012])[.](2[0-9]|3[0-9])$',
                                        errorMessage:
                                            'Неверный формат даты “ДД.ММ.ГГ”',
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvMultipleInput
                                updateData={this.updateNonWorkingDays}
                                value={this.state.nonWorkingDays}
                                options={{
                                    label: 'Не рабочие дни (“ДД.ММ.ГГ”)',
                                    name: 'nonWorkingDays',
                                    pattern: {
                                        value:
                                            '^(0[1-9]|1[0-9]|2[0-9]|3[01])[.](0[1-9]|1[012])[.](2[0-9]|3[0-9])$',
                                        errorMessage:
                                            'Неверный формат даты “ДД.ММ.ГГ”',
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default WorkingDaysForm
