import React from 'react'
import { LOAD_HANDBOOK, REQUEST, SUCCESS, FAILURE } from './actions'
import { getDeleteLink, getList, getUpdateLink } from '../../api/handbook'
import DropdownControls from '../../components/Common/DropdownControls'
import { errorResponseHandler } from '../../api/utils'

export const loadHandbook = ({
    handbookType,
    page,
    pageSize,
    sortBy,
    sortOrder,
    search,
    customColumns,
}) => async (dispatch, getState) => {
    const loading = false
    const loaded = false

    if (loading || loaded) return

    const payload = { handbookType, page, pageSize, search, sortBy, sortOrder }

    dispatch({ type: LOAD_HANDBOOK + REQUEST, payload })

    try {
        const response = await getList(
            handbookType,
            page,
            pageSize,
            search,
            sortBy,
            sortOrder
        ).then((res) => res)
        let maxCount
        const addColumns = []
        let rows = []

        if (response.status >= 200) {
            const data = response.data.data
            maxCount = response.data.maxCount

            for (let i = 0; i < data.length; i++) {
                const updateLink = getUpdateLink(handbookType, data[i].id)
                const deleteLink = getDeleteLink(handbookType)

                data[i].controls = (
                    <DropdownControls
                        id={data[i].id}
                        actions={{
                            update: updateLink,
                            delete: deleteLink,
                        }}
                    />
                )
                data[i].active = data[i].active ? 'Да' : 'Нет'

                if (customColumns && customColumns.length) {
                    customColumns.forEach(({ field, render }) => {
                        if (typeof data[i][field] !== 'undefined') {
                            if (typeof render === 'function') {
                                data[i][field] = render(data[i][field])
                            }
                        }
                    })
                }
            }
            if (data.length > 0) {
                if (data[0].country) {
                    addColumns.push({
                        label: 'Страна',
                        field: 'country',
                        sort: '',
                        width: 150,
                    })
                }

                if (data[0].industry) {
                    addColumns.push({
                        label: 'Отрасль',
                        field: 'industry',
                        sort: '',
                        width: 150,
                    })
                }
            }
            rows = [...data]
        }
        dispatch({
            type: LOAD_HANDBOOK + SUCCESS,
            payload: { handbookType, rows, addColumns, maxCount },
        })
    } catch (error) {
        let message
        try {
            errorResponseHandler(error)
        } catch (err) {
            message = err
        }

        dispatch({
            type: LOAD_HANDBOOK + FAILURE,
            payload: {
                error: message,
                handbookType,
            },
        })
    }
}
