import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import LangForm from './LangForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class LangEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список языков', link: '/languages' },
                { title: 'Редактировать язык', link: '/languages/update' },
            ],

            lang: '',
            id: this.props.match.params.id,
        }
    }

    componentDidMount() {
        axios
            .get('/localization/lang/one', {
                params: {
                    id: this.state.id,
                },
            })
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data

                    this.setState({ lang: data })
                }
            })
    }

    render() {
        const data = {
            columns: [],
            rows: [],
            lang: this.state.lang || {},
            langNmae: this.state.lang || { name: '' },
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование языка"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <LangForm
                            title={
                                'Редактирование языка: ' + data.langNmae.name
                            }
                            id={this.state.id}
                            data={data.lang}
                            typeForm="edit"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default LangEdit
