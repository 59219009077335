// import { createSelector } from 'reselect'
import { initialValues } from './reducer'

export const handbookSelector = ({ handbookReducer }, props) => {
    let mappedState
    if (handbookReducer[props.type]) {
        mappedState = {
            ...handbookReducer[props.type],
        }
    } else {
        mappedState = { ...initialValues }
    }
    return mappedState
}
