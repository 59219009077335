import PropTypes from 'prop-types'

const PaginationAmountEntityShow = ({ page, count, pageSize }) => {
    const from = 1 + (page - 1) * pageSize
    const to = from + pageSize - 1 > count ? count : from + pageSize - 1
    return `Показаны ${from} - ${to} записи из ${count}`
}

PaginationAmountEntityShow.propTypes = {
    page: PropTypes.number,
    count: PropTypes.number,
    quantity: PropTypes.number,
}

export { PaginationAmountEntityShow }
