import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, CardBody, CardFooter, CardHeader, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { MDBDataTablePaginationWrapper } from '../../components/Common/MDBDataTablePaginationWrapper'

import { getCreateLink } from '../../api/handbook'
import { PaginationPageSizeSelect } from '../../components/Common/PaginationPageSizeSelect'
import { SearchWidget } from '../../components/Common/SearchWidget'
import { loadHandbook } from '../../store/handbook-pages/actionsCreators'
import { handbookSelector } from '../../store/handbook-pages/selectors'

class HandbookList extends PureComponent {
    constructor(props) {
        super(props)
        this.setCurrentPage = this.setCurrentPage.bind(this)
        this.setSort = this.setSort.bind(this)
        this.setPageSize = this.setPageSize.bind(this)
        this.setSearch = this.setSearch.bind(this)
    }

    setPageSize(pageSize) {
        const payload = this.buildRequestPayload()
        this.props.loadHandbook({
            ...payload,
            page: 1,
            pageSize: Number(pageSize),
        })
    }

    setCurrentPage(page) {
        const payload = this.buildRequestPayload()
        this.props.loadHandbook({
            ...payload,
            page,
        })
    }

    setSort({ column, direction }) {
        if (!column) {
            return
        }

        if (
            column === this.props.sortBy &&
            direction === this.props.sortOrder
        ) {
            return
        }

        const payload = this.buildRequestPayload()
        this.props.loadHandbook({
            ...payload,
            sortBy: column,
            sortOrder: direction,
        })
    }

    setSearch(value) {
        const payload = this.buildRequestPayload()
        this.props.loadHandbook({ ...payload, search: value })
    }

    buildRequestPayload() {
        return {
            handbookType: this.props.type,
            page: this.props.page,
            pageSize: this.props.pageSize,
            sortBy: this.props.sortBy,
            sortOrder: this.props.sortOrder,
            search: this.props.search,
            customColumns: this.props.customColumns,
        }
    }

    componentDidMount() {
        this.props.loadHandbook(this.buildRequestPayload())
    }

    render() {
        const defaultColumns = [
            {
                label: 'Дата создания',
                field: 'date_create',
                sort: '',
                width: 150,
            },
            {
                label: 'Дата изменения',
                field: 'date_update',
                sort: '',
                width: 150,
            },
            {
                label: 'Наименование',
                field: 'name',
                sort: '',
                width: 150,
            },
            {
                label: 'Автор',
                field: 'user_name',
                sort: '',
                width: 150,
            },
            {
                label: 'Последнее изменение',
                field: 'user_update',
                sort: '',
                width: 150,
            },
            {
                label: 'Активность',
                field: 'active',
                sort: '',
                width: 150,
            },
        ]

        let columns = [...defaultColumns]
        const { addColumns, customColumns } = this.props
        if (addColumns.length) {
            columns = [...columns, ...addColumns]
        }

        if (customColumns.length) {
            columns = [...columns, ...customColumns]
        }

        // todo define why not working without
        columns.forEach((column) => {
            column.sort =
                this.props.sortBy === column.field
                    ? this.props.sortOrder === 'asc'
                        ? 'asc'
                        : 'desc'
                    : ''
        })
        const data = {
            columns:
                this.props.columns ||
                columns.concat([
                    {
                        label: '',
                        field: 'controls',
                        sort: 'disabled',
                        width: 150,
                    },
                ]),
            rows: this.props.rows,
        }

        const order = [this.props.sortBy, this.props.sortOrder]
        return (
            <React.Fragment>
                <Card>
                    <CardHeader>
                        <Link
                            to={getCreateLink(this.props.type)}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            {this.props.create}
                        </Link>

                        {this.props.error && (
                            <div className={'text-danger'}>
                                {this.props.error}
                            </div>
                        )}
                    </CardHeader>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                padding: '20px',
                                boxSizing: 'border-box',
                            }}
                        >
                            <PaginationPageSizeSelect
                                onChangePerPage={this.setPageSize}
                                value={this.props.pageSize}
                            />
                        </div>
                        <div
                            style={{
                                margin: '20px',
                                boxSizing: 'border-box',
                            }}
                        >
                            <SearchWidget
                                value={this.props.search}
                                onChangeSearch={this.setSearch}
                                placeholder={'search'}
                            />
                        </div>
                    </div>
                    <CardBody>
                        {this.props.isLoading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10%',
                                }}
                            >
                                <Spinner color="primary" />
                            </div>
                        ) : (
                            <MDBDataTablePaginationWrapper
                                responsive
                                striped
                                bordered
                                paging={false}
                                btn={true}
                                entriesOptions={[20, 40, 60]}
                                data={data}
                                order={order}
                                onSort={this.setSort}
                                searching={false}
                                showPagingInfo={true}
                                showPagination={true}
                                maxCount={this.props.maxCount}
                                currentPage={this.props.page}
                                setCurrentPage={this.setCurrentPage}
                                pageSize={this.props.pageSize}
                                itemsQuantity={this.props.rows.length}
                            />
                        )}
                    </CardBody>

                    <CardFooter>
                        <Link
                            to={getCreateLink(this.props.type)}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            {this.props.create}
                        </Link>
                    </CardFooter>
                </Card>
            </React.Fragment>
        )
    }
}

HandbookList.propTypes = {
    type: PropTypes.string,
    create: PropTypes.string,
    columns: PropTypes.arrayOf(Object),
    addColumns: PropTypes.array,
    customColumns: PropTypes.array,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    search: PropTypes.string,
    loadHandbook: PropTypes.func,
    rows: PropTypes.array,
    maxCount: PropTypes.number,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
}

HandbookList.defaultProps = {
    customColumns: [],
}

const mapDispatchToProps = {
    loadHandbook,
}
export default connect(handbookSelector, mapDispatchToProps)(HandbookList)
