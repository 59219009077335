import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import LangConstantForm from './LangConstantForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class LangConstantEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Список констант',
                    link: '/lang/' + this.props.match.params.module,
                },
                {
                    title: 'Редактировать константу',
                    link: '/lang/' + this.props.match.params.module + '/update',
                },
            ],

            module: this.props.match.params.module,
            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование константы"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <LangConstantForm
                            title={'Редактирование'}
                            module={this.state.module}
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default LangConstantEdit
