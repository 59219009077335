import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookLangAndFileForm from '../HandbookLangAndFileForm'

class ProjectStatusUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Статус проекта', link: '/handbooks/project_status' },
                {
                    title: 'Редактировать статус',
                    link: '/handbooks/project_status/update',
                },
            ],
            fields: [
                {
                    label: 'Цвет',
                    name: 'color',
                    type: 'color',
                    placeholder: 'Введите цвет',
                    validate: {
                        required: {
                            value: true,
                            errorMessage: 'Обязательно для заполнения',
                        },
                    },
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование статуса"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookLangAndFileForm
                            title="Редактирование статуса"
                            method="post"
                            api="/handbook/project_status/update"
                            redirect="/handbooks/project_status"
                            one="/handbook/project_status/one"
                            id={this.props.match.params.id}
                            fields={this.state.fields}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ProjectStatusUpdate
