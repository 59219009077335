import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import ProductsForm from './ProductsForm'

class ProductsEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список продукции', link: '/products' },
                { title: 'Редактировать продукцию', link: '/products/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование продукции"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <ProductsForm
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ProductsEdit
