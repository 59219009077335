import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import LocalizationElementField from '../../components/Common/LocalizationElementField'
import { Link } from 'react-router-dom'

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class LangConstantForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: true,
            name: '',
            code: '',
            module: this.props.module,

            isUpdate: this.props.id,
            valueLang: '',

            activeTab: 1,
            activeTabProgress: 1,
            langElement: null,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleTab.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab,
                })
            }
        }
    }

    updateData = (value) => {
        this.setState({ langElement: value })
    }

    errorTab(errors) {
        let pos = 0

        errors.forEach((element) => {
            pos = element.indexOf('lang')

            if (pos == -1) {
                this.toggleTab(1)
                return
            } else {
                this.toggleTab(2)
                return
            }
        })
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios
                .get(`/localization/constant/one?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        code: data.code,
                        name: data.name,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })
        this.errorTab(errors)

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/localization/constant/update'
                : '/localization/constant/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        toastr.success(
                            `Константа <b>${this.state.values.name}</b> успешно создан`
                        )
                        this.props.history.push('/lang/' + this.state.module)
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const data = {
            createdBy: jsonUser.userId,
            name: this.state.name,
            code: this.state.code,
            active: this.state.isActive,
            module: this.state.module,
        }

        if (this.state.langElement) {
            data['lang'] = this.state.langElement
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            if (this.state.uploadedPicture) {
                data.picture = this.state.uploadedPicture.id
            }
        }

        return data
    }

    handleAcceptedPicture = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                picture: file,
                uploadedPicture: null,
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="lang">
                    <Card>
                        <div
                            id="basic-pills-wizard"
                            className="twitter-bs-wizard"
                        >
                            <CardHeader>
                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li
                                        className={
                                            this.state.activeTab === 1
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(1)
                                            }}
                                        >
                                            Поля
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.state.activeTab === 2
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(2)
                                            }}
                                        >
                                            Локализация
                                        </Link>
                                    </li>
                                </ul>
                            </CardHeader>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={1}>
                                    <CardHeader>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h4 className="card-title mb-0">
                                                    {this.props.title}
                                                </h4>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex">
                                                    <div className="custom-control custom-switch ml-4">
                                                        <Input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name="isActive"
                                                            id="isActive"
                                                            checked={
                                                                this.state
                                                                    .isActive
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                        <Label
                                                            className="custom-control-label"
                                                            htmlFor="isActive"
                                                        >
                                                            Активность
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <AvField
                                                    label="Наименование константы"
                                                    name="name"
                                                    placeholder="Введите наименование"
                                                    value={this.state.name}
                                                    onInput={
                                                        this.handleInputChange
                                                    }
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage:
                                                                'Обязательно для заполнения',
                                                        },
                                                    }}
                                                />

                                                <AvField
                                                    label="Код"
                                                    name="code"
                                                    placeholder="Введите код"
                                                    value={this.state.code}
                                                    onInput={
                                                        this.handleInputChange
                                                    }
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage:
                                                                'Обязательно для заполнения',
                                                        },
                                                        pattern: {
                                                            value:
                                                                '^[a-z0-9-_]+$',
                                                            errorMessage:
                                                                'Неверный код',
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </TabPane>

                                <TabPane tabId={2}>
                                    <CardBody>
                                        <LocalizationElementField
                                            elementId={this.props.id}
                                            updateData={this.updateData}
                                            module="constant"
                                            field="name"
                                            langElement={this.state.langElement}
                                        />
                                    </CardBody>
                                </TabPane>
                            </TabContent>

                            <CardFooter>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default LangConstantForm
