import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookFormRelatedAndFile from '../HandbookFormRelatedAndFile'

class SubIndustryCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Список подотрослей',
                    link: '/handbooks/sub_industry',
                },
                {
                    title: 'Добавить подотрасль',
                    link: '/handbooks/sub_industry/create',
                },
            ],
            select: {
                options: [],
                label: 'Выберите отрасль',
                placeholder: 'Выберите опцию',
                errorMessage: 'Обязательно для заполнения',
                name: 'industry',
                api: '/handbook/industry/input_list',
            },
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новая подотрасль"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookFormRelatedAndFile
                            title="Создание подотрасли"
                            method="post"
                            api="/handbook/sub_industry/create"
                            redirect="/handbooks/sub_industry"
                            history={this.props.history}
                            select={this.state.select}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default SubIndustryCreate
