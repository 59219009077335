import React from 'react'
import { Redirect } from 'react-router-dom'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'
import AuthLockScreen from '../pages/Authentication/AuthLockScreen'

// Dashboard
import Dashboard from '../pages/Dashboard/index'

// Tables
import DatatableTables from '../pages/Tables/DatatableTables'

// Routes
import { purchaserRoutes } from './purchaser'
import { handbookRoutes } from './handbook'
import { tagRoutes } from './tag'
import { newsRoutes } from './news'
import { newsRoutesEn } from './newsEn'
import { langRoutes } from './lang'
import { langConstantRoutes } from './langConstant'
import { eventsRoutes } from './events'
import { userRoutes } from './user'
import { associationsRoutes } from './associations'
import { expertsRoutes } from './experts'
import { projectsRoutes } from './projects'
import { objectsRoutes } from './objects'
import { investmentSitesRoutes } from './investmentsites'
import { organizationsRoutes } from './organizations'
import { productsRoutes } from './products'
import { businessCalendarRoutes } from './businessCalendar'
import { moduleFiltersRoutes } from './moduleFilters'
import { infoblockRoutes } from './infoblock'
import { roadmapTemplateRoutes } from './roadmapTemplate'
import { graphsRoutes } from './graphs'
import { analyticsRoutes } from './analytics'
import { tradePartnersRoutes } from './tradepartners'
import { schedulerRoutes } from './scheduler'
import { settingsRoutes } from './settings'

let allRoutes = []

allRoutes = purchaserRoutes.concat(allRoutes)
allRoutes = handbookRoutes.concat(allRoutes)
allRoutes = tagRoutes.concat(allRoutes)
allRoutes = newsRoutes.concat(allRoutes)
allRoutes = newsRoutesEn.concat(allRoutes)
allRoutes = langRoutes.concat(allRoutes)
allRoutes = langConstantRoutes.concat(allRoutes)
allRoutes = eventsRoutes.concat(allRoutes)
allRoutes = userRoutes.concat(allRoutes)
allRoutes = associationsRoutes.concat(allRoutes)
allRoutes = expertsRoutes.concat(allRoutes)
allRoutes = projectsRoutes.concat(allRoutes)
allRoutes = objectsRoutes.concat(allRoutes)
allRoutes = investmentSitesRoutes.concat(allRoutes)
allRoutes = organizationsRoutes.concat(allRoutes)
allRoutes = productsRoutes.concat(allRoutes)
allRoutes = businessCalendarRoutes.concat(allRoutes)
allRoutes = moduleFiltersRoutes.concat(allRoutes)
allRoutes = infoblockRoutes.concat(allRoutes)
allRoutes = roadmapTemplateRoutes.concat(allRoutes)
allRoutes = graphsRoutes.concat(allRoutes)
allRoutes = analyticsRoutes.concat(allRoutes)
allRoutes = tradePartnersRoutes.concat(allRoutes)
allRoutes = schedulerRoutes.concat(allRoutes)
allRoutes = settingsRoutes.concat(allRoutes)

const base = [
    { path: '/dashboard/users', component: DatatableTables },
    { path: '/dashboard', component: Dashboard },
    // this route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authProtectedRoutes = allRoutes.concat(base)

const publicRoutes = [
    { path: '/logout', component: Logout },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgetPwd },
    { path: '/register', component: Register },
    { path: '/auth-lock-screen', component: AuthLockScreen },
]

export { authProtectedRoutes, publicRoutes }
