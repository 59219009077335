import Settings from '../pages/Settings/index'
import SettingsAdd from '../pages/Settings/add'
import SettingsEdit from '../pages/Settings/edit'

const settingsRoutes = [
    { path: '/Settings/update/:id', component: SettingsEdit },
    { path: '/Settings/create', component: SettingsAdd },
    { path: '/Settings', component: Settings },
]

export { settingsRoutes }
