/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalComponent = (props) => {
    const {
        className,
        modalTitle,
        children,
        handleClose,
        isOpen = false,
        showSuccessButton = false,
        successButtonText = null,
        successButtonClick = () => {},
    } = props

    return (
        <>
            <Modal isOpen={isOpen} toggle={handleClose} className={className}>
                <ModalHeader toggle={handleClose}>{modalTitle}</ModalHeader>
                <ModalBody>{children}</ModalBody>
                <ModalFooter>
                    {showSuccessButton && (
                        <Button color="primary" onClick={successButtonClick}>
                            {successButtonText}
                        </Button>
                    )}{' '}
                    <Button color="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalComponent
