import RoadmapTemplate from '../pages/RoadmapTemplate/index'
import RoadmapTemplateAdd from '../pages/RoadmapTemplate/create'
import RoadmapTemplateEdit from '../pages/RoadmapTemplate/update'

const roadmapTemplateRoutes = [
    { path: '/roadmap-template/update/:id', component: RoadmapTemplateEdit },
    { path: '/roadmap-template/create', component: RoadmapTemplateAdd },
    { path: '/roadmap-template', component: RoadmapTemplate },
]

export { roadmapTemplateRoutes }
