import React, { Component } from 'react'
import { object } from 'prop-types'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookReadinessForm from './form'

class ReadinessUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Готовность', link: '/handbooks/readiness' },
                {
                    title: 'Редактировать готовность',
                    link: '/handbooks/readiness/update',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование готовности"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookReadinessForm
                            title="Редактирование цвета"
                            method="post"
                            api="/handbook/readiness/update"
                            redirect="/handbooks/readiness"
                            one="/handbook/readiness/one"
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

ReadinessUpdate.propTypes = {
    history: object,
    match: object,
}

export default ReadinessUpdate
