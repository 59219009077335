export function errorResponseHandler(err) {
    let message
    if (err.response && err.response.status) {
        switch (err.response.status) {
            case 401:
                message = 'Пользователь с введенными данными не найден'
                break
            case 403:
                message = 'Не хватает прав. Обратитесь к администратору'
                break
            default:
                message =
                    err[1] ||
                    'Произошла ошибка. Попробуйте перезагрузить страницу!'
                break
        }
        // this.props.history.push('/logout');
    }
    throw message
}
