import {
    SET_ENTRIES_QUANTITY,
    SET_PAGE,
    SET_SEARCH,
    SET_MAX,
    SET_SORT,
    IS_NEED_REFRESH,
} from './actions'

const initialState = {
    page: 1,
    entryQuantity: 20,
    search: '',
    maxCount: 1,
    currentSortBy: 'priority',
    currentSortOrder: 'desc',
    command: 'desc',
    cron_expression: 'desc',
    last_execution: 'desc',
    arguments: 'desc',
    last_return_code: 'desc',
    log_file: 'desc',
    execute_immediately: 'desc',
    disabled: 'desc',
    locked: 'desc',
    name: 'desc',
    priority: 'desc',
    isNeedRefresh: true,
}

export const schedulerPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
            }
        case SET_ENTRIES_QUANTITY:
            return {
                ...state,
                entryQuantity: action.payload,
            }
        case SET_MAX:
            return {
                ...state,
                maxCount: action.payload,
            }
        case SET_SORT:
            return {
                ...state,
                ...action.payload,
            }
        case IS_NEED_REFRESH:
            return {
                ...state,
                isNeedRefresh: action.payload,
            }
        default:
            return state
    }
}
