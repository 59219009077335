import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import HandbookList from '../HandbookList'

class SubIndustry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Подотрасль', link: '/handbook/sub_industry' },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список подотраслей"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookList
                            type="sub_industry"
                            create="Добавить подотрасль"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default SubIndustry
