import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './store'
import user from './store/user'
import axios from 'axios'
import toastr from 'toastr'

axios.defaults.baseURL = '/v1/manage/crud/'
axios.defaults.headers.common.Authorization = 'Bearer ' + user.credentials

axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (err) {
        if (err.response && err.response.status) {
            if (err.response.status === 401) {
                toastr.error(`<b>Токен устарел. Авторизуйтесь заново.</b>`)
                window.location.href = '/logout'
            }

            if (err.response.status === 403) {
                toastr.error(err.response.data.message)
            }
        }
        // console.log(err.response);
        return Promise.reject(err)
    }
)

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
