import Purchaser from '../pages/Purchaser/index'
import PurchaserAdd from '../pages/Purchaser/add'
import PurchaserEdit from '../pages/Purchaser/edit'

const purchaserRoutes = [
    { path: '/purchaser/update/:id', component: PurchaserEdit },
    { path: '/purchaser/create', component: PurchaserAdd },
    { path: '/purchaser', component: Purchaser },
]

export { purchaserRoutes }
