import DatatableTables from '../pages/Tables/DatatableTables'
import {
    RoleGroup,
    RoleGroupCreate,
    RoleGroupUpdate,
    Role,
    RoleCreate,
    RoleUpdate,
    Package,
    PackageCreate,
    PackageUpdate,
    UserList,
    UserListCreate,
    UserListUpdate,
    Subscription,
    SubscriptionCreate,
    SubscriptionUpdate,
    Contract,
    ContractCreate,
    ContractUpdate,
} from '../pages/User'

const userRoutes = [
    { path: '/contract/update/:id', component: ContractUpdate },
    { path: '/contract/create', component: ContractCreate },
    { path: '/contract/', component: Contract },

    { path: '/subscription/update/:id', component: SubscriptionUpdate },
    { path: '/subscription/create', component: SubscriptionCreate },
    { path: '/subscription/', component: Subscription },

    { path: '/user_role_group/update/:id', component: RoleGroupUpdate },
    { path: '/user_role_group/create', component: RoleGroupCreate },
    { path: '/user_role_group/', component: RoleGroup },

    { path: '/user_role/update/:id', component: RoleUpdate },
    { path: '/user_role/create', component: RoleCreate },
    { path: '/user_role/', component: Role },

    { path: '/user_package/update/:id', component: PackageUpdate },
    { path: '/user_package/create', component: PackageCreate },
    { path: '/user_package/', component: Package },

    { path: '/user_list/update/:id', component: UserListUpdate },
    { path: '/user_list/create', component: UserListCreate },
    { path: '/user_list/', component: UserList },

    { path: '/user', component: DatatableTables },
]

export { userRoutes }
