import React, { Component, createRef } from 'react'
import {isEmpty} from "../../../utils/utils"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../../components/Common/AvSelect'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import toastr from 'toastr'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

const formatData = (dates) => {
    let date = new Date(dates)
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        'T' +
        hours +
        ':' +
        minutes
    )
}

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class EventsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'События', link: '/business_calendar/events' },
            ],

            isActive: true,
            nameRu: '',
            nameEn: '',
            date: '',
            country: [],
            region: [],
            typeEvent: [],
            holidaysAndEvents: [],
            link: null,
            previewPicture: null,
            uploadedPreview: null,
            uploadedPictures: null,
            regionOptions: [],

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSelectChangePictogram = this.handleSelectChangePictogram.bind(
            this
        )
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios
                .get(`/business_calendar/events/one?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        nameRu: data.name_ru,
                        nameEn: data.name_en,
                        date: data.date
                            .replace(/:\d{2}$/, '')
                            .replace(' ', 'T'),
                        country: data.country,
                        region: data.region,
                        holidaysAndEvents: data.holidays_and_events,
                        link: data.link,
                        uploadedPreview: data.preview_picture,
                    })

                    this.handleSelectChange('country', [data.country], {
                        key: 'region',
                        options: 'regionOptions',
                    })

                    if (data.region && data.region.id) {
                        this.handleSelectChange('region', [data.region])
                    }

                    if (data.holidaysAndEvents && data.holidaysAndEvents.id) {
                        this.handleSelectChange('holidaysAndEvents', [data.holidays_and_events])
                    }
                })
        } else {
            this.setState({ date: this.getDateToday() })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSelectChangePictogram(key, value, child) {
        const ids = getIdFromSelect(value)
        let file = null
        let _this = this

        if (ids.length) {
            const requests = ids.map((id) => {
                return axios.get(`/handbook/pictogram/one?id=${id}`)
            })

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            console.log()
                            if (request.data.data.file.path) {
                                file = request.data.data.file
                            }
                        })
                    })
                )
                .finally(() => {
                    if (file !== null) {
                        var xhr = new XMLHttpRequest()
                        xhr.open('GET', file.path, true)
                        xhr.responseType = 'blob'
                        xhr.onload = function (e) {
                            if (this.status == 200) {
                                let filenew = new File(
                                    [this.response],
                                    file.name + '.' + file.type,
                                    { type: this.response.type }
                                )

                                Object.assign(filenew, {
                                    preview: URL.createObjectURL(filenew),
                                })

                                _this.setState({
                                    previewPicture: filenew,
                                    uploadedPreview: null,
                                })
                            }
                        }
                        xhr.send()
                    }
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/business_calendar/events/update'
                : '/business_calendar/events/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/business_calendar/events')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(`<b>Страна должна быть уникальной!</b>`)
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    handleAcceptedPreview = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                previewPicture: file,
                uploadedPreview: null,
            })
        })
    }

    getDateToday() {
        let today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        const yyyy = today.getFullYear()
        const hours =
            today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
        const minutes =
            today.getMinutes() < 10
                ? '0' + today.getMinutes()
                : today.getMinutes()
        const seconds =
            today.getSeconds() < 10
                ? '0' + today.getSeconds()
                : today.getSeconds()

        return yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            createdBy: jsonUser.userId,
            active: this.state.isActive,
            name_ru: this.state.nameRu,
            //name_en: this.state.nameEn,
            date: this.state.date.replace('T', ' '),
            country: getIdFromSelect(this.state.country)[0],
            region: [],
            holidays_and_events: [],
            link: this.state.link || '',
            //previewPicture: this.state.previewPicture,
        }

        if (this.state.region) {
            this.state.region.forEach((e) => {
                data.region.push(e.id);
            });
        }

        if (this.state.holidaysAndEvents.length) {
            data.holidays_and_events = getIdFromSelect(this.state.holidaysAndEvents)[0]
        } else if (!isEmpty(this.state.holidaysAndEvents)) {
            data.holidays_and_events = this.state.holidaysAndEvents.id;
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            if (this.state.uploadedPreview) {
                data.previewPicture = this.state.uploadedPreview.id
            }
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'previewPicture'
                ) {
                    data[key].map((el) => fd.append(`${key}[]`, el))
                } else {
                    fd.append(key, data[key])
                }
            }
        }

        return fd
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="event">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование"
                                name="nameRu"
                                placeholder="Введите наименование"
                                value={this.state.nameRu}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            {/*<AvField*/}
                            {/*    label="Наименование Англ."*/}
                            {/*    name="nameEn"*/}
                            {/*    placeholder="Введите наименование Англ."*/}
                            {/*    value={this.state.nameEn}*/}
                            {/*    onInput={this.handleInputChange}*/}
                            {/*    validate={{*/}
                            {/*        required: {*/}
                            {/*            value: true,*/}
                            {/*            errorMessage:*/}
                            {/*                'Обязательно для заполнения',*/}
                            {/*        },*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <FormGroup>
                                <Label>Дата</Label>
                                <Input
                                    className="form-control"
                                    type="datetime-local"
                                    defaultValue={this.state.date}
                                    onInput={(event) =>
                                        this.setState({
                                            date: formatData(
                                                event.target.value
                                            ),
                                        })
                                    }
                                />
                            </FormGroup>
                        </CardBody>
                    </Card>

                    {/*<Card>*/}
                    {/*    <CardHeader>*/}
                    {/*        <h4 className="card-title mb-0">*/}
                    {/*            Картинка для анонса*/}
                    {/*        </h4>*/}
                    {/*    </CardHeader>*/}

                    {/*    <CardBody>*/}
                    {/*        <Dropzone*/}
                    {/*            maxFiles={1}*/}
                    {/*            multiple={false}*/}
                    {/*            onDrop={(acceptedPreview) =>*/}
                    {/*                this.handleAcceptedPreview(acceptedPreview)*/}
                    {/*            }*/}
                    {/*        >*/}
                    {/*            {({ getRootProps, getInputProps }) => (*/}
                    {/*                <div*/}
                    {/*                    className={*/}
                    {/*                        this.state.previewPicture ||*/}
                    {/*                        this.state.uploadedPreview*/}
                    {/*                            ? 'dropzone dropzone-single dz-max-files-reached'*/}
                    {/*                            : 'dropzone dropzone-single'*/}
                    {/*                    }*/}
                    {/*                    style={{ textAlign: 'center' }}*/}
                    {/*                >*/}
                    {/*                    <div*/}
                    {/*                        className="dz-message needsclick"*/}
                    {/*                        {...getRootProps()}*/}
                    {/*                    >*/}
                    {/*                        <input {...getInputProps()} />*/}
                    {/*                        <div className="mb-3">*/}
                    {/*                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>*/}
                    {/*                        </div>*/}
                    {/*                        <h4>*/}
                    {/*                            Перетащите картинку сюда или*/}
                    {/*                            кликните для загрузки*/}
                    {/*                        </h4>*/}
                    {/*                    </div>*/}

                    {/*                    {this.state.previewPicture && (*/}
                    {/*                        <div className="dz-preview dz-preview-single">*/}
                    {/*                            <div className="dz-preview-cover">*/}
                    {/*                                <img*/}
                    {/*                                    data-dz-thumbnail=""*/}
                    {/*                                    className="dz-preview-img"*/}
                    {/*                                    style={{*/}
                    {/*                                        width: 'auto',*/}
                    {/*                                        height: '100%',*/}
                    {/*                                        overflow: 'hidden',*/}
                    {/*                                    }}*/}
                    {/*                                    alt={*/}
                    {/*                                        this.state*/}
                    {/*                                            .previewPicture*/}
                    {/*                                            .name*/}
                    {/*                                    }*/}
                    {/*                                    src={*/}
                    {/*                                        this.state*/}
                    {/*                                            .previewPicture*/}
                    {/*                                            .preview*/}
                    {/*                                    }*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    )}*/}

                    {/*                    {this.state.uploadedPreview && (*/}
                    {/*                        <div className="dz-preview dz-preview-single">*/}
                    {/*                            <div className="dz-preview-cover">*/}
                    {/*                                <img*/}
                    {/*                                    data-dz-thumbnail=""*/}
                    {/*                                    className="dz-preview-img"*/}
                    {/*                                    style={{*/}
                    {/*                                        width: 'auto',*/}
                    {/*                                        height: '100%',*/}
                    {/*                                        overflow: 'hidden',*/}
                    {/*                                    }}*/}
                    {/*                                    src={*/}
                    {/*                                        this.state*/}
                    {/*                                            .uploadedPreview*/}
                    {/*                                            .path*/}
                    {/*                                    }*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    )}*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*        </Dropzone>*/}

                    {/*        <Col>*/}
                    {/*            <CardBody>*/}
                    {/*                <AvSelect*/}
                    {/*                    label="Выбрать пиктограмму"*/}
                    {/*                    name="pictogram"*/}
                    {/*                    value={this.state.pictogram}*/}
                    {/*                    api="/handbook/pictogram/input_list"*/}
                    {/*                    handleSelectChange={*/}
                    {/*                        this.handleSelectChangePictogram*/}
                    {/*                    }*/}
                    {/*                />*/}
                    {/*            </CardBody>*/}
                    {/*        </Col>*/}

                    {/*        <AvField*/}
                    {/*            type="hidden"*/}
                    {/*            name="previewPicture"*/}
                    {/*            value={*/}
                    {/*                this.state.previewPicture ||*/}
                    {/*                this.state.uploadedPreview*/}
                    {/*            }*/}
                    {/*            validate={{*/}
                    {/*                required: {*/}
                    {/*                    value: true,*/}
                    {/*                    errorMessage:*/}
                    {/*                        'Обязательно для заполнения',*/}
                    {/*                },*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </CardBody>*/}
                    {/*</Card>*/}

                    <Card>
                        <CardBody>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <AvSelect
                                            label="Страна"
                                            name="country"
                                            child={{
                                                key: 'region',
                                                options: 'regionOptions',
                                            }}
                                            value={this.state.country}
                                            api="/handbook/country/input_list"
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            errorMessage="Обязательно для заполнения"
                                        />
                                    </Col>

                                    <Col>
                                        <AvSelect
                                            label="Регион"
                                            name="region"
                                            value={this.state.region}
                                            options={this.state.regionOptions}
                                            handleSelectChange={
                                                this.handleSelectChange
                                            }
                                            isDisabled={
                                                !this.state.regionOptions.length
                                            }
                                            multiple
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <AvSelect
                                    label="Праздники и события"
                                    name='holidaysAndEvents'
                                    value={this.state.holidaysAndEvents}
                                    api="/handbook/holidays_and_events/input_list"
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                />
                            </FormGroup>

                            <FormGroup>
                                <AvField
                                    label="Ссылка"
                                    name="link"
                                    placeholder="Введите ссылку"
                                    value={this.state.link}
                                    onInput={this.handleInputChange}
                                />
                            </FormGroup>
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default EventsForm
