import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import EventsForm from './EventsForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class EventsAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'События', link: '/business_calendar/events' },
                {
                    title: 'Добавить событие',
                    link: '/business_calendar/events/create',
                },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый элемент"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <EventsForm
                            title="Создание события"
                            typeForm="add"
                            method="post"
                            api="/business_calendar/events/create"
                            redirect="/business_calendar/events"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default EventsAdd
