import Lang from '../pages/Lang/index'
import LangAdd from '../pages/Lang/add'
import LangEdit from '../pages/Lang/edit'

const langRoutes = [
    { path: '/languages/update/:id', component: LangEdit },
    { path: '/languages/create', component: LangAdd },
    { path: '/languages', component: Lang },
]

export { langRoutes }
