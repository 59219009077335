import React, { Component, createRef } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
    InputGroup,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../../components/Common/AvSelect'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { Editor } from 'react-draft-wysiwyg'
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateFromHTML } from 'draft-js-import-html'
import { renameToSelect } from '../../../helpers/helpers'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import toastr from 'toastr'
import user from '../../../store/user'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

const formatData = (dates) => {
    const date = new Date(dates)
    const month =
        date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return (
        date.getFullYear() +
        '-' +
        month +
        '-' +
        date.getDate() +
        'T' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
    )
}

class ContractForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: true,
            name: '',
            dateStart: '',
            dateEnd: '',
            price: 0,
            user: [],
            subscription: [],

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name || this.state.name,
                        dateStart:
                            data.date_start.replace(' ', 'T') ||
                            this.state.dateStart,
                        dateEnd:
                            data.date_end.replace(' ', 'T') ||
                            this.state.dateEnd,
                        price: data.price || this.state.price,
                        user: [data.user] || this.state.user,
                        subscription: [data.subscription] || this.state.user,
                    })
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!errors.length) {
            const data = {
                createdBy: user.userId,
                active: this.state.isActive,
                name: this.state.name,
                dateStart: this.state.dateStart.split('T')[0],
                dateEnd: this.state.dateEnd.split('T')[0],
                price: this.state.price,
                user: getIdFromSelect(this.state.user)[0],
                subscription: getIdFromSelect(this.state.subscription)[0],
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: data,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/contract')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    const errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="subscription"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название"
                                name="name"
                                placeholder="Введите название"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <Row>
                                <Col>
                                    <AvField
                                        label="Дата начала"
                                        name="dateStart"
                                        placeholder="Введите дату"
                                        type="datetime-local"
                                        value={this.state.dateStart}
                                        onInput={(event) =>
                                            this.setState({
                                                dateStart: formatData(
                                                    event.target.value
                                                ),
                                            })
                                        }
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <AvField
                                        label="Дата окончания"
                                        name="dateEnd"
                                        placeholder="Введите дату"
                                        type="datetime-local"
                                        value={this.state.dateEnd}
                                        onInput={(event) =>
                                            this.setState({
                                                dateEnd: formatData(
                                                    event.target.value
                                                ),
                                            })
                                        }
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage:
                                                    'Обязательно для заполнения',
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <AvSelect
                                label="Пользователь"
                                name="user"
                                value={this.state.user}
                                api="/user/input_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvSelect
                                label="Подписка"
                                name="subscription"
                                value={this.state.subscription}
                                api="/subscription/input_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvField
                                label="Цена"
                                name="price"
                                placeholder="Введите цену"
                                value={String(this.state.price)}
                                onInput={this.handleInputChange}
                                validate={{
                                    pattern: {
                                        value: '^[0-9]+$',
                                        errorMessage:
                                            'Неверное значение. Введите только целые числа.',
                                    },
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center justify-content-end">
                                <Button
                                    color="success"
                                    className="waves-effect waves-light"
                                >
                                    Сохранить{' '}
                                    <i className="ri-check-line align-middle ml-1"></i>
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default ContractForm
