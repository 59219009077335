import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookForm from '../HandbookForm'

class GenderUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Пол', link: '/handbooks/gender' },
                {
                    title: 'Редактировать пол',
                    link: '/handbooks/gender/update',
                },
            ],
            fields: [
                {
                    label: 'Код',
                    name: 'code',
                    placeholder: 'Введите код',
                    validate: {
                        required: {
                            value: true,
                            errorMessage: 'Обязательно для заполнения',
                        },
                    },
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.match.params.id}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookForm
                            title="Создание пола"
                            method="post"
                            api="/handbook/gender/update"
                            redirect="/handbooks/gender"
                            one="/handbook/gender/one"
                            fields={this.state.fields}
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default GenderUpdate
