import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Container } from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DropdownControls from '../../../components/Common/DropdownControls';
import axios from 'axios'
import { logoutUser } from '../../../store/actions';

const user = localStorage.getItem('authUser');
let jsonUser = JSON.parse(user);

class UnofficialHolidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "ExportLink", link : "/" },
        { title : "Неофициальные праздники", link : "/business_calendar/unofficial_holidays" },
      ],
      rows: []
    };
  }

  componentDidMount() {
    axios.get('/business_calendar/unofficial_holidays/list?limit=999&offset=0')
    .then(response => {
      if (response.status >= 200) {
        const data = response.data.data;

        for (let i = 0; i < data.length; i++) {
          data[i].controls = <DropdownControls id={data[i].id} data={this.state.rows} actions={{
            update: `/business_calendar/unofficial_holidays/update/${data[i].id}/`,
            delete: `/business_calendar/unofficial_holidays/delete`
          }} />;

          if (data[i].active) {
            data[i].active = 'Да';
          } else {
            data[i].active = 'Нет';
          }
        }

        this.setState({rows: data})
      }
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 401: message = "Пользователь с введенными данными не найден"; break;
          case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
          default: message = err[1]; break;
        }
        //window.location.href = '/logout';
      }
      //throw message;
    })
  }

  render() {
    const defaultColumns = [
      {
        label: "Дата создания",
        field: "date_create",
        sort: "asc",
        width: 150
      },
      {
        label: "Дата изменения",
        field: "date_update",
        sort: "asc",
        width: 150
      },
      {
        label: "Наименование",
        field: "name",
        sort: "asc",
        width: 150
      },
      {
        label: "Автор",
        field: "user_name",
        sort: "asc",
        width: 150
      },
      {
        label: "Последнее изменение",
        field: "user_update",
        sort: "asc",
        width: 150
      },
      {
        label: "Активность",
        field: "active",
        sort: "asc",
        width: 150
      },
      {
        label: '',
        field: 'controls',
        sort: 'disabled',
        width: 150
      }
    ];

    const data = {
      columns: this.props.columns || defaultColumns,
      rows: this.state.rows
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title="Неофициальные праздники" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>

                  <CardHeader>      
                    <Link to="/business_calendar/unofficial_holidays/create" className="btn btn-primary waves-effect waves-light">
                      Добавить
                    </Link> 
                  </CardHeader>

                  <CardBody>
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      btn={true}
                      entriesOptions={[20, 40, 60]}
                      entries={20}
                      data={data}
                      history={this.props.history}
                    />
                  </CardBody>

                  <CardFooter>
                    <Link to="/business_calendar/unofficial_holidays/create" className="btn btn-primary waves-effect waves-light">
                      Добавить
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UnofficialHolidays
