import Analytics from '../pages/Analytics/index'
import AnalyticsAdd from '../pages/Analytics/add'
import AnalyticsEdit from '../pages/Analytics/edit'

const analyticsRoutes = [
    { path: '/analytics/update/:id', component: AnalyticsEdit },
    { path: '/analytics/create', component: AnalyticsAdd },
    { path: '/analytics', component: Analytics },
]

export { analyticsRoutes }
