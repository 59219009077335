import React, {Component} from 'react';
import {AvFeedback, AvGroup, AvInput} from 'availity-reactstrap-validation'
import {isEmpty} from "../../utils/utils";
import {Label} from "reactstrap";
import Select from 'react-select';
import axios from 'axios';


class AvSelectV2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            api: this.props.api,
            value: [],
            selected: [],
            checkValueInOptions: this.props.checkValueInOptions || false,
            isMultiple: !!this.props.multiple,
            options: [],
            placeholder: this.props.placeholder || 'Выберите значение',
            isLoading: true,
            typeValueMulti: this.props.typeValueMulti || '',
            isVisible: !this.props.isVisible,
            element: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.isInViewport = this.isInViewport.bind(this);
        this.setOptionData = this.setOptionData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.scroll = this.scroll.bind(this);
    }

    componentDidMount() {
        this.setState({options: this.props.options});
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Очищаем значение если clearValue = true
        if (prevProps.clearValue !== this.props.clearValue && this.props.clearValue) {
            this.handleChange([]);
        }

        // Если ссылка API поменялась, загружаем новый список опций
        if (prevProps.api !== this.props.api) {
            this.state.api = this.props.api;
            this.loadData();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setOptionData(nextProps, nextContext);
    }

    setOptionData(nextProps, nextContext) {
        if (nextProps.options && nextProps.options.length) {
            this.state.options = nextProps.options;

            this.setState({
                options: nextProps.options
            });
        }

        if (nextProps.optionsNew && nextProps.optionsNew.length) {
            let options = this.state.options.concat(nextProps.optionsNew);

            options = options.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.name === thing.name
                    ))
            );

            this.state.options = options;

            this.setState({
                options: options
            });
        }

        if (nextProps.value && nextProps.value.length) {
            if (typeof nextProps.value === 'object') {
                nextProps.value.forEach((element, key) => {
                    if (element && !element.name) {
                        let name = this.state.options.find(el => el.id === element.id);

                        if (name) {
                            nextProps.value[key].name = name.name;
                        }
                    }
                });
            }
        }

        this.setState({
            value: nextProps.value
        });
    }

    loadData() {
        if (typeof this.state.api === 'object')
        {
            let data = [];
            let value = this.props.value;

            this.state.api.forEach((element) => {
                axios.get(element).then((response) => {

                    if (this.props.optionNew) {
                        for (let op in this.props.optionNew) {
                            data.push(this.props.optionNew[op]);
                        }
                    }

                    for (let d in response.data.data) {
                        data.push(response.data.data[d]);
                    }

                }).catch((err) => {
                    console.log(err.message);
                }).finally(() => {
                    this.setState({isLoading: false});
                });
            });

            this.setState({
                options: data,
                value: value
            });

            this.setOptionData({value: value});
        } else if (this.state.api) {
            axios.get(this.state.api).then((response) => {

                let data = [];
                let value = this.props.value;

                if (this.props.optionNew) {
                    for (let op in this.props.optionNew) {
                        data.push(this.props.optionNew[op]);
                    }
                }

                for (let d in response.data.data) {
                    data.push(response.data.data[d]);
                }

                // Если есть данный параметр, проверяем относятся ли все значения к данному списку опций.
                if (this.state.checkValueInOptions) {
                    let newValue = [];
                    value.forEach((e, i) => {
                        if (typeof data.find(el => el.id === e.id) !== "undefined") {
                            newValue.push(e);
                        }
                    });

                    value = newValue;
                    this.handleChange(value);
                }

                this.setState({
                    options: data,
                    value: value
                });

                this.setOptionData({value: value});
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                this.setState({isLoading: false});
            })
        } else if (this.props.options) {
            if (this.props.value && this.props.value.length) {
                if (typeof this.props.value === 'object') {
                    this.props.value.forEach((element, key) => {
                        if (element && !element.name) {
                            let name = this.props.options.find(el => el.id === element.id);

                            if (name) {
                                this.props.value[key].name = name.name;
                            }
                        }
                    });
                }
            }

            this.setState({
                value: this.props.value,
                isLoading: false
            });
        } else {
            this.setState({isLoading: false});
        }
    }

    handleChange(event) {
        const value = this.state.isMultiple ? event : [event];
        this.setState({ value: value });

        if (this.props.handleSelectChange) {
            this.props.handleSelectChange(
                {
                    'name': this.props['name'],
                    'value': value,
                    'data-name': this.props['data-name'] || false,
                    'field-type': this.props['field-type'] || '',
                    'data-index': typeof this.props['data-index'] == "undefined" ? false : this.props['data-index'],
                    'data-key': typeof this.props['data-key'] == "undefined" ? false : this.props['data-key'],
                    'multiple': this.state.isMultiple
                }
            );
            this.setState({isLoading: false});
        }
    };

    isInViewport(el) {
        if (!el) return false;

        this.setState({element: el});

        if (!this.state.isVisible) {
            window.addEventListener('scroll', this.scroll, false);
        }
    }

    scroll() {
        if (!this.state.element.getBoundingClientRect()) return false;

        const top = this.state.element.getBoundingClientRect().top;
        const visible = (top + 0) >= 0 && (top - 0) <= window.innerHeight;

        if (visible) {
            this.setState({isVisible: true});
            window.removeEventListener('scroll', this.scroll);
        }
    }

    render() {
        let value = this.state.value && this.state.value.length && !!this.props.errorMessage ? 'value' : '';

        // if (this.state.typeValueMulti.length && this.state.value.id) {
        //   value = 'value';
        // }

        if (isEmpty(this.state.value) || !isEmpty(this.state.value) && !!this.props.errorMessage && Array.isArray(this.state.value) && this.state.value[0].id == '') {
            value = '';
        }

        if (this.state.typeValueMulti.length && this.state.value && this.state.value.id) {
            value = 'value';
        }

        const styles = {
            menuList: (provided, state) => ({
                ...provided,
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                width: '100%',
                display: 'block',
                overflowY: 'scroll',
            }),
            menu: (provided, state) => ({
                ...provided,
                height: '160px',
                maxHeight: '160px',
            })
        }

        return (
            <React.Fragment>
                <AvGroup>
                    <div ref={this.isInViewport}>

                        <Label htmlFor={this.props.name}>{this.props.label}</Label>
                        {this.state.isVisible &&
                            <AvInput name={this.props.name} type="hidden" ref="input" value={value} required={!!this.props.errorMessage} />
                        }
                        <Select
                            options={this.state.options}
                            value={this.state.value}
                            styles={styles}
                            name={this.props.name}
                            placeholder={this.state.placeholder}
                            onChange={this.handleChange}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isMulti={this.state.isMultiple}
                            isLoading={this.state.isLoading && !this.props.isDisabled}
                            isDisabled={this.props.isDisabled}
                            isClearable={Boolean(this.props.isClearable)}
                        />
                        <AvFeedback>{this.props.errorMessage}</AvFeedback>

                    </div>
                </AvGroup>
            </React.Fragment>
        );
    }
}

AvSelectV2.defaultProps = {
    value: [],
    options: []
};

export default AvSelectV2;
