import Projects from '../pages/Projects/index'
import ProjectsAdd from '../pages/Projects/add'
import ProjectsEdit from '../pages/Projects/edit'

const projectsRoutes = [
    { path: '/projects/update/:id', component: ProjectsEdit },
    { path: '/projects/create', component: ProjectsAdd },
    { path: '/projects', component: Projects },
]

export { projectsRoutes }
