import React, { Component } from 'react'
import { MDBDataTable, MDBPagination } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import DropdownControls from '../../../components/Common/DropdownControls'
import axios from 'axios'
import { logoutUser } from '../../../store/actions'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './style.css'
import store from '../../../store'
import {
    setMax,
    setPage,
    setQuantity,
    setSearch,
    setSort,
    isNeedRefresh,
} from '../../../store/contract-page/actionsCreators'
import { PaginationAmountEntityShow } from '../../../components/Common/PaginationAmountEntityShow'
import { connect } from 'react-redux'
import { PaginationPageSizeSelect } from '../../../components/Common/PaginationPageSizeSelect'
import { SearchWidget } from '../../../components/Common/SearchWidget'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class Contract extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Договоры', link: '/contract' },
            ],
            rows: [],
            isNeedRenerder: false,
        }
    }

    refreshData = () => {
        const pageStore = store.getState().contractPageReducer
        axios
            .get(
                `/contract/list?` +
                    `quantity=${pageStore.entryQuantity}&` +
                    `page=${pageStore.page}&` +
                    `search=${pageStore.search}&` +
                    `sort_by=${pageStore.currentSortBy || ''}&` +
                    `sort_order=${pageStore.currentSortOrder || ''}`
            )
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data
                    store.dispatch(setMax(response.data.maxCount))

                    for (let i = 0; i < data.length; i++) {
                        data[i].controls = (
                            <DropdownControls
                                id={data[i].id}
                                data={this.state.rows}
                                actions={{
                                    update: `/contract/update/${data[i].id}/`,
                                    delete: `/contract/delete`,
                                }}
                            />
                        )

                        if (data[i].active) {
                            data[i].active = 'Да'
                        } else {
                            data[i].active = 'Нет'
                        }
                    }

                    this.setState({ rows: data })
                }
            })
            .catch((err) => {
                let message
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        case 401:
                            message =
                                'Пользователь с введенными данными не найден'
                            break
                        case 403:
                            message =
                                'Не хватает прав. Обратитесь к администратору'
                            break
                        default:
                            message = err[1]
                            break
                    }
                    // window.location.href = '/logout';
                }
                throw message
            })
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        if (store.getState().contractPageReducer.isNeedRefresh) {
            store.dispatch(isNeedRefresh(false))
            this.refreshData()
        }
    }

    onChangePage = (current) => {
        store.dispatch(setPage(Number(current)))
        store.dispatch(isNeedRefresh(true))
        // this.setState({ isNeedRenerder: true });
    }

    onChangePerPage = (value) => {
        store.dispatch(setQuantity(Number(value)))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
        this.setState({ isNeedRenerder: true })
    }

    onChangeSearch = (value) => {
        store.dispatch(setSearch(value))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
    }

    render() {
        const defaultColumns = [
            {
                label: 'Дата создания',
                field: 'date_create',
                sort: store.getState().contractPageReducer.date_create,
                width: 150,
            },
            {
                label: 'Дата обновления',
                field: 'date_update',
                sort: store.getState().contractPageReducer.date_update,
                width: 150,
            },
            {
                label: 'Наименование',
                field: 'name',
                sort: store.getState().contractPageReducer.name,
                width: 150,
            },
            {
                label: 'Пользователь',
                field: 'user_email',
                sort: 'disabled',
                width: 150,
            },
            {
                label: 'Активность',
                field: 'active',
                sort: store.getState().contractPageReducer.active,
                width: 150,
            },
            {
                label: 'Последнее изменение',
                field: 'user_update',
                sort: 'disabled',
                width: 150,
            },
            {
                label: '',
                field: 'controls',
                sort: 'disabled',
                width: 150,
            },
        ]

        const data = {
            columns: this.props.columns || defaultColumns,
            rows: this.state.rows,
        }

        const Table = () => {
            if (this.state.isNeedRenerder) {
                setTimeout(() => {
                    this.setState({ isNeedRenerder: false })
                }, 10)
                return <></>
            }

            return (
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    sortable
                    btn
                    displayEntries={false}
                    searching={false}
                    onSort={(res) => {
                        if (res.direction && res.column) {
                            const data = {
                                currentSortBy: res.column,
                                currentSortOrder: res.direction,
                                [res.column]:
                                    res.direction === 'asc' ? 'desc' : 'asc',
                            }
                            for (const key in store.getState()
                                .contractPageReducer) {
                                if (
                                    key === 'date_update' ||
                                    key === 'date_create' ||
                                    key === 'name' ||
                                    key === 'active'
                                ) {
                                    if (key !== res.column) {
                                        data[key] = ''
                                    }
                                }
                            }
                            store.dispatch(setSort(data))
                            store.dispatch(isNeedRefresh(true))
                        }
                    }}
                    entries={store.getState().contractPageReducer.entryQuantity}
                    data={data}
                    info={false}
                    paginationLabel={['']}
                />
            )
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Link
                                            to="/contract/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Добавить договор
                                        </Link>
                                    </CardHeader>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <PaginationPageSizeSelect
                                                onChangePerPage={
                                                    this.onChangePerPage
                                                }
                                                value={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .entryQuantity
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                margin: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <SearchWidget
                                                value={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .search
                                                }
                                                onChangeSearch={
                                                    this.onChangeSearch
                                                }
                                                placeholder={'search'}
                                            />
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className={'contract-table'}>
                                            {Table()}
                                        </div>
                                        <MDBPagination>
                                            <Pagination
                                                defaultPageSize={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .entryQuantity
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .entryQuantity
                                                }
                                                current={
                                                    store.getState()
                                                        .contractPageReducer.page
                                                }
                                                defaultCurrent={
                                                    store.getState()
                                                        .contractPageReducer.page
                                                }
                                                onChange={this.onChangePage}
                                                total={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                        <MDBPagination>
                                            <PaginationAmountEntityShow
                                                page={
                                                    store.getState()
                                                        .contractPageReducer.page
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .entryQuantity
                                                }
                                                count={
                                                    store.getState()
                                                        .contractPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                    </CardBody>

                                    <CardFooter>
                                        <Link
                                            to="/contract/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Добавить договор
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => state.contractPageReducer
export default connect(mapStateToProps, {
    setPage,
    setQuantity,
    setSort,
    setSearch,
    setMax,
    isNeedRefresh,
})(Contract)