import Associations from '../pages/Associations/index'
import AssociationsAdd from '../pages/Associations/add'
import AssociationsEdit from '../pages/Associations/edit'

const associationsRoutes = [
    { path: '/associations/update/:id', component: AssociationsEdit },
    { path: '/associations/create', component: AssociationsAdd },
    { path: '/associations', component: Associations },
]

export { associationsRoutes }
