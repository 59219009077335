import {
    SET_ENTRIES_QUANTITY,
    SET_PAGE,
    SET_SEARCH,
    SET_MAX,
    SET_SORT,
    IS_NEED_REFRESH,
} from './actions'

export const setPage = (value) => ({
    type: SET_PAGE,
    payload: value,
})

export const setQuantity = (value) => ({
    type: SET_ENTRIES_QUANTITY,
    payload: value,
})

export const setSearch = (value) => ({
    type: SET_SEARCH,
    payload: value,
})

export const setMax = (value) => ({
    type: SET_MAX,
    payload: value,
})

export const setSort = (value) => ({
    type: SET_SORT,
    payload: value,
})

export const isNeedRefresh = (value) => ({
    type: IS_NEED_REFRESH,
    payload: value,
})
