import React, { Component } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import axios from 'axios'
import toastr from 'toastr'
import Dropzone from 'react-dropzone'
import 'toastr/build/toastr.min.css'
import user from './../../store/user'

class HandbookForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: true,
            name: '',
            isUpdate: this.props.id,
            file: null,
            uploadedFile: null,
        }

        if (
            typeof this.props.fields != 'undefined' &&
            this.props.fields.length > 0
        ) {
            this.props.fields.forEach((element) => {
                this.state[element.name] = ''
            })
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name,
                        uploadedFile: data.file,
                    })

                    if (
                        typeof this.props.fields != 'undefined' &&
                        this.props.fields.length > 0
                    ) {
                        this.props.fields.forEach((element) => {
                            this.setState({
                                [element.name]: data[element.name],
                            })
                        })
                    }
                })
        }
    }

    handleAcceptedFile = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                file: file,
                uploadedFile: null,
            })
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!errors.length) {
            const fd = new FormData()

            const data = {
                createdBy: user.userId,
                name: this.state.values.name,
                active: this.state.isActive,
                file: this.state.file,
            }

            if (this.state.uploadedFile) {
                data.file = this.state.uploadedFile.id
            }

            if (
                typeof this.props.fields != 'undefined' &&
                this.props.fields.length > 0
            ) {
                this.props.fields.forEach((element) => {
                    data[element.name] = this.state[element.name]
                })
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    if (
                        typeof data[key] === 'object' &&
                        data[key] !== null &&
                        key !== 'file'
                    ) {
                        data[key].map((el) => fd.append(`${key}[]`, el))
                    } else {
                        fd.append(key, data[key])
                    }
                }
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: fd,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push(this.props.redirect)
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 422 &&
                        err.response.data.errors
                    ) {
                        let errors = err.response.data.errors
                        for (var key in errors) {
                            toastr.error(`<b>` + errors[key] + `</b>`)
                        }
                    }
                    console.log(err.message)
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    className="needs-validation"
                    id="purchaser"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            id="isActive"
                                            checked={this.state.isActive}
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="isActive"
                                        >
                                            Активен
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование"
                                name="name"
                                placeholder="Введите наименование"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            {typeof this.props.fields != 'undefined' &&
                                this.props.fields.length > 0 &&
                                this.props.fields.map((element, i) => {
                                    return (
                                        <AvField
                                            key={i}
                                            label={element.label}
                                            name={element.name}
                                            placeholder={element.placeholder}
                                            value={this.state[element.name]}
                                            onInput={this.handleInputChange}
                                            validate={element.validate}
                                        />
                                    )
                                })}
                        </CardBody>

                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">Файл</h4>
                            </CardHeader>

                            <CardBody>
                                <Dropzone
                                    maxFiles={1}
                                    multiple={false}
                                    onDrop={(acceptedPreview) =>
                                        this.handleAcceptedFile(acceptedPreview)
                                    }
                                    accept="image/*"
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            className={
                                                this.state.file ||
                                                this.state.uploadedFile
                                                    ? 'dropzone dropzone-single dz-max-files-reached'
                                                    : 'dropzone dropzone-single'
                                            }
                                            style={{ textAlign: 'center' }}
                                        >
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                </div>
                                                <h4>
                                                    Перетащите картинку сюда или
                                                    кликните для загрузки
                                                </h4>
                                            </div>

                                            {this.state.file && (
                                                <div className="dz-preview dz-preview-single">
                                                    <div className="dz-preview-cover">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="dz-preview-img"
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                            alt={
                                                                this.state.file
                                                                    .name
                                                            }
                                                            src={
                                                                this.state.file
                                                                    .preview
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.uploadedFile && (
                                                <div className="dz-preview dz-preview-single">
                                                    <div className="dz-preview-cover">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="dz-preview-img"
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                            src={
                                                                this.state
                                                                    .uploadedFile
                                                                    .path
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>

                                <AvField
                                    type="hidden"
                                    name="file"
                                    value={
                                        this.state.file ||
                                        this.state.uploadedFile
                                    }
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage:
                                                'Обязательно для заполнения',
                                        },
                                    }}
                                />
                            </CardBody>
                        </Card>

                        <CardFooter>
                            <div className="d-flex align-items-center justify-content-end">
                                <Button
                                    color="success"
                                    className="waves-effect waves-light"
                                >
                                    Сохранить{' '}
                                    <i className="ri-check-line align-middle ml-1"></i>
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default HandbookForm
