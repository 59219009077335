import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookLangAndFileForm from '../HandbookLangAndFileForm'

class ObjectTypeCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Вид объекта', link: '/handbooks/object_type' },
                {
                    title: 'Добавить вид объекта',
                    link: '/handbooks/object_type/create',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Добавить вид объекта"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookLangAndFileForm
                            title="Создание вида объекта"
                            method="post"
                            api="/handbook/object_type/create"
                            redirect="/handbooks/object_type"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ObjectTypeCreate
