import Tag from '../pages/Tag/index'
import TagAdd from '../pages/Tag/add'
import TagEdit from '../pages/Tag/edit'

const tagRoutes = [
    { path: '/tag/update/:id', component: TagEdit },
    { path: '/tag/create', component: TagAdd },
    { path: '/tag', component: Tag },
]

export { tagRoutes }
