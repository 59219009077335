import React, { Component } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import ProjectsForm from './ProjectsForm'
import { Container } from 'reactstrap'

// const user = localStorage.getItem('authUser')
// const jsonUser = JSON.parse(user)

class ProjectsAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список проектов ', link: '/projects' },
                { title: 'Создать проект', link: '/projects/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый проект"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <ProjectsForm
                            title="Создание проекта"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ProjectsAdd
