import React, { Component } from 'react'
import { MDBDataTable, MDBPagination } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DropdownControls from '../../components/Common/DropdownControls'
import axios from 'axios'
import { logoutUser } from '../../store/actions'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './style.css'
import store from '../../store'
import {
    setMax,
    setPage,
    setQuantity,
    setSearch,
    setSort,
    isNeedRefresh,
} from '../../store/scheduler-page/actionsCreators'
import { connect } from 'react-redux'

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class Scheduler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список планировщика ', link: '/scheduler' },
            ],
            isNeedRenerder: false,
            rows: [],
        }
    }

    refreshData = () => {
        const pageStore = store.getState().schedulerPageReducer
        axios
            .get(
                `/scheduler/list?` +
                    `quantity=${pageStore.entryQuantity}&` +
                    `page=${pageStore.page}&` +
                    `search=${pageStore.search}&` +
                    `sort_by=${pageStore.currentSortBy || ''}&` +
                    `sort_order=${pageStore.currentSortOrder || ''}`
            )
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data
                    store.dispatch(setMax(response.data.maxCount))

                    for (let i = 0; i < data.length; i++) {
                        data[i].controls = (
                            <DropdownControls
                                id={data[i].id}
                                redirect="/scheduler/"
                                history={this.props.history}
                                actions={{
                                    update: `/scheduler/update/${data[i].id}/`,
                                    delete: '/scheduler/delete',
                                }}
                            />
                        )

                        for (var val in data[i]) {
                            if (
                                data[i][val] == true ||
                                data[i][val] == false ||
                                data[i][val] == null
                            ) {
                                data[i][val] = String(data[i][val])
                            }
                        }
                    }

                    this.setState({ rows: data })
                }
            })
            .catch((err) => {
                // let message;
                // if (err.response && err.response.status) {
                //   switch (err.response.status) {
                //     case 401: message = "Пользователь с введенными данными не найден"; break;
                //     case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
                //     default: message = err[1]; break;
                //   }
                //   window.location.href = '/logout';
                // }
                // throw message;
            })
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        if (store.getState().schedulerPageReducer.isNeedRefresh) {
            store.dispatch(isNeedRefresh(false))
            this.refreshData()
        }
    }

    onChangePage = (current) => {
        store.dispatch(setPage(Number(current)))
        store.dispatch(isNeedRefresh(true))
        //this.setState({ isNeedRenerder: true });
    }

    onChancgePerPage = (value) => {
        store.dispatch(setQuantity(Number(value.target.value)))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
        this.setState({ isNeedRenerder: true })
    }

    onChangeSearch = (value) => {
        store.dispatch(setSearch(value.target.value))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Наименование',
                    field: 'name',
                    sort: store.getState().schedulerPageReducer.name,
                    width: 150,
                },
                {
                    label: 'Команда',
                    field: 'command',
                    sort: store.getState().schedulerPageReducer.command,
                    width: 150,
                },
                {
                    label: 'Выражение cron',
                    field: 'cron_expression',
                    sort: store.getState().schedulerPageReducer.cron_expression,
                    width: 150,
                },
                {
                    label: 'Последнее выполнение',
                    field: 'last_execution',
                    sort: store.getState().schedulerPageReducer.last_execution,
                    width: 150,
                },
                {
                    label: 'Опции',
                    field: 'arguments',
                    sort: store.getState().schedulerPageReducer.arguments,
                    width: 150,
                },
                {
                    label: 'Код последней ошибки',
                    field: 'last_return_code',
                    sort: store.getState().schedulerPageReducer
                        .last_return_code,
                    width: 150,
                },
                {
                    label: 'Лог файл',
                    field: 'log_file',
                    sort: store.getState().schedulerPageReducer.log_file,
                    width: 150,
                },
                {
                    label: 'Приоритет',
                    field: 'priority',
                    sort: store.getState().schedulerPageReducer.priority,
                    width: 150,
                },
                {
                    label: 'Выполнить немедленно',
                    field: 'execute_immediately',
                    sort: store.getState().schedulerPageReducer
                        .execute_immediately,
                    width: 150,
                },
                {
                    label: 'Отключенный',
                    field: 'disabled',
                    sort: store.getState().schedulerPageReducer.disabled,
                    width: 150,
                },
                {
                    label: 'Заблокированный',
                    field: 'locked',
                    sort: store.getState().schedulerPageReducer.locked,
                    width: 150,
                },
                {
                    label: '',
                    field: 'controls',
                    sort: 'disabled',
                    width: 150,
                },
            ],
            rows: this.state.rows,
        }

        const Table = () => {
            if (this.state.isNeedRenerder) {
                setTimeout(() => {
                    this.setState({ isNeedRenerder: false })
                }, 10)
                return <></>
            }

            return (
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    sortable
                    btn
                    displayEntries={false}
                    searching={false}
                    onSort={(res) => {
                        if (res.direction && res.column) {
                            let data = {
                                currentSortBy: res.column,
                                currentSortOrder: res.direction,
                                [res.column]:
                                    res.direction === 'asc' ? 'desc' : 'asc',
                            }
                            // for (let key in store.getState().schedulerPageReducer) {
                            //   console.log(key);
                            //   console.log(res.column);
                            //   if (key !== res.column) {
                            //     data[key] = '';
                            //   }
                            // }
                            store.dispatch(setSort(data))
                            store.dispatch(isNeedRefresh(true))
                        }
                    }}
                    entries={Number(
                        store.getState().schedulerPageReducer.entryQuantity
                    )}
                    data={data}
                    info={false}
                    paginationLabel={['']}
                />
            )
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список планировщика"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Link
                                            to="/scheduler/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать планировщик
                                        </Link>
                                    </CardHeader>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <div>{'Show entries'}</div>
                                            <select
                                                className="browser-default custom-select"
                                                style={{ width: '100px' }}
                                                value={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .entryQuantity
                                                }
                                                onChange={this.onChancgePerPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                            </select>
                                        </div>
                                        <div
                                            style={{
                                                margin: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <input
                                                style={{ width: '150px' }}
                                                value={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .search
                                                }
                                                onChange={this.onChangeSearch}
                                                placeholder={'search'}
                                            />
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className={'scheduler-table'}>
                                            {Table()}
                                        </div>
                                        <MDBPagination>
                                            <Pagination
                                                defaultPageSize={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .entryQuantity
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .entryQuantity
                                                }
                                                current={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .page
                                                }
                                                defaultCurrent={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .page
                                                }
                                                onChange={this.onChangePage}
                                                total={
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                        <MDBPagination>
                                            {`Показаны ${
                                                1 +
                                                (store.getState()
                                                    .schedulerPageReducer.page -
                                                    1) *
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .entryQuantity
                                            }` +
                                                ` - ${
                                                    1 +
                                                        store.getState()
                                                            .schedulerPageReducer
                                                            .page *
                                                            store.getState()
                                                                .schedulerPageReducer
                                                                .entryQuantity >
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .maxCount
                                                        ? store.getState()
                                                              .schedulerPageReducer
                                                              .maxCount
                                                        : store.getState()
                                                              .schedulerPageReducer
                                                              .page *
                                                          store.getState()
                                                              .schedulerPageReducer
                                                              .entryQuantity
                                                } записи из ${
                                                    store.getState()
                                                        .schedulerPageReducer
                                                        .maxCount
                                                }`}
                                        </MDBPagination>
                                    </CardBody>

                                    <CardFooter>
                                        <Link
                                            to="/scheduler/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать планировщик
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => state.schedulerPageReducer
export default connect(mapStateToProps, {
    setPage,
    setQuantity,
    setSort,
    setSearch,
    setMax,
    isNeedRefresh,
})(Scheduler)
