function renameToSelect(arr) {
    arr.map((el) => {
        el.value = el.id
        el.label = el.name

        delete el.id
        delete el.name
    })

    return arr
}

function leadingZero(str) {
    return ('0' + str).slice(-2)
}

export { renameToSelect, leadingZero }
