import React, { Component } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import LangForm from './LangForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class LangAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список языков', link: '/languages' },
                { title: 'Добавить язык', link: '/languages/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый язык"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <LangForm
                            title="Создание языка"
                            typeForm="add"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default LangAdd
