import React, {Component} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Label, Row} from 'reactstrap';
import {formatValue, isEmpty, stringToBoolean} from "../../utils/utils";
import AvSelectV2 from "../../components/Common/AvSelectV2";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: true,
            name: '',
            module: [],
            filters: [],
            filter_values: [],
            isUpdate: this.props.id,
            errorMessage: !!"Обязательно для заполнения",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputGroupChange = this.handleInputGroupChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleModuleSelectChange = this.handleModuleSelectChange.bind(this);
    }

    componentWillMount() {
        if (this.state.isUpdate) {
            axios.get(`/infoblock/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data;

                this.setState({
                    isActive: data.active,
                    name: data.name,
                    module: data.module_code,
                    filter_values: data.filter_values
                });

                this.handleModuleSelectChange({
                    "value": [
                        {"id": data.module_code}
                    ]
                });
            });
        }
    }

    /**
     * Обработчик изменения инпута.
     *
     * @param event
     */
    handleInputChange(event) {
        const target = event.target;
        const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    /**
     * Обрабочтик изменения input группы.
     *
     * @param event
     */
    handleInputGroupChange(event) {
        const target = event.target;

        this.state.filter_values[target.name] = {
            "filter_value": target.value,
            "filter_type": target.getAttribute("filter-type"),
        };

        this.setState({filter_values: this.state.filter_values});
    }

    /**
     * Обработчик изменения select.
     *
     * @param props
     */
    handleSelectChange(props) {
        let key = props['name'];
        let value = props['value'];
        let dataName = props['data-name'];
        let index = props['data-index'];
        let fieldType = props['field-type'];

        if (!isEmpty(dataName) && typeof index !== "boolean") {
            this.state.filter_values[key] = {
                "filter_value": value,
                "filter_type": fieldType,
            };

            this.setState({filter_values: this.state.filter_values});
        } else {
            this.state[key] = value;
            this.setState({key: value});
        }
    }

    /**
     * Обработчик изменения select для списка модулей.
     *
     * @param props
     */
    handleModuleSelectChange(props) {
        this.handleSelectChange(props);

        let codeName = props['value'][0]['id'];

        axios.get(`/module-filters/input-list-filters?code_name=${codeName}`).then((response) => {
            let {data} = response.data;

            this.state.filters = data;

            this.setState({filters: this.state.filters});
        });
    }

    /**
     * Обработчик отправки формы.
     *
     * @param event
     * @param errors
     * @param values
     */
    handleSubmit(event, errors, values) {
        this.setState({errors, values});

        if (!this.state.errors.length) {
            const fd = this.getFormData();
            const url = this.state.isUpdate ? '/infoblock/update' : '/infoblock/create';

            axios.post(url, fd).then(response => {
                if (response.status === 200) {
                    this.props.history.push('/infoblock');
                }
            }).catch(err => {
                toastr.error(`<b>` + err.response.data.message + `</b>`);

                let errors = err.response.data.errors;
                for (var key in errors) {
                    toastr.error(`<b>` + key + ':' + errors[key] + `</b>`);
                }
                console.log(err.message)
            });
        }
    };

    /**
     * Возвращает данные формы для отправки запроса.
     *
     * @returns {{module_code: *, filter_values: *[], name: string, active}}
     */
    getFormData() {
        let filterValues = [];

        for (let arrKey in this.state.filter_values) {
            let value;
            let e = this.state.filter_values[arrKey];

            if (e?.filter_type === 'string') { // Конвертация в строку
                let valueString = '';

                if (e.filter_value.constructor === Array) {
                    valueString = e?.filter_value[0]?.id;
                } else {
                    valueString = e?.filter_value;
                }

                value = valueString;
            } else if (e?.filter_type === 'int') { // Конвертация в целое число
                let valueInt;

                if (e.filter_value.constructor === Array) {
                    valueInt = e?.filter_value[0]?.id;
                } else {
                    valueInt = e?.filter_value;
                }

                value = parseInt(valueInt);
            } else if (e?.filter_type === 'bool') { // Конвертация в логическое значение
                let valueBool;

                if (e.filter_value.constructor === Array) {
                    valueBool = e?.filter_value[0]?.id;
                } else {
                    valueBool = e?.filter_value
                }

                value = stringToBoolean(valueBool);
            } else if (e?.filter_type === 'float') { // Конвертация в значение с плавающей точкой
                let valueFloat;

                if (e.filter_value.constructor === Array) {
                    valueFloat = e?.filter_value[0]?.id;
                } else {
                    valueFloat = e?.filter_value
                }

                value = parseFloat(valueFloat);
            } else if (e?.filter_type === 'array') { // Конвертация в массив
                let valueArray = [];

                e.filter_value.forEach((e, i) => {
                    valueArray.push(e.id);
                });

                value = valueArray;
            } else {
                value = e?.filter_value || e;
            }

            filterValues.push({
                'filter_code': arrKey,
                'filter_value': value
            });
        }

        const data = {
            active: this.state.isActive,
            name: this.state.name,
            module_code: this.state.module[0]?.id || this.state.module,
            filter_values: filterValues
        };

        if (this.state.isUpdate) {
            data.id = this.props.id;
        }

        return data;
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="working_days">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">{this.props.title}</h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="custom-control custom-switch">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            id="isActive"
                                            checked={this.state.isActive}
                                            onChange={this.handleInputChange}
                                        />
                                        <Label className="custom-control-label" htmlFor="isActive">
                                            Активен
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование инфоблока"
                                name="name"
                                placeholder="Введите значение"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Обязательно для заполнения'
                                    }
                                }}
                            />

                            <AvSelectV2
                                label="Модуль"
                                name='module'
                                data-name='module'
                                data-key='id'
                                value={formatValue(this.state.module)}
                                api={'/module-filters/input-list-modules'}
                                handleSelectChange={this.handleModuleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvGroup>
                                <div style={{borderBottom: "1px solid #efefef", marginTop: "10px"}}>
                                    {this.state.filters.map((obj, i) => {
                                            return (
                                                <>
                                                    {obj?.type === 'input' && (
                                                        <Row style={{width: '100%'}}>
                                                            <Col>
                                                                <AvField
                                                                    label={obj.name}
                                                                    name={obj.field_name}
                                                                    data-index={i}
                                                                    filter-type={obj.field_type}
                                                                    placeholder="Введите значение"
                                                                    value={
                                                                        this.state.filter_values[obj.field_name]?.filter_value
                                                                        || this.state.filter_values[obj.field_name]
                                                                    }
                                                                    onInput={this.handleInputGroupChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {obj?.type === 'select' && (
                                                        <Row style={{width: '100%'}}>
                                                            <Col>
                                                                <AvSelectV2
                                                                    label={obj.name}
                                                                    name={obj.field_name}
                                                                    data-name='filter_values'
                                                                    data-index={i}
                                                                    data-key='id'
                                                                    field-type={obj.field_type}
                                                                    value={
                                                                        formatValue(this.state.filter_values[obj.field_name]?.filter_value
                                                                            || this.state.filter_values[obj.field_name]
                                                                        )
                                                                    }
                                                                    api={obj.url}
                                                                    options={obj.data}
                                                                    handleSelectChange={this.handleSelectChange}
                                                                    multiple={obj.field_type === 'array'}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </>
                                            )
                                        }
                                    )}
                                </div>
                            </AvGroup>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1"/>
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default Form
