import React, { useMemo } from 'react'

const range = (start, end) => {
    const length = end - start + 1
    /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
    return Array.from({ length }, (_, idx) => idx + start)
}

export const DOTS = '...'

export const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage,
}) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize)

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5

        /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        )

        /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount

        /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount
            const leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }

        /*
    	Case 3: No right dots to show, but left dots to be shown
    */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount
            const rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            )
            return [firstPageIndex, DOTS, ...rightRange]
        }

        /*
    	Case 4: Both left and right dots to be shown
    */
        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex)
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }
    }, [totalCount, pageSize, siblingCount, currentPage])

    return paginationRange
}

const Pagination = (props) => {
    const {
        onPageChange = () => {},
        totalCount,
        siblingCount = 1,
        currentPage = 1,
        pageSize = 20,
    } = props

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    })

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null
    }

    const onNext = () => {
        onPageChange(currentPage + 1, pageSize)
    }

    const onPrevious = () => {
        onPageChange(currentPage - 1, pageSize)
    }

    const lastPage = paginationRange[paginationRange.length - 1]

    return (
        <div data-test="datatable-pagination" className="col-sm-12 col-md-7">
            <div className="dataTables_paginate">
                <ul data-test="pagination" className="pagination">
                    {/* Left navigation arrow */}
                    <li
                        data-test="page-item"
                        className={`page-item ${
                            currentPage === 1 ? 'disabled' : ''
                        }`}
                    >
                        <button
                            data-test="page-link"
                            aria-label="Previous"
                            className="page-link page-link"
                            onClick={onPrevious}
                        >
                            <span>Previous</span>
                        </button>
                    </li>
                    {paginationRange.map((pageNumber,k) => {
                        // If the pageItem is a DOT, render the DOTS unicode character
                        if (pageNumber === DOTS) {
                            return (
                                <li
                                    key={'dot_' + k}
                                    className="pagination-item dots"
                                >
                                    &#8230;
                                </li>
                            )
                        }

                        // Render our Page Pills
                        return (
                            <li
                                key={pageNumber}
                                data-test="page-item"
                                className={`${
                                    pageNumber === currentPage ? 'active' : ''
                                } page-item`}
                            >
                                <button
                                    data-test="page-link"
                                    className="page-link page-link"
                                    onClick={() =>
                                        onPageChange(pageNumber, pageSize)
                                    }
                                >
                                    {pageNumber}
                                    {pageNumber === currentPage && (
                                        <span className="sr-only">
                                            (current)
                                        </span>
                                    )}
                                </button>
                            </li>
                        )
                    })}
                    {/*  Right Navigation arrow */}
                    <li
                        data-test="page-item"
                        className={`page-item ${
                            currentPage === lastPage ? 'disabled' : ''
                        }`}
                    >
                        <button
                            data-test="page-link"
                            aria-label="Next"
                            className="page-link page-link"
                            onClick={onNext}
                        >
                            <span>Next</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Pagination
