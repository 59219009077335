import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import RoleForm from './RoleForm'

class RoleUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список ролей', link: '/user_role' },
                { title: 'Редактировать роль', link: '/user_role/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        const data = {
            columns: [],
            rows: [],
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование роли"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <RoleForm
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RoleUpdate
