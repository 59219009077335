import React, { Component } from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import axios from 'axios'

//i18n
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

class DropdownControls extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: false,
        }

        this.remove = this.remove.bind(this)
    }

    edit = (id) => {
        console.log('edit:' + id)
    }

    remove(url, id) {
        if (window.confirm('Вы уверены, что хотите удалить элемент?')) {
            axios
                .request({
                    method: 'post',
                    url: url,
                    data: {
                        id: id,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        window.location.reload()
                    }
                })
                .catch((err) => {
                    if (err.response.status === 409) {
                        toastr.error(`<b>${err.response.data.message}</b>`)
                    }
                    console.log(err.response)
                })
        }
    }

    downloadWord(url, id, nameFile, country, region, industry, subIndustry) {   
        const filters = [];
        if (country !== null) {
            filters.push({'name': country[0].name, 'code': 'country'});
            country = country[0].id;
        } else {
            filters.push({'name': null, 'code': 'country'});
        }

        if (region !== null) {
            filters.push({'name': region[0].name, 'code': 'region'});
            region = region[0].id;
        } else {
            filters.push({'name': null, 'code': 'region'});
        }

        if (industry !== null) {
            filters.push({'name': industry[0].name, 'code': 'industry'});
            industry = industry[0].id;
        } else {
            filters.push({'name': null, 'code': 'industry'});
        }

        if (subIndustry !== null) {
            filters.push({'name': subIndustry[0].name, 'code': 'subIndustry'});
            subIndustry = subIndustry[0].id;
        } else {
            filters.push({'name': null, 'code': 'subIndustry'});
        }

        axios
            .request({
                method: 'post',
                url: url,
                data: {
                    id: id,
                    nameFile: nameFile,
                    country: country,
                    region: region,
                    industry: industry,
                    subIndustry: subIndustry,
                    filters: filters,
                },
                responseType: 'blob',
            })
            .then((response) => {
                if (response.status === 200) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${nameFile}.docx`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    toastr.error(`<b>${err.response.data.message}</b>`)
                }
                console.log(err.response)
            })   
    }

    render() {
        return (
            <React.Fragment>
                <Dropdown
                    isOpen={this.state.menu}
                    toggle={() => this.setState({ menu: !this.state.menu })}
                >
                    <DropdownToggle tag="i" className="arrow-none">
                        <i className="mdi mdi-dots-vertical"></i>
                    </DropdownToggle>

                    <DropdownMenu right={true}>
                        <Link
                            to={this.props.actions.update}
                            className="dropdown-item"
                        >
                            Редактировать
                        </Link>

                        {typeof this.props.nameFile != 'undefined' &&
                            <DropdownItem
                                onClick={() =>
                                    this.downloadWord(
                                        this.props.actions.word,
                                        this.props.id,
                                        this.props.nameFile,
                                        this.props.country,
                                        this.props.region,
                                        this.props.industry,
                                        this.props.subIndustry
                                    )
                                }
                            >
                                Скачать документ Word
                            </DropdownItem>
                        }

                        <DropdownItem
                            onClick={() =>
                                this.remove(
                                    this.props.actions.delete,
                                    this.props.id
                                )
                            }
                        >
                            Удалить
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )
    }
}

export default DropdownControls
