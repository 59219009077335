import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookFormRelated from '../HandbookFormRelated'

class ProductionUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список продукций', link: '/handbooks/production' },
                {
                    title: 'Редактировать продукцию',
                    link: '/handbooks/production/update',
                },
            ],
            select: {
                options: [],
                label: 'Выберите подотрасль',
                placeholder: 'Выберите опцию',
                errorMessage: 'Обязательно для заполнения',
                name: 'sub_industry',
                api: '/handbook/sub_industry/input_list',
            },
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.match.params.id}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookFormRelated
                            title="Редактирование продукции"
                            method="post"
                            api="/handbook/production/update"
                            redirect="/handbooks/production"
                            one="/handbook/production/one"
                            id={this.props.match.params.id}
                            history={this.props.history}
                            select={this.state.select}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ProductionUpdate
