import React, { Component } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Button } from 'reactstrap'
import AvSelect from '../../../components/Common/AvSelect'
// import AvMultipleInput from '../../../components/Common/AvMultipleInput'
import СheckboxTree from '../../../components/Common/СheckboxTree'
import СheckboxTreeClone from '../../../components/Common/СheckboxTreeClone'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from 'axios'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import toastr from 'toastr'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class NewsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список ролей', link: '/user_role' },
            ],

            name: '',
            code: '',
            api_url: [''],
            role_group: '',
            routes: [],
            routesSubscription: [],
            routesPublic: false,
            routesPublicSubscription: false,

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)

        this.routesData = React.createRef()
        this.routesSubscriptionData = React.createRef()
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios.get(`/role/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data

                this.setState({
                    name: data.name,
                    code: data.code,
                    // api_url: data.api_url.split(','),
                    role_group: data.role_group_id,
                    routes: data.routes.route,
                    routesSubscription: data.routes_subscription
                        ? data.routes_subscription.routeSubscription
                        : [],
                    routesPublic: data.role_group.code != 'admin',
                    routesPublicSubscription: data.role_group.code != 'admin',
                })

                this.handleSelectChange('role_group', [data.role_group])
            })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value) {
        this.setState({
            [key]: value[0],
        })

        if (value[0].name === 'Администраторы') {
            this.setState({ routesPublic: true })
            this.setState({ routesPublicSubscription: true })
            document.querySelector(
                '.checkbox-tree-subscription'
            ).style.display = 'none'
        } else {
            this.setState({ routesPublic: false })
            this.setState({ routesPublicSubscription: false })
            document.querySelector(
                '.checkbox-tree-subscription'
            ).style.display = 'block'
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData() // return;
            const url = this.state.isUpdate ? '/role/update' : '/role/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/user_role')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    const errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const routesData = this.routesData.current.getRoutesData()
        let routesSubscriptionData = null

        if (!this.state.routesPublicSubscription) {
            routesSubscriptionData = this.routesSubscriptionData.current.getRoutesData()
        }

        const data = {
            createdBy: jsonUser.userId,
            name: this.state.name,
            code: this.state.code,
            api_url: [], // this.state.api_url,
            role_group: this.state.role_group.id,
            routes: routesData,
            routesSubscription: routesSubscriptionData,
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId
        }

        return data
    }

    updateData = (value) => {
        this.setState({ api_url: value })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="news">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex"></div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование"
                                name="name"
                                placeholder="Введите заголовок"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Код"
                                name="code"
                                placeholder="Введите код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z0-9--]+$',
                                        errorMessage: 'Неверный код',
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <AvSelect
                                label="Группа"
                                name="role_group"
                                api="/role_group/input_list"
                                value={this.state.role_group}
                                handleSelectChange={this.handleSelectChange}
                                typeValueMulti="one"
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            {/* <AvMultipleInput
                updateData={this.updateData} 
                value={this.state.api_url}
                options={{
                  label:"Доступ к API",
                  name:"api_url",
                  errorMessage:"Обязательно для заполнения"
                }}
              /> */}
                            <CardHeader>
                                <h4 className="card-title mb-0">
                                    Права доступа
                                </h4>
                            </CardHeader>

                            <СheckboxTree
                                ref={this.routesData}
                                value={this.state.routes}
                                routesPublic={this.state.routesPublic}
                                dataKey="route"
                            />
                        </CardBody>

                        <CardBody className="checkbox-tree-subscription">
                            <CardHeader>
                                <h4 className="card-title mb-0">
                                    Отключать доступ по окончании подписки
                                </h4>
                            </CardHeader>

                            <СheckboxTreeClone
                                ref={this.routesSubscriptionData}
                                value={this.state.routesSubscription}
                                routesPublic={true}
                                dataKey="routeSubscription"
                            />
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default NewsForm
