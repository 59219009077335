import React, { Component } from 'react'
import {
    AvGroup,
    AvInput,
    AvFeedback,
    AvField,
} from 'availity-reactstrap-validation'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
    InputGroupAddon,
} from 'reactstrap'
import { Editor } from 'react-draft-wysiwyg'
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

class AvMultipleText extends Component {
    constructor(props) {
        super(props)

        this.state = {
            firstLoad: true,
            value: this.splitText(this.props.value),
            options: this.props.options,
            errorMessage: !!this.props.options.errorMessage,
        }

        this.addInput = this.addInput.bind(this)
    }

    componentDidMount() {
        const value = this.splitText(this.props.value)

        value.map((val, i) => {
            value[i] = this.getEditorContent(val)
        })

        this.setState({ value: value })
        this.setState({ options: this.props.options })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.splitText(prevProps.value).length === 1 &&
            prevProps.value !== this.props.value &&
            this.state.firstLoad
        ) {
            const value = this.splitText(this.props.value)

            value.map((val, i) => {
                if (value[i]) {
                    if (typeof val === 'string') {
                        value[i] = EditorState.moveFocusToEnd(
                            this.getEditorContent(val)
                        )
                    } else {
                        value[i] = val
                    }
                }
            })

            this.setState({ value: value, firstLoad: false })
        }

        if (
            this.splitText(prevProps.value).length !==
            this.splitText(this.props.value).length
        ) {
            const value = this.splitText(this.props.value)

            value.map((val, i) => {
                if (value[i]) {
                    if (typeof val === 'string')
                        value[i] = this.getEditorContent(val)
                    else value[i] = val
                }
            })

            this.setState({ value: value })
        }
    }

    addInput() {
        this.state.value.push(
            EditorState.createWithContent(ContentState.createFromText(' '))
        )
        this.setState({ value: this.state.value })
    }

    deleteInput(index) {
        if (!index && this.state.errorMessage) return

        delete this.state.value[index]

        const data = this.state.value.filter(function (el) {
            return el != null
        })

        this.joinAndSetData(data)
        this.setState({ value: this.state.value })
    }

    onEditorStateChange(index, editorState) {
        if (typeof this.state.value[index] !== 'undefined') {
            this.state.value[index] = editorState
            this.joinAndSetData(this.state.value)
        }
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        // EditorState.moveFocusToEnd(EditorState.createWithContent(contentState));
        return EditorState.createWithContent(contentState)
    }

    splitText(text) {
        return typeof text === 'object' ? text : text.split('<---=--->')
    }

    joinAndSetData(editor) {
        return this.props.updateDataText(
            editor
                .map(function (val) {
                    return draftToHtml(convertToRaw(val.getCurrentContent()))
                        .replace('<p></p>', '')
                        .replace('\n', '')
                })
                .join('<---=--->')
        )
    }

    render() {
        const pattern = this.state.options.pattern
            ? this.state.options.pattern.value
            : ''
        let errorMessage = this.state.options.errorMessage

        if (pattern && this.state.options.pattern.errorMessage) {
            errorMessage = this.state.options.pattern.errorMessage
        }

        return (
            <React.Fragment>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">
                            {this.state.options.label}
                        </h4>
                    </CardHeader>

                    {this.state.value.map((val, i) => {
                        return (
                            <CardBody key={i}>
                                <Card>
                                    <Editor
                                        editorState={val}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={this.onEditorStateChange.bind(
                                            this,
                                            i
                                        )}
                                    />
                                    {i !== 0 || !this.state.errorMessage ? (
                                        <CardBody>
                                            <InputGroupAddon
                                                addonType="append"
                                                onClick={this.deleteInput.bind(
                                                    this,
                                                    i
                                                )}
                                            >
                                                <div className="btn btn-primary">
                                                    <i className="mdi mdi-close" />{' '}
                                                    Удалить
                                                </div>
                                            </InputGroupAddon>
                                        </CardBody>
                                    ) : null}

                                    <AvField
                                        name={
                                            this.state.options.name +
                                            '[' +
                                            i +
                                            ']'
                                        }
                                        data-index={i}
                                        type="hidden"
                                        value={
                                            typeof val !== 'string'
                                                ? draftToHtml(
                                                      convertToRaw(
                                                          val.getCurrentContent()
                                                      )
                                                  ).replace('<p></p>', '')
                                                : ''
                                        }
                                        validate={this.state.options.validate}
                                    />
                                </Card>
                            </CardBody>
                        )
                    })}

                    <AvGroup>
                        <div className="icon-demo-content row">
                            <div
                                className="col-xl-3 col-lg-4 col-sm-6"
                                onClick={this.addInput}
                            >
                                <i className="mdi mdi-plus" />
                                <span>Добавить</span>
                            </div>
                        </div>
                    </AvGroup>
                </Card>
            </React.Fragment>
        )
    }
}

AvMultipleText.defaultProps = {
    value: ' ',
    options: {
        validate: {},
        name: 'name',
        errorMessage: false,
        pattern: false,
    },
}

export default AvMultipleText
