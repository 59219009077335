import React, { Component } from 'react'
import { object } from 'prop-types'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookColorForm from '../HandbookColorForm'

class ColorsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Цвета', link: '/handbooks/colors' },
                {
                    title: 'Редактировать цвет',
                    link: '/handbooks/colors/update',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование цвета"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookColorForm
                            title="Редактирование цвета"
                            method="post"
                            api="/handbook/colors/update"
                            redirect="/handbooks/colors"
                            one="/handbook/colors/one"
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

ColorsUpdate.propTypes = {
    history: object,
    match: object,
}

export default ColorsUpdate
