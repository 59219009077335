import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    Button,
} from 'reactstrap'
import AvSelect from '../../components/Common/AvSelect'
import GraphsDataTable from '../../components/Common/GraphsDataTable'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from 'axios'
import toastr from 'toastr'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

const getFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push({ id: el.id, name: el.name }))
    return ids
}

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
        c
    ) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class GraphsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список графиков', link: '/graphs' },
            ],

            isActive: true,
            name: '',
            type: '',
            data: [],
            data_color: [],
            graphs_name: [],
            names_options: [],
            units: [],
            discharges: [],

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.addInputSignNames = this.addInputSignNames.bind(this)
        this.graphsData = React.createRef()
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios.get(`/graphs/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data

                this.setState({
                    isActive: data.active,
                    name: data.name,
                    type: [{ id: data.type }],
                    graphs_name: data.graphs_name,
                    data: data.data,
                    data_color: data.data_color,
                    units: [data.units],
                    discharges: [data.discharges],
                })
            })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            // console.log(fd);return;
            const url = this.state.isUpdate
                ? '/graphs/update'
                : '/graphs/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/graphs')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    const errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const graphsData = this.graphsData.current.getGraphsData()

        const data = {
            createdBy: jsonUser.userId,
            name: this.state.name,
            name_public: getFromSelect(this.state.graphs_name),
            data: graphsData.data,
            data_color: graphsData.color,
            type: this.state.type[0].id,
            active: this.state.isActive,
            units: getIdFromSelect(this.state.units)[0],
            discharges: getIdFromSelect(this.state.discharges)[0],
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId
        }

        return data
    }

    addInputSignNames(event) {
        event.preventDefault()

        const target = event.target
        const value = target.value
        const id = uuidv4()

        this.state.graphs_name.push({ id: id, name: value })

        this.setState({
            graphs_name: this.state.graphs_name,
            names_options: [{ id: id, name: value }],
        })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="associations"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название"
                                name="name"
                                placeholder="Введите название"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <Card>
                                <AvSelect
                                    label="Наименование для публичной части"
                                    name="graphs_name"
                                    value={this.state.graphs_name}
                                    optionsNew={this.state.names_options}
                                    api="/handbook/graph_name/input_list"
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                    multiple
                                />
                                <FormGroup>
                                    <Row>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value="."
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (точка)
                                        </Button>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value=","
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (запятая)
                                        </Button>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value="-"
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (тире)
                                        </Button>
                                        <Button
                                            onClick={this.addInputSignNames}
                                            value=":"
                                            color="info"
                                            style={{ margin: '10px' }}
                                        >
                                            (двоеточие)
                                        </Button>
                                    </Row>
                                </FormGroup>
                            </Card>

                            <AvSelect
                                label="Тип графика"
                                name="type"
                                value={this.state.type}
                                api="/graphs/type_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvSelect
                                label="Разряды"
                                name="discharges"
                                value={this.state.discharges}
                                api="/handbook/discharges/input_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />

                            <AvSelect
                                label="Единица измерения"
                                name="units"
                                value={this.state.units}
                                api="/handbook/units/input_list"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">
                                Заполнение данных для построения графика
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <GraphsDataTable
                                ref={this.graphsData}
                                value={this.state.data}
                                color={this.state.data_color}
                                type={this.state.type || [{ id: null }]}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default GraphsForm
