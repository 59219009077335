import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import NewsFormEn from './NewsFormEn'

class NewsEditEn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список новостей (EN)', link: '/news_en' },
                {
                    title: 'Редактировать новость (EN)',
                    link: '/news_en/update',
                },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        const data = {
            columns: [],
            rows: [],
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование новости (EN)"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <NewsFormEn
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default NewsEditEn
