import React, { Component } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Progress,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import user from './../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LocalizationElementField from '../../components/Common/LocalizationElementField'

class HandbookColorForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: true,
            name: '',
            isUpdate: this.props.id,
            color: '',
            sort: 500,
        }

        if (
            typeof this.props.fields !== 'undefined' &&
            this.props.fields.length > 0
        ) {
            this.props.fields.forEach((element) => {
                this.state[element.name] = ''
            })
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name,
                        color: data.color,
                        sort: data.sort,
                    })
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!errors.length) {
            const data = {
                createdBy: user.userId,
                name: this.state.values.name,
                active: this.state.isActive,
                color: this.state.color,
                sort: this.state.sort,
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: data,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push(this.props.redirect)
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 422 &&
                        err.response.data.errors
                    ) {
                        const errors = err.response.data.errors
                        for (var key in errors) {
                            toastr.error(`<b>` + errors[key] + `</b>`)
                        }
                    }
                    console.log(err.message)
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    className="needs-validation"
                    name="handbookForm"
                    id="handbook-form"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <Card>
                        <div
                            id="basic-pills-wizard"
                            className="twitter-bs-wizard"
                        >
                            <CardHeader>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <h4 className="card-title mb-0">
                                            {this.props.title}
                                        </h4>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="custom-control custom-switch">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активен
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody>
                                <AvField
                                    label="Наименование"
                                    name="name"
                                    placeholder="Введите наименование"
                                    value={this.state.name}
                                    onInput={this.handleInputChange}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage:
                                                'Обязательно для заполнения',
                                        },
                                    }}
                                />

                                <AvField
                                    label="Цвет"
                                    name="color"
                                    type="color"
                                    placeholder="Введите цвет"
                                    value={this.state.color}
                                    onInput={this.handleInputChange}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage:
                                                'Обязательно для заполнения',
                                        },
                                    }}
                                />

                                <AvField
                                    label="Сортировка"
                                    name="sort"
                                    placeholder="Введите сортировку"
                                    value={this.state.sort}
                                    onInput={this.handleInputChange}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage:
                                                'Обязательно для заполнения',
                                        },
                                        pattern: {
                                            value: '^[0-9]+$',
                                            errorMessage:
                                                'Неверный формат, только цифры',
                                        },
                                    }}
                                />
                            </CardBody>

                            <CardFooter>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default HandbookColorForm
