import {
    SET_ENTRIES_QUANTITY,
    SET_PAGE,
    SET_SEARCH,
    SET_MAX,
    SET_SORT,
    IS_NEED_REFRESH,
} from './actions'

const initialState = {
    page: 1,
    entryQuantity: 20,
    search: '',
    maxCount: 1,
    currentSortBy: 'date_publication',
    currentSortOrder: 'desc',
    date_create: 'desc',
    date_update: 'desc',
    date_publication: 'desc',
    user_update: 'desc',
    name_ru: 'desc',
    active: 'desc',
    isNeedRefresh: true,
}

export const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
            }
        case SET_ENTRIES_QUANTITY:
            return {
                ...state,
                entryQuantity: action.payload,
            }
        case SET_MAX:
            return {
                ...state,
                maxCount: action.payload,
            }
        case SET_SORT:
            return {
                ...state,
                ...action.payload,
            }
        case IS_NEED_REFRESH:
            return {
                ...state,
                isNeedRefresh: action.payload,
            }
        default:
            return state
    }
}
