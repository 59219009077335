import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Row,
    Col,
    CardBody,
    Card,
    Breadcrumb,
    BreadcrumbItem,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import user from './../../store/user'

class LocalizationElementField extends Component {
    constructor(props) {
        super(props)

        this.state = {
            langList: [],
            langElement: [],
            elementId: this.props.elementId || '',
            module: this.props.module || '',
            field: this.props.field || 'name',
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.getLangList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.langElement === this.props.langElement) {
            this.props.updateData(this.getData())
        }
    }

    getLangList() {
        axios
            .get('/localization/lang/list?limit=999&offset=0')
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data
                    this.setState({ langList: data })
                    this.getValueLang()
                }
            })
            .catch((err) => {
                let message
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        case 401:
                            message =
                                'Пользователь с введенными данными не найден'
                            break
                        case 403:
                            message =
                                'Не хватает прав. Обратитесь к администратору'
                            break
                        default:
                            message = err[1]
                            break
                    }
                    //window.location.href = '/logout'
                }
                console.log(message)
            })
    }

    getValueLang() {
        if (this.props.elementId) {
            axios
                .get(
                    '/localization/element/list?elementId=' +
                        this.props.elementId +
                        '&module=' +
                        this.props.module
                )
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data.data

                        for (let i = 0; i < data.length; i++) {
                            this.setState({
                                [data[i].lang.id]: data[i],
                            })
                        }
                    }
                })
                .catch((err) => {
                    let message
                    if (err.response && err.response.status) {
                        switch (err.response.status) {
                            case 401:
                                message =
                                    'Пользователь с введенными данными не найден'
                                break
                            case 403:
                                message =
                                    'Не хватает прав. Обратитесь к администратору'
                                break
                            default:
                                message = err[1]
                                break
                        }
                        window.location.href = '/logout'
                    }
                    throw message
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
    }

    getData() {
        const result = []
        const inputList = document.querySelectorAll('.js-lang-input')

        for (var i = 0; i < inputList.length; i++) {
            const input = inputList[i]

            const data = {
                lang: input.getAttribute('langid'),
                name: input.value,
                id: input.getAttribute('elementid'),
                elementId: this.state.elementId,
                createdBy: user.userId,
                module: this.state.module,
                field: this.state.field,
            }

            result.push(data)
        }

        return result
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        {this.state.langList.map((item, i) => {
                            let value = ''
                            let elementId = ''

                            if (
                                !this.props.elementId &&
                                item.code === this.props.defaultLang
                            ) {
                                value = this.props.defaultValue
                            }

                            if (this.state[item.id]) {
                                value = this.state[item.id].name
                                elementId = this.state[item.id].id
                            }

                            return (
                                <AvField
                                    label={'Наименование (' + item.name + ')'}
                                    name={'lang[' + item.code + ']'}
                                    key={i}
                                    langid={item.id}
                                    elementid={elementId}
                                    className="js-lang-input"
                                    placeholder="Введите наименование"
                                    value={value}
                                    onInput={this.handleInputChange}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage:
                                                'Обязательно для заполнения',
                                        },
                                    }}
                                />
                            )
                        })}
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

export default LocalizationElementField
