import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const AppRoute = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) => {
            if (isAuthProtected && !localStorage.getItem('authUser')) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }}
    />
)
AppRoute.propTypes = {
    component: PropTypes.instanceOf(Object),
    layout: PropTypes.instanceOf(Object),
    isAuthProtected: PropTypes.bool,
    location: PropTypes.object,
}

export default AppRoute
