import Products from '../pages/Products/index'
import ProductsAdd from '../pages/Products/add'
import ProductsEdit from '../pages/Products/edit'

const productsRoutes = [
    { path: '/products/update/:id', component: ProductsEdit },
    { path: '/products/create', component: ProductsAdd },
    { path: '/products', component: Products },
]

export { productsRoutes }
