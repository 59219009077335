import axios from 'axios'

export async function getList(
    type,
    page = 1,
    pageSize,
    search,
    sortBy,
    orderBy
) {
    return await axios.get(
        getListLink(type, page, pageSize, search, sortBy, orderBy)
    )
}

export function getListLink(type, page = 1, pageSize, search, sortBy, orderBy) {
    let link = `/handbook/${type}/list`
    const params = []
    if (page > 1) {
        params.push(`page=${page}`)
    }

    if (pageSize && pageSize > 0) {
        params.push(`quantity=${pageSize}`)
    }

    if (search) {
        params.push(`search=${search}`)
    }

    if (orderBy) {
        params.push(`order_by=${orderBy}`)
    }

    if (sortBy) {
        params.push(`sort_by=${sortBy}`)
    }

    if (params.length > 0) {
        link = link + '?' + params.join('&')
    }

    return link
}

export function getCreateLink(type) {
    return `/handbooks/${type}/create`
}

export function getUpdateLink(type, id) {
    return `/handbooks/${type}/update/${id}`
}

// id should be send in post request body
// warning route is `handbook` not `handbooks`
export function getDeleteLink(type) {
    return `/handbook/${type}/delete`
}
