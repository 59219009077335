import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Progress,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import AvSelect from '../../components/Common/AvSelect'

import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import user from './../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LocalizationElementField from '../../components/Common/LocalizationElementField'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []

    if (!Array.isArray(arr)) {
        arr = [arr]
    }

    arr.map((el) => ids.push(el.id))
    return ids
}

class HandbookFormRelated extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: true,
            name: '',
            isUpdate: this.props.id,
            valueLang: '',

            activeTab: 1,
            activeTabProgress: 1,
            langElement: null,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.toggleTab.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        name: data.name,
                        [this.props.select.name]: data[this.props.select.name],
                    })
                })
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab,
                })
            }
        }
    }

    updateData = (value) => {
        this.setState({ langElement: value })
    }

    errorTab(errors) {
        let pos = 0

        errors.forEach((element) => {
            pos = element.indexOf('lang')

            if (pos == -1) {
                this.toggleTab(1)
                return
            } else {
                this.toggleTab(2)
                return
            }
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        if (name === 'name') {
            this.setState({
                valueLang: value,
            })
        }

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })
        this.errorTab(errors)

        if (!errors.length) {
            const data = {
                createdBy: user.userId,
                name: this.state.values.name,
                active: this.state.isActive,
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            if (this.state.langElement) {
                data['lang'] = this.state.langElement
            }

            data[this.props.select.name] = getIdFromSelect(
                this.state[this.props.select.name]
            )[0]

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: data,
                })
                .then((response) => {
                    if (response.status === 200) {
                        toastr.success(
                            `<b>${this.state.values.name}</b> успешно!`
                        )
                        this.props.history.push(this.props.redirect)
                    }
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    className="needs-validation"
                    id="purchaser"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <Card>
                        <div
                            id="basic-pills-wizard"
                            className="twitter-bs-wizard"
                        >
                            <CardHeader>
                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li
                                        className={
                                            this.state.activeTab === 1
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(1)
                                            }}
                                        >
                                            Поля
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.state.activeTab === 2
                                                ? 'disabled'
                                                : ''
                                        }
                                        style={{ margin: '10px' }}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.toggleTab(2)
                                            }}
                                        >
                                            Локализация
                                        </Link>
                                    </li>
                                </ul>
                            </CardHeader>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={1}>
                                    <CardHeader>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h4 className="card-title mb-0">
                                                    {this.props.title}
                                                </h4>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="custom-control custom-switch">
                                                    <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        name="isActive"
                                                        id="isActive"
                                                        checked={
                                                            this.state.isActive
                                                        }
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        htmlFor="isActive"
                                                    >
                                                        Активен
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <AvField
                                                    label="Наименование"
                                                    name="name"
                                                    placeholder="Введите наименование"
                                                    onInput={
                                                        this.handleInputChange
                                                    }
                                                    value={this.state.name}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage:
                                                                'Обязательно для заполнения',
                                                        },
                                                    }}
                                                />
                                            </Col>

                                            <Col>
                                                <AvSelect
                                                    options={
                                                        this.props.select
                                                            .options
                                                    }
                                                    label={
                                                        this.props.select.label
                                                    }
                                                    placeholder={
                                                        this.props.select
                                                            .placeholder
                                                    }
                                                    errorMessage={
                                                        this.props.select
                                                            .errorMessage
                                                    }
                                                    value={
                                                        this.state[
                                                            this.props.select
                                                                .name
                                                        ]
                                                    }
                                                    handleSelectChange={
                                                        this.handleSelectChange
                                                    }
                                                    name={
                                                        this.props.select.name
                                                    }
                                                    typeValueMulti="one"
                                                    api={this.props.select.api}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </TabPane>

                                <TabPane tabId={2}>
                                    <CardBody>
                                        <LocalizationElementField
                                            elementId={this.props.id}
                                            updateData={this.updateData}
                                            module="handbook"
                                            field="name"
                                            defaultLang="ru"
                                            defaultValue={this.state.valueLang}
                                            langElement={this.state.langElement}
                                        />
                                    </CardBody>
                                </TabPane>
                            </TabContent>

                            <CardFooter>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default HandbookFormRelated
