import Scheduler from '../pages/Scheduler/index'
import SchedulerAdd from '../pages/Scheduler/add'
import PSchedulerEdit from '../pages/Scheduler/edit'

const schedulerRoutes = [
    { path: '/scheduler/update/:id', component: PSchedulerEdit },
    { path: '/scheduler/create', component: SchedulerAdd },
    { path: '/scheduler', component: Scheduler },
]

export { schedulerRoutes }
