import React, { useState } from 'react'
import axios from 'axios'
import { FormGroup, CustomFileInput, Label, Spinner } from 'reactstrap'

export const ImportObjectsForm = () => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [importErrors, setImportErrors] = useState([])
    const [responseResult, setResponseResult] = useState(null)

    const onFileInputChange = async (e) => {
        setErrors([])
        setImportErrors([])
        setResponseResult(null)

        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        e.target.value = null
        let response
        setLoading(true)
        try {
            response = await axios.post('/objects/import', formData)
        } catch (err) {
            setErrors([err.response.data.message])
            setResponseResult(null)
        }
        if (response && response.data && response.data.errors) {
            setImportErrors([...response.data.errors])
            setResponseResult({
                result: response.data.data,
                parsed: response.data.totalParsed,
                persisted: response.data.persisted,
            })
        }
        setLoading(false)
        // console.log(response);
    }
    return (
        <>
            <FormGroup className={'upload-files'}>
                {loading && <Spinner color="primary" />}
                {responseResult && renderResponseResult(responseResult)}
                {renderResponseErrors(errors)}
                {importErrors.length > 0 && <p>Ошибки при обработке файла</p>}
                {renderImportErrors(importErrors)}
                <Label htmlFor="file">Импорт файла</Label>
                <div className="custom-control">
                    <CustomFileInput
                        onChange={onFileInputChange}
                        className="custom-file-input"
                        multiple={false}
                        name="file"
                        id="file"
                    />
                    <Label className="custom-file-label" htmlFor="customFile">
                        Выберите файл
                    </Label>
                </div>
            </FormGroup>
        </>
    )
}

const renderResponseResult = (responseResult) => {
    return (
        <>
            <p>Результат обработки файла</p>
            <ul className="result">
                <li>
                    Всего обработано строк с инвестплощадками{' '}
                    {responseResult.parsed}
                </li>
                <li>Добавлено инвестплощадок {responseResult.persisted}</li>
            </ul>
        </>
    )
}

const renderResponseErrors = (errors) => {
    if (!errors.length) {
        return
    }
    return (
        <>
            <p>Ошибки при загрузке файла</p>
            <ul className={'error'}>
                {errors.map((error, i) => (
                    <li key={i}>{error}</li>
                ))}
            </ul>
        </>
    )
}

const renderImportErrors = (errors, errorsKey = [], showTitle = true) => {
    if (!errors.length) {
        return
    }
    const errorSection = errors.map((error, key) => {
        let title
        if (showTitle && typeof error === 'object') {
            for (const j in error) {
                title = j
                break
            }
        }
        if (Array.isArray(error)) {
            return error.map((err, i) => {
                return (
                    <li key={i}>
                        {errorsKey[key].length
                            ? '[' + errorsKey[key] + ']:'
                            : ''}
                        {err}
                    </li>
                )
            })
        }
        return (
            <li key={key}>
                <span className="error-title">{title}</span> {title && <br />}
                {typeof error === 'object'
                    ? renderImportErrors(
                          Object.values(error),
                          Object.keys(error),
                          false
                      )
                    : error}
            </li>
        )
    })
    return <ul className={'error'}>{errorSection}</ul>
}
