import TradePartners from '../pages/TradePartners/index'
import TradePartnersAdd from '../pages/TradePartners/add'
import TradePartnersEdit from '../pages/TradePartners/edit'

const tradePartnersRoutes = [
    { path: '/trade_partners/update/:id', component: TradePartnersEdit },
    { path: '/trade_partners/create', component: TradePartnersAdd },
    { path: '/trade_partners', component: TradePartners },
]

export { tradePartnersRoutes }
