import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import ExpertsForm from './ExpertsForm'

class ExpertsEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список экспертов', link: '/experts' },
                {
                    title: 'Редактировать эксперта',
                    link: '/experts/update',
                },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование эксперта"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <ExpertsForm
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ExpertsEdit
