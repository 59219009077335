import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import RoleForm from './RoleForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class RoleCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список ролей', link: '/user_role' },
                { title: 'Создать роль', link: '/user_role/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новая роль"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <RoleForm
                            title="Создание"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RoleCreate
