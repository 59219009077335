import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import RoleGroupForm from './RoleGroupForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class RoleGroupUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список групп', link: '/user_role_group' },
                {
                    title: 'Редактировать группу',
                    link: '/user_role_group/update',
                },
            ],

            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        const data = {
            columns: [],
            rows: [],
            group: this.state.group || {},
            groupName: this.state.group || { name: '' },
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование группы"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <RoleGroupForm
                            title={
                                'Редактирование группы: ' + data.groupName.name
                            }
                            id={this.state.id}
                            data={data.group}
                            typeForm="edit"
                            history={this.props.history}
                            method="post"
                            api="/role_group/update"
                            redirect="/user_role_group"
                            one="/role_group/one"
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RoleGroupUpdate
