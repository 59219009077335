import Events from '../pages/Events/index'
import EventsAdd from '../pages/Events/add'
import EventsEdit from '../pages/Events/edit'

const eventsRoutes = [
    { path: '/events/update/:id', component: EventsEdit },
    { path: '/events/create', component: EventsAdd },
    { path: '/events', component: Events },
]

export { eventsRoutes }
