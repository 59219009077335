import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookLangAndFileForm from '../HandbookLangAndFileForm'

class ExpertsBusinessSizeUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Размер бизнеса',
                    link: '/handbooks/experts_business_size',
                },
                {
                    title: 'Редактировать размер бизнеса',
                    link: '/handbooks/experts_business_size/update',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookLangAndFileForm
                            title="Редактирование"
                            method="post"
                            api="/handbook/experts_business_size/update"
                            redirect="/handbooks/experts_business_size"
                            one="/handbook/experts_business_size/one"
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ExpertsBusinessSizeUpdate
