import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookForm from '../HandbookForm'

class PaymentCurrencyCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Валюты платежа', link: '/handbooks/payment_currency' },
                {
                    title: 'Добавить валюту',
                    link: '/handbooks/payment_currency/create',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Валюта"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookForm
                            title="Создание валюты"
                            method="post"
                            api="/handbook/payment_currency/create"
                            redirect="/handbooks/payment_currency"
                            fields={this.state.fields}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentCurrencyCreate
