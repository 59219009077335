import Graphs from '../pages/Graphs/index'
import GraphsAdd from '../pages/Graphs/add'
import GraphsEdit from '../pages/Graphs/edit'

const graphsRoutes = [
    { path: '/graphs/update/:id', component: GraphsEdit },
    { path: '/graphs/create', component: GraphsAdd },
    { path: '/graphs', component: Graphs },
]

export { graphsRoutes }
