import React, {Component} from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  Label,
  Row
} from 'reactstrap';
import {isEmpty, formatValueToUrlParam, formatValue} from "../../utils/utils";
import AvSelectV2 from "../../components/Common/AvSelectV2";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import user from '../../store/user';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
      name: '',
      code_name: '',
      filter_fields: [],
      isUpdate: this.props.id,
      errorMessage: !!"Обязательно для заполнения",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputGroupChange = this.handleInputGroupChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
  }

  componentWillMount() {
    if (this.state.isUpdate) {
      axios.get(`/module-filters/one?id=${this.props.id}`).then((response) => {
        const data = response.data.data;

        this.setState({
          isActive: data.active,
          name: data.name,
          code_name: data.code_name,
          filter_fields: data.filter_fields || [],
        });
      });
    } else {
      this.addGroup();
    }
  }

  /**
   * Обработчик изменения инпута.
   *
   * @param event
   */
  handleInputChange(event) {
    const target = event.target;
    const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

  /**
   * Обрабочтик изменения input группы.
   *
   * @param event
   */
  handleInputGroupChange(event) {
    const target = event.target;

    this.state.filter_fields[target.dataset.index][target.name] = target.value;
    this.setState({filter_fields: this.state.filter_fields});
  }

  /**
   * Обработчик изменения select.
   *
   * @param props
   */
  handleSelectChange(props) {
    let key = props['name'];
    let value = props['value'];
    let dataName = props['data-name'];
    let index = props['data-index'];

    if (!isEmpty(dataName) && typeof index !== "boolean") {
      this.state[dataName][index][key] = value;
      this.setState({[dataName]: this.state[dataName]});
    } else {
      this.state[key] = value;
      this.setState({key: value});
    }
  }

  /**
   * Обработчик отправки формы.
   *
   * @param event
   * @param errors
   * @param values
   */
  handleSubmit(event, errors, values) {
    this.setState({errors, values});

    if (!this.state.errors.length) {
      const fd = this.getFormData();
      const url = this.state.isUpdate ? '/module-filters/update' : '/module-filters/create';

      axios.post(url, fd).then(response => {
        if (response.status === 200) {
          this.props.history.push('/module-filters');
        }
      }).catch(err => {
        if (err.response.status === 409 && err.response.data.message == 'unique') {
          toastr.error(`<b>Кодовое имя модуля должно быть уникальным</b>`);
        }else if (err.response.data.message) {
          toastr.error(`<b>` + err.response.data.message + `</b>`);
        }
        let errors = err.response.data.errors;
        for (var key in errors) {
          toastr.error(`<b>` + key + ':' + errors[key] + `</b>`);
        }
        console.log(err.message)
      });
    }
  };

  /**
   * Добавляет элементы формы в группу.
   */
  addGroup() {
    this.state.filter_fields.push(
        {
          name: '',
          type: [],
          field_type: [],
          field_name: '',
          url: '',
        }
    );

    this.setState({filter_fields: this.state.filter_fields});
  }

  /**
   * Удаляет группу элементов формы.
   *
   * @param i индекс группы
   */
  deleteGroup(i) {
    this.state.filter_fields.splice(i, 1);
    this.setState({filter_fields: this.state.filter_fields});
  }

  /**
   * Возвращает данные формы для отправки запроса.
   *
   * @returns {{country, filter_fields: ([]|[string]|*), name: string, active}}
   */
  getFormData() {
    let filter_fields = [];
    this.state.filter_fields.forEach((e, i) => {
      filter_fields[i] = {
        name: '',
        field_name: '',
        type: [],
        field_type: [],
        url: '',
        data: [],
      };

      filter_fields[i]['name'] = e.name;
      filter_fields[i]['field_name'] = e.field_name;
      filter_fields[i]['url'] = e.url;
      filter_fields[i]['data'] = e.data;

      if (typeof e.type === 'string') {
        filter_fields[i]['type'] = e.type;
      } else {
        filter_fields[i]['type'] = e.type[0]['id'];
      }

      if (typeof e.field_type === 'string') {
        filter_fields[i]['field_type'] = e.field_type;
      } else {
        filter_fields[i]['field_type'] = e.field_type[0]['id'];
      }

      let getSelectValue = (variable) => {
        if (!isEmpty(e[variable])) {
          e[variable].forEach((e) => {
            if (e !== null) {
              if (typeof e === "object") {
                filter_fields[i][variable].push(e.id);
              } else {
                filter_fields[i][variable].push(e);
              }
            }
          });
        } else {
          e[variable] = [];
        }
      }

      //getSelectValue('');
    });

    const data = {
      active: this.state.isActive,
      name: this.state.name,
      code_name:  this.state.code_name,
      filter_fields: filter_fields,
    };

    if (this.state.isUpdate) {
      data.id = this.props.id;
      data.updatedBy = user.userId;
    }

    console.log(data);

    return data;
  }

  render() {
    return (
      <React.Fragment>
        <AvForm onSubmit={this.handleSubmit} ref="form" id="working_days">
          <Card>
            <CardHeader>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="card-title mb-0">{this.props.title}</h4>
                </div>
                <div className="flex-shrink-0">
                  <div className="custom-control custom-switch">
                    <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="isActive"
                        id="isActive"
                        checked={this.state.isActive}
                        onChange={this.handleInputChange}
                      />
                      <Label className="custom-control-label" htmlFor="isActive">
                        Активен
                      </Label>
                  </div>
                </div>
              </div>
            </CardHeader>

            <CardBody>
              <AvField
                label="Наименование модуля"
                name="name"
                placeholder="Введите значение"
                value={this.state.name}
                onInput={this.handleInputChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Обязательно для заполнения'
                  }
                }}
              />

              <AvField
                  label="Кодовое имя модуля"
                  name="code_name"
                  placeholder="Введите значение"
                  value={this.state.code_name}
                  onInput={this.handleInputChange}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Обязательно для заполнения'
                    },
                    pattern: {
                      value: '/^[a-zA-Z0-9_]+$/i',
                      errorMessage: 'Неверный формат кодового имени модуля'
                    }
                  }}
              />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <AvGroup>
                {this.state.filter_fields.map((val, i) =>
                    {
                      return (
                          <div key={i} style={{borderBottom: "1px solid #efefef", marginTop: "10px"}}>
                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvField
                                    label="Наименование фильтра"
                                    name='name'
                                    data-index={i}
                                    placeholder="Введите значение"
                                    value={val.name}
                                    onInput={this.handleInputGroupChange}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Обязательно для заполнения'
                                      }
                                    }}
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvField
                                    label="Наименование поля"
                                    name='field_name'
                                    data-index={i}
                                    placeholder="Введите значение"
                                    value={val.field_name}
                                    onInput={this.handleInputGroupChange}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Обязательно для заполнения'
                                      },
                                      pattern: {
                                        value: '/^[a-zA-Z0-9_]+$/i',
                                        errorMessage: 'Неверный формат наименования поля'
                                      }
                                    }}
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvSelectV2
                                    label="Тип фильтра"
                                    name='type'
                                    data-name='filter_fields'
                                    data-index={i}
                                    data-key='id'
                                    value={formatValue(val.type)}
                                    api={'/module-filters/input-list-filter-types'}
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvSelectV2
                                    label="Тип поля"
                                    name='field_type'
                                    data-name='filter_fields'
                                    data-index={i}
                                    data-key='id'
                                    value={formatValue(val.field_type)}
                                    api={'/module-filters/input-list-filter-field-types'}
                                    handleSelectChange={this.handleSelectChange}
                                    errorMessage="Обязательно для заполнения"
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvField
                                    label="Ендпоинт(URL) данных"
                                    name='url'
                                    data-index={i}
                                    placeholder="Введите значение"
                                    value={val.url}
                                    onInput={this.handleInputGroupChange}
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                <AvField
                                    label="Данные"
                                    name='data'
                                    data-index={i}
                                    placeholder="Введите значение"
                                    value={val.data}
                                    onInput={this.handleInputGroupChange}
                                />
                              </Col>
                            </Row>

                            <Row style={{width: '100%'}}>
                              <Col>
                                {i != 0 || !this.state.errorMessage ? (
                                    <FormGroup>
                                      <Label htmlFor={'Удалить'}>
                                        {'Удалить'}
                                      </Label>
                                      <InputGroupAddon addonType="append" onClick={this.deleteGroup.bind(this,i)}>
                                        <div className="btn btn-primary" style={{zIndex: 0}}><i className="mdi mdi-close"></i></div>
                                      </InputGroupAddon>
                                    </FormGroup>
                                ) : (null)
                                }
                              </Col>
                            </Row>
                          </div>
                      )
                    }

                )}
                <div className="icon-demo-content row">
                  <div className="col-xl-3 col-lg-4 col-sm-6" onClick={this.addGroup}>
                    <i className="mdi mdi-plus"></i>
                    <span>Добавить</span>
                  </div>
                </div>
              </AvGroup>
            </CardBody>
          </Card>

          <Card>
            <CardFooter>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1" />
                <div className="flex-shrink-0">
                  <Button
                    color="success"
                    className="waves-effect waves-light"
                  >
                    Сохранить <i className="ri-check-line align-middle ml-1"></i>
                  </Button>
                </div>
              </div>
            </CardFooter>
          </Card>
        </AvForm>
      </React.Fragment>
    )
  }
}

export default Form
