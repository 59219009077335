import ModuleFilters from '../pages/ModuleFilters/index'
import ModuleFiltersAdd from '../pages/ModuleFilters/create'
import ModuleFiltersEdit from '../pages/ModuleFilters/update'

const moduleFiltersRoutes = [
    { path: '/module-filters/update/:id', component: ModuleFiltersEdit },
    { path: '/module-filters/create', component: ModuleFiltersAdd },
    { path: '/module-filters', component: ModuleFilters },
]

export { moduleFiltersRoutes }
