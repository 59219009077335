import React, { Component, createRef } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
    InputGroup,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../../components/Common/AvSelect'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { Editor } from 'react-draft-wysiwyg'
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateFromHTML } from 'draft-js-import-html'
import { renameToSelect } from '../../../helpers/helpers'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import toastr from 'toastr'
import user from '../../../store/user'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

class SubscriptionForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: true,
            nameRu: '',
            nameEn: '',
            code: '',
            country: [],
            industry: [],
            specialization: [],
            commentRu: EditorState.createEmpty(),
            commentEn: EditorState.createEmpty(),

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        isActive: data.active,
                        nameRu: data.name_ru || this.state.nameRu,
                        nameEn: data.name_en || this.state.nameEn,
                        code: data.code || this.state.code,
                        country: data.country || this.state.country,
                        industry: data.industry || this.state.industry,
                        specialization: data.specialization || this.state.specialization,
                        commentRu: this.getEditorContent(data.comment_ru || ''),
                        commentEn: this.getEditorContent(data.comment_en || ''),
                    })
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!errors.length) {
            const data = {
                createdBy: user.userId,
                code: this.state.code,
                nameRu: this.state.nameRu,
                nameEn: this.state.nameEn,
                active: this.state.isActive,
                country: getIdFromSelect(this.state.country),
                industry: getIdFromSelect(this.state.industry),
                specialization: getIdFromSelect(this.state.specialization),
                commentRu: draftToHtml(
                    convertToRaw(this.state.commentRu.getCurrentContent())
                ),
                commentEn: draftToHtml(
                    convertToRaw(this.state.commentEn.getCurrentContent())
                ),
            }

            if (
                typeof this.props.fields !== 'undefined' &&
                this.props.fields.length > 0
            ) {
                this.props.fields.forEach((element) => {
                    data[element.name] = this.state[element.name]
                })
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: data,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/subscription')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    const errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="subscription"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название Рус."
                                name="nameRu"
                                placeholder="Введите название Рус."
                                value={this.state.nameRu}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Название Англ."
                                name="nameEn"
                                placeholder="Введите название Англ."
                                value={this.state.nameEn}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Символьный код"
                                name="code"
                                placeholder="Введите символьный код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z--]{4,255}$',
                                        errorMessage: 'Неверный символьный код',
                                    },
                                }}
                            />

                            <Row>
                                <Col>
                                    <AvSelect
                                        label="Страна"
                                        name="country"
                                        value={this.state.country}
                                        api="/handbook/country/input_list"
                                        handleSelectChange={
                                            this.handleSelectChange
                                        }
                                        errorMessage="Обязательно для заполнения"
                                        multiple
                                    />
                                </Col>
                                <Col>
                                    <AvSelect
                                        label="Отрасль"
                                        name="industry"
                                        value={this.state.industry}
                                        api="/handbook/industry/input_list"
                                        handleSelectChange={
                                            this.handleSelectChange
                                        }
                                        errorMessage="Обязательно для заполнения"
                                        multiple
                                    />
                                </Col>
                                <Col>
                                    <AvSelect
                                        label="Специализация"
                                        name="specialization"
                                        value={this.state.specialization}
                                        api="/handbook/investment_sites_specialization/input_list"
                                        handleSelectChange={
                                            this.handleSelectChange
                                        }
                                        errorMessage="Обязательно для заполнения"
                                        multiple
                                    />
                                </Col>
                            </Row>
                        </CardBody>

                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">
                                    Комментарий (публичный) - рус
                                </h4>
                            </CardHeader>

                            <CardBody>
                                <Editor
                                    editorState={this.state.commentRu}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange.bind(
                                        this,
                                        'commentRu'
                                    )}
                                />

                                <AvField
                                    type="hidden"
                                    name="commentRu"
                                    value={draftToHtml(
                                        convertToRaw(
                                            this.state.commentRu.getCurrentContent()
                                        )
                                    ).replace('<p></p>', '')}
                                />
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">
                                    Комментарий (публичный) - анг
                                </h4>
                            </CardHeader>

                            <CardBody>
                                <Editor
                                    editorState={this.state.commentEn}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange.bind(
                                        this,
                                        'commentEn'
                                    )}
                                />

                                <AvField
                                    type="hidden"
                                    name="commentEn"
                                    value={draftToHtml(
                                        convertToRaw(
                                            this.state.commentEn.getCurrentContent()
                                        )
                                    ).replace('<p></p>', '')}
                                />
                            </CardBody>
                        </Card>

                        <CardFooter>
                            <div className="d-flex align-items-center justify-content-end">
                                <Button
                                    color="success"
                                    className="waves-effect waves-light"
                                >
                                    Сохранить{' '}
                                    <i className="ri-check-line align-middle ml-1"></i>
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default SubscriptionForm
