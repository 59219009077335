import News from '../pages/News/index'
import NewsAdd from '../pages/News/add'
import NewsEdit from '../pages/News/edit'

const newsRoutes = [
    { path: '/news/update/:id', component: NewsEdit },
    { path: '/news/create', component: NewsAdd },
    { path: '/news', component: News },
]

export { newsRoutes }
