import React, { Component } from 'react'
import { MDBDataTable, MDBPagination } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DropdownControls from '../../components/Common/DropdownControls'
import axios from 'axios'
// import { logoutUser } from '../../store/actions'
import ModalComponent from '../../components/Common/Modal'
import { ImportObjectsForm } from './ImportObjectsForm'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './style.css'
import store from '../../store'
import {
    setMax,
    setPage,
    setQuantity,
    setSearch,
    setSort,
    isNeedRefresh,
} from '../../store/objects-page/actionsCreators'
import { PaginationAmountEntityShow } from '../../components/Common/PaginationAmountEntityShow'
import { connect } from 'react-redux'
import { PaginationPageSizeSelect } from '../../components/Common/PaginationPageSizeSelect'
import { SearchWidget } from '../../components/Common/SearchWidget'

// const user = localStorage.getItem('authUser')
// const jsonUser = JSON.parse(user)

class Objects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список объектов', link: '/objects' },
            ],
            isNeedRenerder: false,
            modalIsOpen: false,
            rows: [],
        }
    }

    refreshData = () => {
        const pageStore = store.getState().objectsPageReducer
        axios
            .get(
                `/objects/list?` +
                    `quantity=${pageStore.entryQuantity}&` +
                    `page=${pageStore.page}&` +
                    `search=${pageStore.search}&` +
                    `sort_by=${pageStore.currentSortBy || ''}&` +
                    `sort_order=${pageStore.currentSortOrder || ''}`
            )
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data
                    store.dispatch(setMax(response.data.maxCount))

                    for (let i = 0; i < data.length; i++) {
                        data[i].controls = (
                            <DropdownControls
                                id={data[i].id}
                                redirect="/objects/"
                                history={this.props.history}
                                actions={{
                                    update: `/objects/update/${data[i].id}/`,
                                    delete: '/objects/delete',
                                }}
                            />
                        )

                        if (data[i].active) {
                            data[i].active = 'Да'
                        } else {
                            data[i].active = 'Нет'
                        }
                    }

                    this.setState({ rows: data })
                }
            })
            .catch((err) => {
                // let message;
                // if (err.response && err.response.status) {
                //   switch (err.response.status) {
                //     case 401: message = "Пользователь с введенными данными не найден"; break;
                //     case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
                //     default: message = err[1]; break;
                //   }
                //   window.location.href = '/logout';
                // }
                // throw message;
            })
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        if (store.getState().objectsPageReducer.isNeedRefresh) {
            store.dispatch(isNeedRefresh(false))
            this.refreshData()
        }
    }

    onChangePage = (current) => {
        store.dispatch(setPage(Number(current)))
        store.dispatch(isNeedRefresh(true))
        // this.setState({ isNeedRenerder: true });
    }

    onChangePerPage = (value) => {
        store.dispatch(setQuantity(Number(value)))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
        this.setState({ isNeedRenerder: true })
    }

    onChangeSearch = (value) => {
        store.dispatch(setSearch(value))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Дата создания',
                    field: 'date_create',
                    sort: store.getState().objectsPageReducer.date_create,
                    width: 150,
                },
                {
                    label: 'Дата обновления',
                    field: 'date_update',
                    sort: store.getState().objectsPageReducer.date_update,
                    width: 150,
                },
                {
                    label: 'Наименование',
                    field: 'name_ru',
                    sort: store.getState().objectsPageReducer.name_ru,
                    width: 150,
                },
                {
                    label: 'Активность',
                    field: 'active',
                    sort: store.getState().objectsPageReducer.active,
                    width: 150,
                },
                {
                    label: '',
                    field: 'controls',
                    sort: 'disabled',
                    width: 150,
                },
            ],
            rows: this.state.rows,
        }

        const Table = () => {
            if (this.state.isNeedRenerder) {
                setTimeout(() => {
                    this.setState({ isNeedRenerder: false })
                }, 10)
                return <></>
            }

            return (
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    sortable
                    btn
                    displayEntries={false}
                    searching={false}
                    onSort={(res) => {
                        if (res.direction && res.column) {
                            const data = {
                                currentSortBy: res.column,
                                currentSortOrder: res.direction,
                                [res.column]:
                                    res.direction === 'asc' ? 'desc' : 'asc',
                            }
                            for (const key in store.getState()
                                .objectsPageReducer) {
                                if (
                                    key === 'date' ||
                                    key === 'date_update' ||
                                    key === 'date_create' ||
                                    key === 'name' ||
                                    key === 'active' ||
                                    key === 'user_update'
                                ) {
                                    if (key !== res.column) {
                                        data[key] = ''
                                    }
                                }
                            }
                            store.dispatch(setSort(data))
                            store.dispatch(isNeedRefresh(true))
                        }
                    }}
                    entries={store.getState().objectsPageReducer.entryQuantity}
                    data={data}
                    info={false}
                    paginationLabel={['']}
                />
            )
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список объектов"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Link
                                            to="/objects/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать объект
                                        </Link>
                                        <Button
                                            color="info"
                                            className="waves-effect waves-light"
                                            style={{ marginLeft: '10px' }}
                                            onClick={() =>
                                                this.setState({
                                                    modalIsOpen: !this.state
                                                        .modalIsOpen,
                                                })
                                            }
                                        >
                                            Импорт объектов
                                        </Button>
                                        <ModalComponent
                                            modalTitle="Импорт объектов из excel файла"
                                            isOpen={this.state.modalIsOpen}
                                            handleClose={() =>
                                                this.setState({
                                                    modalIsOpen: false,
                                                })
                                            }
                                        >
                                            <ImportObjectsForm />
                                        </ModalComponent>
                                    </CardHeader>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <PaginationPageSizeSelect
                                                onChangePerPage={
                                                    this.onChangePerPage
                                                }
                                                value={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .entryQuantity
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                margin: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <SearchWidget
                                                value={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .search
                                                }
                                                onChangeSearch={
                                                    this.onChangeSearch
                                                }
                                                placeholder={'search'}
                                            />
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className={'objects-table'}>
                                            {Table()}
                                        </div>
                                        <MDBPagination>
                                            <Pagination
                                                defaultPageSize={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .entryQuantity
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .entryQuantity
                                                }
                                                current={
                                                    store.getState()
                                                        .objectsPageReducer.page
                                                }
                                                defaultCurrent={
                                                    store.getState()
                                                        .objectsPageReducer.page
                                                }
                                                onChange={this.onChangePage}
                                                total={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                        <MDBPagination>
                                            <PaginationAmountEntityShow
                                                page={
                                                    store.getState()
                                                        .objectsPageReducer.page
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .entryQuantity
                                                }
                                                count={
                                                    store.getState()
                                                        .objectsPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                    </CardBody>

                                    <CardFooter>
                                        <Link
                                            to="/objects/create"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Создать объект
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => state.objectsPageReducer
export default connect(mapStateToProps, {
    setPage,
    setQuantity,
    setSort,
    setSearch,
    setMax,
    isNeedRefresh,
})(Objects)
