import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    Button,
} from 'reactstrap'
import AvSelect from '../../components/Common/AvSelect'
import {
    AvForm,
    AvField,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import {
    EditorState,
    ContentState,
} from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import toastr from 'toastr'

const translit = (str) => {
    var space = '-'
    var link = ''
    var transl = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'sh',
        ъ: space,
        ы: 'y',
        ь: space,
        э: 'e',
        ю: 'yu',
        я: 'ya',
    }

    if (str != '') {
        str = str.toLowerCase().substr(0, 100)
    }

    for (var i = 0; i < str.length; i++) {
        if (/[а-яё]/.test(str.charAt(i))) {
            link += transl[str.charAt(i)]
        } else if (/[a-z0-9]/.test(str.charAt(i))) {
            link += str.charAt(i)
        } else {
            if (link.slice(-1) !== space) link += space
        }
    }

    return link
}

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class SettingsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список настроек', link: '/settings' },
            ],

            active: true,
            name: '',
            type: '',
            code: '',
            value: '',
            file: null,
            json: [],
            uploadedFile: null,

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputKeyChange = this.handleInputKeyChange.bind(this)
        this.handleInputValueChange = this.handleInputValueChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.addJson = this.addJson.bind(this);
        this.deleteJson = this.deleteJson.bind(this);
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios
                .get(`/settings/one?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data
                    
                    let formJSON = [];
                    if (typeof data.value === 'object') {

                        const parseJSON = (value) => {
                            let parsed;
                            try {
                                const validJson = value.replaceAll(`'`, `"`);
                                parsed = JSON.parse(validJson);
                            } catch (err) {
                                parsed = value;
                            }
                            return parsed;
                        }

                        Object.keys(data.value).forEach((key) => {
                            formJSON.push({key: key, value: parseJSON(data.value[key]) })
                        })
                    }


                    this.setState({
                        active: data.active,
                        name: data.name,
                        type: data.type,
                        code: data.code,
                        value: typeof data.value === 'object' ? '' : data.value,
                        json: typeof data.value === 'object' ? formJSON : [],
                        uploadedFile: data.file,
                    })

                    this.handleSelectChange('type', [{id: data.type, name: data.type}])
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        if (name === 'name' && !this.state.isUpdate) {
            this.setState({
                code: translit(value.trim()),
            })
        }
    }

    handleSelectChange(key, value) {
        this.setState({
            [key]: value
        })
    }

    handleInputKeyChange(event) { 
        const target = event.target
        const key = target.value
        this.state.json[target.dataset.index].key = key;
        this.setState({json: this.state.json});
    }

    handleInputValueChange(event) {
        const target = event.target
        const value = target.value
        this.state.json[target.dataset.index].value = value;
        this.setState({json: this.state.json});
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/settings/update'
                : '/settings/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/settings')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    addJson() {
        this.state.json.push({key: '', value: ''});
        this.setState({json: this.state.json});
    }

    deleteJson(i) {
        this.state.json.splice(i, 1);
        this.setState({json: this.state.json});
    }

    handleFile = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                file: file,
                uploadedFile: null,
            })
        })
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    getFormData() {
        const fd = new FormData()

        let data = {
            name: this.state.name,
            code: this.state.code,
            type: this.state.type[0].name,
            value: this.state.value,
            file: this.state.file,
            active: this.state.active,
        }

        if (data.type === 'json') {
            let jsonValue = {};
            this.state.json.forEach((item) => {
                jsonValue = {
                    ...jsonValue,
                    [item.key]: item.value,
                }
            })

            data = {
                ...data,
                value: JSON.stringify(jsonValue),
            }
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            if (this.state.uploadedFile) {
                data.file = this.state.uploadedFile
            }
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                    fd.append(key, data[key])
            }
        }

        return fd
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="settings"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="active"
                                                id="isActive"
                                                checked={this.state.active}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название"
                                name="name"
                                placeholder="Введите название"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />
                            <AvSelect
                              label="Тип"
                              name="type"
                              value={this.state.type}
                              api="/settings/type_input_list"
                              handleSelectChange={
                                this.handleSelectChange
                              }
                            />

                            <AvField
                                label="Символьный код"
                                name="code"
                                placeholder="Введите символьный код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z0-9--_]{4,255}$',
                                        errorMessage: 'Неверный символьный код',
                                    },
                                }}
                            />

                            {(this.state.type[0]?.name === 'string') && (
                            <AvField
                              label="Значение параметра"
                              name="value"
                              placeholder="value"
                              value={this.state.value}
                              onInput={this.handleInputChange}
                            />
                            )}
                            {(this.state.type[0]?.name === 'document') && (
                            <>
                            {(this.state.file || this.state.uploadedFile) && (
                                    <AvField
                                        label='Значение параметра'
                                        name="file"
                                        value={this.state.file ? this.state.file?.preview : this.state.uploadedFile ? this.state
                                                            .uploadedFile?.path : '' }
                                    />
                                )}
                                <br/>
                                <Dropzone
                                maxFiles={1}
                                multiple={false}
                                onDrop={(file) =>
                                    this.handleFile(file)
                                }
                                >
                                    {({ getRootProps, getInputProps }) => (
                                    <div
                                        className={
                                            this.state.file ||
                                            this.state.uploadedFile
                                            ? 'dropzone dropzone-single dz-max-files-reached'
                                            : 'dropzone dropzone-single'
                                        }
                                        style={{ textAlign: 'center' }}
                                    >
                                        <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>
                                                Перетащите файл или картинку сюда или
                                                кликните для загрузки
                                            </h4>
                                        </div>

                                        {this.state.file && (
                                            <div className="dz-preview dz-preview-single">
                                                <div className="dz-preview-cover">
                                                    <img
                                                    data-dz-thumbnail=""
                                                    className="dz-preview-img"
                                                    style={{
                                                        width: 'auto',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                    }}
                                                    alt={
                                                        this.state
                                                            .file
                                                            .name
                                                    }
                                                    src={
                                                        this.state
                                                            .file?.preview
                                                    }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {this.state.uploadedFile && (
                                            <div className="dz-preview dz-preview-single">
                                                <div className="dz-preview-cover">
                                                    <img
                                                    data-dz-thumbnail=""
                                                    className="dz-preview-img"
                                                    style={{
                                                        width: 'auto',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                    }}
                                                    src={
                                                        this.state
                                                            .uploadedFile?.path
                                                    }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    )}
                                </Dropzone>
                            </>
                            )}

                            {(this.state.type[0]?.name === 'json') && (
                                <>
                                    {this.state.json?.map((obj, i) => {
                                            return (
                                                <div key={i} style={{borderBottom: "1px solid #efefef", marginTop: "10px"}}>
                                                    <Card>
                                                        <CardHeader>
                                                            <h4 className="card-title mb-0">
                                                                Json
                                                            </h4>
                                                        </CardHeader>

                                                        <CardBody>
                                                        <AvField
                                                            label="Ключ"
                                                            name={"jsonKey" + i}
                                                            placeholder="Ключ"
                                                            value={this.state.json[i].key}
                                                            data-index={i}
                                                            onInput={this.handleInputKeyChange}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage:
                                                                        'Обязательно для заполнения',
                                                                },
                                                            }}
                                                        />
                                                        <AvField
                                                            label="Значение"
                                                            name={"jsonValue" + i}
                                                            placeholder="Значение"
                                                            data-index={i}
                                                            value={this.state.json[i].value}
                                                            onInput={this.handleInputValueChange}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage:
                                                                        'Обязательно для заполнения',
                                                                },
                                                            }}
                                                        />
                                                        </CardBody>
                                                    </Card>

                                                    <Row style={{width: '100%'}}>
                                                        <Col>
                                                            {i != 0 || !this.state.errorMessage ? (
                                                                <div style={{margin: "10px 0"}}>
                                                                    <button title={'Удалить'} className="btn btn-primary" onClick={this.deleteJson.bind(this, i)}><i className="mdi mdi-close"></i></button>
                                                                </div>
                                                            ) : (null)
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }
                                    )}
                                    <div className="icon-demo-content row">
                                        <div className="col-xl-3 col-lg-4 col-sm-6"
                                            onClick={() => this.addJson()}>
                                            <i className="mdi mdi-plus"></i>
                                            <span>Добавить json</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                      color="success"
                                      className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>

                </AvForm>
            </React.Fragment>
        )
    }
}

export default SettingsForm
