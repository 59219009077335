import InvestmentSites from '../pages/InvestmentSites/index'
import InvestmentSitesAdd from '../pages/InvestmentSites/add'
import InvestmentSitesEdit from '../pages/InvestmentSites/edit'

const investmentSitesRoutes = [
    { path: '/investment_sites/update/:id', component: InvestmentSitesEdit },
    { path: '/investment_sites/create', component: InvestmentSitesAdd },
    { path: '/investment_sites', component: InvestmentSites },
]

export { investmentSitesRoutes }
