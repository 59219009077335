import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'

import axios from 'axios'

class ListConstantLang extends Component {
    constructor(props) {
        super(props)

        this.state = {
            list: [],
        }
    }

    componentDidMount() {}

    render() {
        return this.props.list.map((item, i) => (
            <li key={i}>
                <Link to={'/lang/' + this.props.module + '/' + item.id}>
                    {item.name}
                </Link>
            </li>
        ))
    }
}

export default ListConstantLang
