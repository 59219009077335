import React, { Component } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import LangConstantForm from './LangConstantForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class LangConstantAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Список констант',
                    link: '/lang/' + this.props.match.params.module,
                },
                {
                    title: 'Добавить константу',
                    link: '/lang/' + this.props.match.params.module + '/create',
                },
            ],
            toggleSwitch: true,
            module: this.props.match.params.module,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новая константа"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <LangConstantForm
                            title="Создание константы"
                            module={this.state.module}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default LangConstantAdd
