import React, { Component } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Form from './form'
import axios from 'axios'

const user = localStorage.getItem('authUser');
let jsonUser = JSON.parse(user);

class RoadmapTemplateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "ExportLink", link : "/" },
        { title : "Шаблоны дорожной карты", link : "/roadmap-template" },
        { title : "Редактировать элемент", link : "/roadmap-template/update" },
      ],

      id: this.props.match.params.id
    };
  }

  componentDidMount() {
  }

  render() {
    const data = {
      columns: [],
      rows: [],
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title="Редактирование элемента" breadcrumbItems={this.state.breadcrumbItems} />

            <Form 
              title={'Редактирование элемента'} 
              id={this.state.id} 
              typeForm="edit" 
              history={this.props.history}
              method="post"
              api="/roadmap-template/update"
              redirect="/roadmap-template"
              one="/roadmap-template/one"
            />

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RoadmapTemplateEdit
