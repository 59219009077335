import { FAILURE, LOAD_HANDBOOK, REQUEST, SUCCESS } from './actions'

export const initialValues = {
    isLoading: null,
    rows: [],
    addColumns: [],
    page: 1,
    pageSize: 20,
    search: '',
    maxCount: null,
    sortBy: null,
    sortOrder: null,
    error: null,
}

const initialState = {}

export const handbookReducer = (state = initialState, action) => {
    if (!action || !action.payload) {
        return state
    }

    const { payload } = action
    const { handbookType } = action.payload
    if (handbookType && !state[handbookType]) {
        state[handbookType] = { ...initialValues }
    }

    switch (action.type) {
        case LOAD_HANDBOOK + REQUEST:
            return {
                ...state,
                [handbookType]: {
                    ...state[handbookType],
                    page: payload.page,
                    pageSize: payload.pageSize,
                    search: payload.search,
                    sortBy: payload.sortBy,
                    sortOrder: payload.sortOrder,
                    isLoading: true,
                    error: null,
                },
            }
        case LOAD_HANDBOOK + SUCCESS:
            return {
                ...state,
                [handbookType]: {
                    ...state[handbookType],
                    ...payload,
                    isLoading: false,
                },
            }
        case LOAD_HANDBOOK + FAILURE:
            return {
                ...state,
                [handbookType]: {
                    ...state[handbookType],
                    ...payload,
                    isLoading: false,
                },
            }

        default:
            return state
    }
}
