import DatatableTables from '../pages/Tables/DatatableTables'
import {
    Events,
    EventsCreate,
    EventsUpdate,
    WorkingDays,
    WorkingDaysCreate,
    WorkingDaysUpdate,
    OfficialHolidays,
    OfficialHolidaysCreate,
    OfficialHolidaysUpdate,
    UnofficialHolidays,
    UnofficialHolidaysCreate,
    UnofficialHolidaysUpdate,
} from '../pages/BusinessCalendar'

const businessCalendarRoutes = [

    {
        path: '/business_calendar/events/update/:id',
        component: EventsUpdate
    },
    {
        path: '/business_calendar/events/create',
        component: EventsCreate
    },
    {
        path: '/business_calendar/events/',
        component: Events
    },

    {
        path: '/business_calendar/working_days/update/:id',
        component: WorkingDaysUpdate,
    },
    {
        path: '/business_calendar/working_days/create',
        component: WorkingDaysCreate,
    },
    { path: '/business_calendar/working_days/', component: WorkingDays },

    {
        path: '/business_calendar/official_holidays/update/:id',
        component: OfficialHolidaysUpdate,
    },
    {
        path: '/business_calendar/official_holidays/create',
        component: OfficialHolidaysCreate,
    },
    {
        path: '/business_calendar/official_holidays/',
        component: OfficialHolidays,
    },

    {
      path: "/business_calendar/unofficial_holidays/update/:id",
      component: UnofficialHolidaysUpdate
    },
    {
      path: "/business_calendar/unofficial_holidays/create",
      component: UnofficialHolidaysCreate
    },
    {
      path: "/business_calendar/unofficial_holidays/",
      component: UnofficialHolidays
    },

    { path: '/business_calendar', component: DatatableTables },
]

export { businessCalendarRoutes }
