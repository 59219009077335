import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TagForm from './TagForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class TagEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список тегов', link: '/tag' },
                { title: 'Редактировать тег', link: '/tag/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        const data = {
            columns: [],
            rows: [],
            tag: this.state.tag || {},
            tagNmae: this.state.tag || { name: '' },
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование тега"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <TagForm
                            title={'Редактирование тега: ' + data.tagNmae.name}
                            id={this.state.id}
                            data={data.tag}
                            typeForm="edit"
                            history={this.props.history}
                            method="post"
                            api="/tag/update"
                            redirect="/tag"
                            one="/tag/one"
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default TagEdit
