/**
 * Проверяет значение на undefined и пустоту.
 *
 * @param value значение
 * @returns {boolean}
 */
export function isEmpty(value) {
    let bool = false

    if (typeof value === 'undefined') {
        bool = true
    } else if (value === null) {
        bool = true
    } else if (typeof value === 'object' && Object.keys(value).length === 0) {
        bool = true
    } else if (typeof value !== 'object' && value.length === 0) {
        bool = true
    }

    return bool
}

/**
 * Форматирует значение, для формата компонента select.
 *
 * @param value значение
 * @returns {*[]}
 */
export function formatValue(value) {
    const newValue = [];

    if (!isEmpty(value) &&
        (value.constructor === Array || value.constructor === String || value.constructor === Boolean)
    ) {
        if (value.constructor === String) {
            value = [value];
        } else if (value.constructor === Boolean) {
            value = [String(value)];
        }

        value.forEach((e, i) => {
            if (typeof e !== 'object') {
                newValue.push({ id: e })
            } else {
                newValue.push(e)
            }
        })
    }

    return newValue
}

/**
 * Формирует массив GET параметров, для род. группы из значений массива select.
 *
 * @param value массив значений select
 * @param variable наименование параметра
 * @returns {string}
 */
export function formatValueToUrlParam(value = [], variable = '') {
    value = formatValue(value)
    let urlParam = ''

    if (!isEmpty(value) && value.constructor === Array && value.length) {
        value.forEach((e) => {
            if (!isEmpty(e) && typeof e === 'object') {
                if (urlParam.length) {
                    urlParam = urlParam + '&' + variable + '[]=' + e.id
                } else {
                    urlParam = urlParam + '?' + variable + '[]=' + e.id
                }
            }
        })
    }

    return urlParam
}

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
        c
    ) {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export const translit = (str) => {
    var space = '-'
    var link = ''
    var transl = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'sh',
        ъ: space,
        ы: 'y',
        ь: space,
        э: 'e',
        ю: 'yu',
        я: 'ya',
    }

    if (str !== '') {
        str = str.toLowerCase().substr(0, 100)
    }

    for (var i = 0; i < str.length; i++) {
        if (/[а-яё]/.test(str.charAt(i))) {
            link += transl[str.charAt(i)]
        } else if (/[a-z0-9]/.test(str.charAt(i))) {
            link += str.charAt(i)
        } else {
            if (link.slice(-1) !== space) link += space
        }
    }

    return link
}

export const formatData = (dates) => {
    const date = new Date(dates)
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        'T' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
    )
}

export function getDateToday() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    const hours =
        today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    const minutes =
        today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
    const seconds =
        today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()

    return (
        yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes + ':' + seconds
    )
}

/**
 * Преобразует строковое значение в булево.
 *
 * @param string значение
 * @returns {boolean}
 */
export function stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
            return true;
        case "false":
        case "no":
        case "0":
        case null:
            return false;
        default:
            return Boolean(string);
    }
}