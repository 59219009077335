import DatatableTables from '../pages/Tables/DatatableTables'
import {
    Industry,
    IndustryCreate,
    IndustryUpdate,
    SubIndustry,
    SubIndustryCreate,
    SubIndustryUpdate,
    Production,
    ProductionCreate,
    ProductionUpdate,
    Country,
    CountryCreate,
    CountryUpdate,
    Region,
    RegionCreate,
    RegionUpdate,
    UserCountry,
    UserCountryCreate,
    UserCountryUpdate,
    DeliveryTerms,
    DeliveryTermsCreate,
    DeliveryTermsUpdate,
    PaymentTerms,
    PaymentTermsCreate,
    PaymentTermsUpdate,
    Employee,
    EmployeeCreate,
    EmployeeUpdate,
    Revenue,
    RevenueCreate,
    RevenueUpdate,
    Trademark,
    TrademarkCreate,
    TrademarkUpdate,
    Gender,
    GenderCreate,
    GenderUpdate,
    Subject,
    SubjectCreate,
    SubjectUpdate,
    Rubric,
    RubricCreate,
    RubricUpdate,
    Pictogram,
    PictogramCreate,
    PictogramUpdate,
    TypeEvent,
    TypeEventCreate,
    TypeEventUpdate,
    OrganizationType,
    OrganizationTypeCreate,
    OrganizationTypeUpdate,
    UserCategory,
    UserCategoryCreate,
    UserCategoryUpdate,
    TypeAssociations,
    TypeAssociationsCreate,
    TypeAssociationsUpdate,
    RubricAnalytics,
    RubricAnalyticsCreate,
    RubricAnalyticsUpdate,
    GraphName,
    GraphNameCreate,
    GraphNameUpdate,
    GraphConstants,
    GraphConstantsCreate,
    GraphConstantsUpdate,
    AnalyticsName,
    AnalyticsNameCreate,
    AnalyticsNameUpdate,
    AnalyticsTask,
    AnalyticsTaskCreate,
    AnalyticsTaskUpdate,
    AnalyticsSource,
    AnalyticsSourceCreate,
    AnalyticsSourceUpdate,
    TimePeriods,
    TimePeriodsCreate,
    TimePeriodsUpdate,
    Units,
    UnitsCreate,
    UnitsUpdate,
    Discharges,
    DischargesCreate,
    DischargesUpdate,
    Colors,
    ColorsCreate,
    ColorsUpdate,
    TypeInvestmentSites,
    TypeInvestmentSitesCreate,
    TypeInvestmentSitesUpdate,
    InvestmentSitesSpecialization,
    InvestmentSitesSpecializationCreate,
    InvestmentSitesSpecializationUpdate,
    ProjectCategory,
    ProjectCategoryCreate,
    ProjectCategoryUpdate,
    ProjectStatus,
    ProjectStatusCreate,
    ProjectStatusUpdate,
    ProjectType,
    ProjectTypeCreate,
    ProjectTypeUpdate,
    TypeOfWork,
    TypeOfWorkCreate,
    TypeOfWorkUpdate,
    ObjectType,
    ObjectTypeCreate,
    ObjectTypeUpdate,
    Currency,
    CurrencyCreate,
    CurrencyUpdate,
    ObjectCategory,
    ObjectCategoryCreate,
    ObjectCategoryUpdate,
    ObjectStatus,
    ObjectStatusCreate,
    ObjectStatusUpdate,
    TradePartnerType,
    TradePartnerTypeCreate,
    TradePartnerTypeUpdate,
    MainModuleName,
    MainModuleNameCreate,
    MainModuleNameUpdate,
    HolidaysAndEvents,
    HolidaysAndEventsCreate,
    HolidaysAndEventsUpdate,
    TypeExperts,
    TypeExpertsCreate,
    TypeExpertsUpdate,
    ExpertsBusinessSize,
    ExpertsBusinessSizeCreate,
    ExpertsBusinessSizeUpdate,
    Readiness,
    ReadinessCreate,
    ReadinessUpdate,
    PaymentCurrency,
    PaymentCurrencyCreate,
    PaymentCurrencyUpdate,
    RoadMap,
    RoadMapCreate,
    RoadMapUpdate,
} from '../pages/Handbook'

const handbookRoutes = [
    { path: '/handbooks/products/edit', component: DatatableTables },
    { path: '/handbooks/products/add', component: DatatableTables },
    { path: '/handbooks/products', component: DatatableTables },

    { path: '/handbooks/import_geo/edit', component: DatatableTables },
    { path: '/handbooks/import_geo/add', component: DatatableTables },
    { path: '/handbooks/import_geo', component: DatatableTables },

    { path: '/handbooks/revenue/update/:id', component: RevenueUpdate },
    { path: '/handbooks/revenue/create', component: RevenueCreate },
    { path: '/handbooks/revenue', component: Revenue },

    {
        path: '/handbooks/employee_quantity/update/:id',
        component: EmployeeUpdate,
    },
    { path: '/handbooks/employee_quantity/create', component: EmployeeCreate },
    { path: '/handbooks/employee_quantity', component: Employee },

    { path: '/handbooks/trademark/update/:id', component: TrademarkUpdate },
    { path: '/handbooks/trademark/create', component: TrademarkCreate },
    { path: '/handbooks/trademark', component: Trademark },

    {
        path: '/handbooks/payment_terms/update/:id',
        component: PaymentTermsUpdate,
    },
    { path: '/handbooks/payment_terms/create', component: PaymentTermsCreate },
    { path: '/handbooks/payment_terms', component: PaymentTerms },

    {
        path: '/handbooks/delivery_terms/update/:id',
        component: DeliveryTermsUpdate,
    },
    {
        path: '/handbooks/delivery_terms/create',
        component: DeliveryTermsCreate,
    },
    { path: '/handbooks/delivery_terms', component: DeliveryTerms },

    {
        path: '/handbooks/sub_industry/update/:id',
        component: SubIndustryUpdate,
    },
    { path: '/handbooks/sub_industry/create', component: SubIndustryCreate },
    { path: '/handbooks/sub_industry', component: SubIndustry },

    { path: '/handbooks/production/update/:id', component: ProductionUpdate },
    { path: '/handbooks/production/create', component: ProductionCreate },
    { path: '/handbooks/production', component: Production },

    { path: '/handbooks/industry/update/:id', component: IndustryUpdate },
    { path: '/handbooks/industry/create', component: IndustryCreate },
    { path: '/handbooks/industry/', component: Industry },

    { path: '/handbooks/purchaser_type/edit', component: DatatableTables },
    { path: '/handbooks/purchaser_type/add', component: DatatableTables },
    { path: '/handbooks/purchaser_type', component: DatatableTables },
    { path: '/handbooks/purchaser_status/edit', component: DatatableTables },
    { path: '/handbooks/purchaser_status/add', component: DatatableTables },
    { path: '/handbooks/purchaser_status', component: DatatableTables },

    { path: '/handbooks/site_language/edit', component: DatatableTables },
    { path: '/handbooks/site_language/add', component: DatatableTables },
    { path: '/handbooks/site_language', component: DatatableTables },

    { path: '/handbooks/country/update/:id', component: CountryUpdate },
    { path: '/handbooks/country/create', component: CountryCreate },
    { path: '/handbooks/country', component: Country },

    { path: '/handbooks/region/update/:id', component: RegionUpdate },
    { path: '/handbooks/region/create', component: RegionCreate },
    { path: '/handbooks/region', component: Region },

    {
        path: '/handbooks/user_country/update/:id',
        component: UserCountryUpdate,
    },
    { path: '/handbooks/user_country/create', component: UserCountryCreate },
    { path: '/handbooks/user_country', component: UserCountry },

    { path: '/handbooks/gender/update/:id', component: GenderUpdate },
    { path: '/handbooks/gender/create', component: GenderCreate },
    { path: '/handbooks/gender', component: Gender },

    { path: '/handbooks/subject/update/:id', component: SubjectUpdate },
    { path: '/handbooks/subject/create', component: SubjectCreate },
    { path: '/handbooks/subject', component: Subject },

    { path: '/handbooks/rubric/update/:id', component: RubricUpdate },
    { path: '/handbooks/rubric/create', component: RubricCreate },
    { path: '/handbooks/rubric', component: Rubric },

    { path: '/handbooks/pictogram/update/:id', component: PictogramUpdate },
    { path: '/handbooks/pictogram/create', component: PictogramCreate },
    { path: '/handbooks/pictogram', component: Pictogram },

    { path: '/handbooks/type_event/update/:id', component: TypeEventUpdate },
    { path: '/handbooks/type_event/create', component: TypeEventCreate },
    { path: '/handbooks/type_event', component: TypeEvent },

    {
        path: '/handbooks/organization_type/update/:id',
        component: OrganizationTypeUpdate,
    },
    {
        path: '/handbooks/organization_type/create',
        component: OrganizationTypeCreate,
    },
    { path: '/handbooks/organization_type', component: OrganizationType },

    {
        path: '/handbooks/user_category/update/:id',
        component: UserCategoryUpdate,
    },
    { path: '/handbooks/user_category/create', component: UserCategoryCreate },
    { path: '/handbooks/user_category', component: UserCategory },

    {
        path: '/handbooks/type_associations/update/:id',
        component: TypeAssociationsUpdate,
    },
    {
        path: '/handbooks/type_associations/create',
        component: TypeAssociationsCreate,
    },
    { path: '/handbooks/type_associations', component: TypeAssociations },

    {
        path: '/handbooks/type_experts/update/:id',
        component: TypeExpertsUpdate,
    },
    {
        path: '/handbooks/type_experts/create',
        component: TypeExpertsCreate,
    },
    { path: '/handbooks/type_experts', component: TypeExperts },

    {
        path: '/handbooks/experts_business_size/update/:id',
        component: ExpertsBusinessSizeUpdate,
    },
    {
        path: '/handbooks/experts_business_size/create',
        component: ExpertsBusinessSizeCreate,
    },
    { path: '/handbooks/experts_business_size', component: ExpertsBusinessSize },

    {
        path: '/handbooks/investment_sites_specialization/update/:id',
        component: InvestmentSitesSpecializationUpdate,
    },
    {
        path: '/handbooks/investment_sites_specialization/create',
        component: InvestmentSitesSpecializationCreate,
    },
    {
        path: '/handbooks/investment_sites_specialization',
        component: InvestmentSitesSpecialization,
    },

    {
        path: '/handbooks/rubric_analytics/update/:id',
        component: RubricAnalyticsUpdate,
    },
    {
        path: '/handbooks/rubric_analytics/create',
        component: RubricAnalyticsCreate,
    },
    { path: '/handbooks/rubric_analytics', component: RubricAnalytics },

    { path: '/handbooks/graph_name/update/:id', component: GraphNameUpdate },
    { path: '/handbooks/graph_name/create', component: GraphNameCreate },
    { path: '/handbooks/graph_name', component: GraphName },

    {
        path: '/handbooks/graph_constants/update/:id',
        component: GraphConstantsUpdate,
    },
    {
        path: '/handbooks/graph_constants/create',
        component: GraphConstantsCreate,
    },
    { path: '/handbooks/graph_constants', component: GraphConstants },

    {
        path: '/handbooks/analytics_name/update/:id',
        component: AnalyticsNameUpdate,
    },
    {
        path: '/handbooks/analytics_name/create',
        component: AnalyticsNameCreate,
    },
    { path: '/handbooks/analytics_name', component: AnalyticsName },

    {
        path: '/handbooks/analytics_source/update/:id',
        component: AnalyticsSourceUpdate,
    },
    {
        path: '/handbooks/analytics_source/create',
        component: AnalyticsSourceCreate,
    },
    {
        path: '/handbooks/analytics_source',
        component: AnalyticsSource,
    },

    {
        path: '/handbooks/analytics_task/update/:id',
        component: AnalyticsTaskUpdate,
    },
    {
        path: '/handbooks/analytics_task/create',
        component: AnalyticsTaskCreate,
    },
    { path: '/handbooks/analytics_task', component: AnalyticsTask },

    {
        path: '/handbooks/time_periods/update/:id',
        component: TimePeriodsUpdate,
    },
    { path: '/handbooks/time_periods/create', component: TimePeriodsCreate },
    { path: '/handbooks/time_periods', component: TimePeriods },

    { path: '/handbooks/units/update/:id', component: UnitsUpdate },
    { path: '/handbooks/units/create', component: UnitsCreate },
    { path: '/handbooks/units', component: Units },

    { path: '/handbooks/discharges/update/:id', component: DischargesUpdate },
    { path: '/handbooks/discharges/create', component: DischargesCreate },
    { path: '/handbooks/discharges', component: Discharges },

    { path: '/handbooks/colors/update/:id', component: ColorsUpdate },
    { path: '/handbooks/colors/create', component: ColorsCreate },
    { path: '/handbooks/colors', component: Colors },

    {
        path: '/handbooks/type_investment_sites/update/:id',
        component: TypeInvestmentSitesUpdate,
    },
    {
        path: '/handbooks/type_investment_sites/create',
        component: TypeInvestmentSitesCreate,
    },
    {
        path: '/handbooks/type_investment_sites',
        component: TypeInvestmentSites,
    },

    {
        path: '/handbooks/investment_sites_specialization/update/:id',
        component: InvestmentSitesSpecializationUpdate,
    },
    {
        path: '/handbooks/investment_sites_specialization/create',
        component: InvestmentSitesSpecializationCreate,
    },
    {
        path: '/handbooks/investment_sites_specialization',
        component: InvestmentSitesSpecialization,
    },

    {
        path: '/handbooks/project_category/update/:id',
        component: ProjectCategoryUpdate,
    },
    {
        path: '/handbooks/project_category/create',
        component: ProjectCategoryCreate,
    },
    { path: '/handbooks/project_category', component: ProjectCategory },

    {
        path: '/handbooks/project_status/update/:id',
        component: ProjectStatusUpdate,
    },
    {
        path: '/handbooks/project_status/create',
        component: ProjectStatusCreate,
    },
    { path: '/handbooks/project_status', component: ProjectStatus },

    {
        path: '/handbooks/project_type/update/:id',
        component: ProjectTypeUpdate,
    },
    { path: '/handbooks/project_type/create', component: ProjectTypeCreate },
    { path: '/handbooks/project_type', component: ProjectType },

    { path: '/handbooks/type_of_work/update/:id', component: TypeOfWorkUpdate },
    { path: '/handbooks/type_of_work/create', component: TypeOfWorkCreate },
    { path: '/handbooks/type_of_work', component: TypeOfWork },

    { path: '/handbooks/object_type/update/:id', component: ObjectTypeUpdate },
    { path: '/handbooks/object_type/create', component: ObjectTypeCreate },
    { path: '/handbooks/object_type', component: ObjectType },

    { path: '/handbooks/currency/update/:id', component: CurrencyUpdate },
    { path: '/handbooks/currency/create', component: CurrencyCreate },
    { path: '/handbooks/currency', component: Currency },

    {
        path: '/handbooks/object_category/update/:id',
        component: ObjectCategoryUpdate,
    },
    {
        path: '/handbooks/object_category/create',
        component: ObjectCategoryCreate,
    },
    { path: '/handbooks/object_category', component: ObjectCategory },

    {
        path: '/handbooks/object_status/update/:id',
        component: ObjectStatusUpdate,
    },
    { path: '/handbooks/object_status/create', component: ObjectStatusCreate },
    { path: '/handbooks/object_status', component: ObjectStatus },

    {
        path: '/handbooks/trade_partner_type/update/:id',
        component: TradePartnerTypeUpdate,
    },
    {
        path: '/handbooks/trade_partner_type/create',
        component: TradePartnerTypeCreate,
    },
    { path: '/handbooks/trade_partner_type', component: TradePartnerType },

    {
        path: '/handbooks/main_module_name/update/:id',
        component: MainModuleNameUpdate,
    },
    {
        path: '/handbooks/main_module_name/create',
        component: MainModuleNameCreate,
    },
    { path: '/handbooks/main_module_name', component: MainModuleName },

    {
        path: '/handbooks/holidays_and_events/update/:id',
        component: HolidaysAndEventsUpdate,
    },
    {
        path: '/handbooks/holidays_and_events/create',
        component: HolidaysAndEventsCreate,
    },
    { path: '/handbooks/holidays_and_events', component: HolidaysAndEvents },

    { path: '/handbooks/readiness/create', component: ReadinessCreate },
    { path: '/handbooks/readiness/update/:id', component: ReadinessUpdate },
    { path: '/handbooks/readiness', component: Readiness },

    { path: '/handbooks/payment_currency/update/:id', component: PaymentCurrencyUpdate },
    { path: '/handbooks/payment_currency/create', component: PaymentCurrencyCreate },
    { path: '/handbooks/payment_currency', component: PaymentCurrency },

    {
        path: '/handbooks/roadmap/update/:id',
        component: RoadMapUpdate,
    },
    {
        path: '/handbooks/roadmap/create',
        component: RoadMapCreate,
    },
    {
        path: '/handbooks/roadmap',
        component: RoadMap
    },
    
    { path: '/handbooks', component: DatatableTables },
]

export { handbookRoutes }
