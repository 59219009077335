import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ContractForm from './ContractForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class ContractUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Договоры', link: '/contract' },
                { title: 'Редактировать договор', link: '/contract/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        const data = {
            columns: [],
            rows: [],
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <ContractForm
                            title={'Редактирование'}
                            id={this.state.id}
                            typeForm="edit"
                            history={this.props.history}
                            method="post"
                            api="/contract/update"
                            redirect="/contract"
                            one="/contract/one"
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ContractUpdate
