import React from 'react'
import './styles/coloredDot.css'
import PropTypes from 'prop-types'

const ColoredDot = ({ color, radius = 25 }) => {
    return (
        <span
            className={'dot'}
            style={{
                backgroundColor: color,
                height: `${radius}px`,
                width: `${radius}px`,
            }}
        ></span>
    )
}

ColoredDot.propTypes = {
    color: PropTypes.string,
    radius: PropTypes.number,
}

export { ColoredDot }
