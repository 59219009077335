import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Progress,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../../components/Common/AvSelect'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import user from '../../../store/user'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LocalizationElementField from '../../../components/Common/LocalizationElementField'

class RoleGroupForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            code: '',
            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        if (this.props.one) {
            axios
                .get(`${this.props.one}?id=${this.props.id}`)
                .then((response) => {
                    const data = response.data.data

                    this.setState({
                        name: data.name,
                        code: data.code,
                    })

                    if (
                        typeof this.props.fields !== 'undefined' &&
                        this.props.fields.length > 0
                    ) {
                        this.props.fields.forEach((element) => {
                            this.setState({
                                [element.name]: data[element.name],
                            })
                        })
                    }
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!errors.length) {
            const data = {
                createdBy: user.userId,
                name: this.state.values.name,
                code: this.state.values.code,
            }

            if (
                typeof this.props.fields !== 'undefined' &&
                this.props.fields.length > 0
            ) {
                this.props.fields.forEach((element) => {
                    data[element.name] = this.state[element.name]
                })
            }

            if (this.props.id) {
                data.id = this.props.id
                data.updatedBy = user.userId
            }

            axios
                .request({
                    url: this.props.api,
                    method: this.props.method,
                    data: data,
                })
                .then((response) => {
                    if (response.status === 200) {
                        toastr.success(
                            `Группа <b>${this.state.values.name}</b> успешно обновлен`
                        )
                        this.props.history.push('/user_role_group')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 422 &&
                        err.response.data.errors
                    ) {
                        const errors = err.response.data.errors
                        for (var key in errors) {
                            toastr.error(`<b>` + errors[key] + `</b>`)
                        }
                    }
                    console.log(err.message)
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm onSubmit={this.handleSubmit} ref="form" id="role_group">
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0"></div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Наименование"
                                name="name"
                                placeholder="Введите наименование"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Код"
                                name="code"
                                placeholder="Введите код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z0-9--]+$',
                                        errorMessage: 'Неверный код',
                                    },
                                }}
                            />
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center justify-content-end">
                                <Button
                                    color="success"
                                    className="waves-effect waves-light"
                                >
                                    Сохранить{' '}
                                    <i className="ri-check-line align-middle ml-1"></i>
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default RoleGroupForm
