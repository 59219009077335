import React, { Component } from 'react'
import {
    AvGroup,
    AvInput,
    AvFeedback,
    AvField,
} from 'availity-reactstrap-validation'
import {
    Label,
    Button,
    InputGroup,
    InputGroupAddon,
    Col,
    Input,
} from 'reactstrap'
import './styles/checkboxTree.css'
import axios from 'axios'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import toastr from 'toastr'

class СheckboxTree extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            value: this.props.value,
            dataKey: this.props.dataKey,
            routesPublic: this.props.routesPublic,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        // this.setState({data: this.props.data});
        this.getDataRoutes()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.routesPublic !== this.props.routesPublic) {
            this.setState({ routesPublic: this.props.routesPublic })
            this.getDataRoutes()
            setTimeout(() => this.setChecked(), 1000)
        }

        if (prevProps.value && prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value })
            setTimeout(() => this.setChecked(), 1000)
        }
    }

    getDataRoutes() {
        axios
            .get(`/routes?public=${this.state.routesPublic}`)
            .then((response) => {
                const data = response.data

                this.setState({
                    data: data,
                })
            })
    }

    setChecked(data = null) {
        var node = document.querySelector('.' + this.state.dataKey)

        if (!data) {
            data = this.state.value
        }
        // console.log(data);
        if (data) {
            for (var route in data) {
                var inputRoute = node.querySelector(
                    'input[name="' +
                        this.state.dataKey +
                        '[' +
                        route +
                        '][route]"]'
                )
                var inputRead = node.querySelector(
                    'input[name="' +
                        this.state.dataKey +
                        '[' +
                        route +
                        '][read]"]'
                )
                var inputWrite = node.querySelector(
                    'input[name="' +
                        this.state.dataKey +
                        '[' +
                        route +
                        '][write]"]'
                )

                if (inputRoute) {
                    inputRoute.checked = true
                }

                if (inputRead) {
                    inputRead.checked = data[route].read
                }

                if (inputWrite) {
                    inputWrite.checked = data[route].write
                }
            }
        }
    }

    handleInputChange(event) {
        const target = event.target
        const index = target.dataset.index
        const value = target.value
        const parent = target.parentElement.parentElement
        const routeInputs = parent.querySelectorAll('input[data-type="route"]')
        const readInputs = parent.querySelectorAll('input[data-type="read"]')
        const writeInputs = parent.querySelectorAll('input[data-type="write"]')

        switch (target.dataset.type) {
            case 'route':
                if (routeInputs && routeInputs.length) {
                    routeInputs.forEach((val) => {
                        if (target.checked) {
                            val.checked = true
                        } else {
                            val.checked = false
                        }
                    })
                }
                break
            case 'read':
                if (readInputs && readInputs.length) {
                    readInputs.forEach((val) => {
                        if (target.checked) {
                            val.checked = true
                        } else {
                            val.checked = false
                        }
                    })
                }
                break
            case 'write':
                if (writeInputs && writeInputs.length) {
                    writeInputs.forEach((val) => {
                        if (target.checked) {
                            val.checked = true
                        } else {
                            val.checked = false
                        }
                    })
                }
                break
        }
    }

    handleClick(event) {
        const target = event.target
        const children = target.parentElement.querySelectorAll(
            '.tree--children'
        )

        if (target.classList.contains('col')) {
            target.classList.toggle('tree--open')
            target.classList.toggle('tree--hide')

            if (children && children.length) {
                children.forEach((val) => {
                    val.classList.toggle('hide')
                })
            }
        }
    }

    getData() {
        var obj = {}
        var node = document.querySelector('.' + this.state.dataKey)
        var children = node.querySelectorAll('input[data-type="route"]')
        for (var i = 0; i < children.length; i++) {
            var child = children[i]

            if (!child.name || !child.checked) continue

            var inputRead = node.querySelector(
                'input[name="' +
                    this.state.dataKey +
                    '[' +
                    child.value +
                    '][read]"]'
            )
            var inputWrite = node.querySelector(
                'input[name="' +
                    this.state.dataKey +
                    '[' +
                    child.value +
                    '][write]"]'
            )
            var propertyChain = child.name.replace(/\]/g, '').split('[')
            var chain = obj
            var j

            for (j = 0; j < propertyChain.length - 1; j++) {
                var property = propertyChain[j]
                chain[property] = chain[property] || {}
                chain = chain[property]
            }

            if (inputRead) {
                if (inputRead.checked) {
                    chain.read = true
                } else {
                    chain.read = false
                }
            }

            if (inputWrite) {
                if (inputWrite.checked) {
                    chain.write = true
                } else {
                    chain.write = false
                }
            }

            chain[propertyChain[j]] = child.value
        }
        return obj
    }

    getRoutesData() {
        return this.getData()
    }

    createList(item, margin, i = 0, cl = '') {
        const keys = Object.keys(item)
        return keys.map((el, key) => {
            i++
            return (
                <div
                    key={i}
                    className={'form-check tree--children ' + cl}
                    style={{ marginLeft: margin }}
                >
                    <Col
                        onClick={this.handleClick}
                        className={
                            item[el].child
                                ? 'tree--hide group-checkbox'
                                : 'group-checkbox'
                        }
                    >
                        <Input
                            name={
                                this.state.dataKey +
                                '[' +
                                item[el].route +
                                '][route]'
                            }
                            value={item[el].route}
                            data-value={item[el].route}
                            data-type="route"
                            type="checkbox"
                            className="form-check-input form-check-input"
                            id={this.state.dataKey + 'route' + item[el].route}
                            onChange={this.handleInputChange}
                        />
                        <Label
                            htmlFor={
                                this.state.dataKey + 'route' + item[el].route
                            }
                            className="form-check-label"
                            style={{ marginRight: '50px' }}
                        >
                            {item[el].name}
                        </Label>
                        <input
                            name={
                                this.state.dataKey +
                                '[' +
                                item[el].route +
                                '][read]'
                            }
                            value={true}
                            data-type="read"
                            type="checkbox"
                            className="form-check-input form-check-input"
                            id={this.state.dataKey + 'read' + item[el].route}
                            onChange={this.handleInputChange}
                        />
                        <Label
                            htmlFor={
                                this.state.dataKey + 'read' + item[el].route
                            }
                            className="form-check-label"
                            style={{ marginRight: '50px' }}
                        >
                            Read
                        </Label>
                        <input
                            name={
                                this.state.dataKey +
                                '[' +
                                item[el].route +
                                '][write]'
                            }
                            value={true}
                            data-type="write"
                            type="checkbox"
                            className="form-check-input form-check-input"
                            id={this.state.dataKey + 'write' + item[el].route}
                            onChange={this.handleInputChange}
                        />
                        <Label
                            htmlFor={
                                this.state.dataKey + 'write' + item[el].route
                            }
                            className="form-check-label"
                            style={{ marginRight: '50px' }}
                        >
                            Write
                        </Label>
                    </Col>

                    {item[el].child
                        ? this.createList(item[el].child, 10, i, 'hide')
                        : null}
                </div>
            )
        })
    }

    render() {
        return (
            <React.Fragment>
                <AvGroup className={this.state.dataKey + ' tree'}>
                    {this.createList(this.state.data, 0)}
                </AvGroup>
            </React.Fragment>
        )
    }
}

СheckboxTree.defaultProps = {
    data: [],
    value: [],
    dataKey: 'route',
    routesPublic: false,
}

export default СheckboxTree
