import Objects from '../pages/Objects/index'
import ObjectsAdd from '../pages/Objects/add'
import ObjectsEdit from '../pages/Objects/edit'

const objectsRoutes = [
    { path: '/objects/update/:id', component: ObjectsEdit },
    { path: '/objects/create', component: ObjectsAdd },
    { path: '/objects', component: Objects },
]

export { objectsRoutes }
