import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookLangAndFileForm from '../HandbookLangAndFileForm'

class ProjectCategoryCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Категория проекта',
                    link: '/handbooks/project_category',
                },
                {
                    title: 'Добавить категорию',
                    link: '/handbooks/project_category/create',
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Добавить категорию"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookLangAndFileForm
                            title="Создание категории"
                            method="post"
                            api="/handbook/project_category/create"
                            redirect="/handbooks/project_category"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ProjectCategoryCreate
