import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookColorForm from '../HandbookColorForm'

class ColorsCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Цвета', link: '/handbooks/colors' },
                { title: 'Добавить цвет', link: '/handbooks/colors/create' },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Добавить цвет"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookColorForm
                            title="Создание"
                            method="post"
                            api="/handbook/colors/create"
                            redirect="/handbooks/colors"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ColorsCreate
