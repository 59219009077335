import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ContractForm from './ContractForm'
import { Container } from 'reactstrap'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class ContractCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Договоры', link: '/contract' },
                { title: 'Добавить договор', link: '/contract/create' },
            ],
            toggleSwitch: true,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Новый договор"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <ContractForm
                            title="Создание"
                            typeForm="add"
                            method="post"
                            api="/contract/create"
                            redirect="/contract"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ContractCreate
