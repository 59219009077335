import Experts from '../pages/Experts/index'
import ExpertsAdd from '../pages/Experts/add'
import ExpertsEdit from '../pages/Experts/edit'

const expertsRoutes = [
    { path: '/experts/update/:id', component: ExpertsEdit },
    { path: '/experts/create', component: ExpertsAdd },
    { path: '/experts', component: Experts },
]

export { expertsRoutes }
