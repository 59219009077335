import React, { Component } from 'react'
import {
    AvGroup,
    AvInput,
    AvFeedback,
    AvField,
} from 'availity-reactstrap-validation'
import {
    Row,
    Col,
    Label,
    Button,
    InputGroup,
    InputGroupAddon,
    FormGroup,
} from 'reactstrap'
import AvSelect from './AvSelect'
import axios from 'axios'

class AvMultipleInputSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: this.props.value,
            options: this.props.options,
            errorMessage: !!this.props.options.errorMessage,
            selectOptions: null,
        }

        this.addInput = this.addInput.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
        this.setState({ options: this.props.options })

        if (this.props.options.api && this.props.options.api.length) {
            axios
                .get(this.props.options.api)
                .then((response) => {
                    const data = []
                    for (const d in response.data.data) {
                        data.push(response.data.data[d])
                    }

                    this.setState({
                        selectOptions: data,
                    })
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.value.length === 1 &&
            prevProps.value !== this.props.value
        ) {
            this.setState({ value: this.props.value })
        }
        if (prevProps.value.length !== this.props.value.length) {
            this.setState({ value: this.props.value })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const index = target.dataset.index
        const key = target.dataset.key
        const object = target.dataset.object
        const value = target.value

        if (!this.state.value[index]) {
            this.state.value[index] = {}
        }

        this.state.value[index][object] = value

        this.props.updateData(this.state.value)
    }

    handleSelectChange(index, key, name, value) {
        if (typeof this.state.value[index] !== 'undefined') {
            if (!this.state.value[index]) {
                this.state.value[index] = {}
            }

            this.state.value[index][key] = value[0].id
            this.props.updateData(this.state.value)
        }
    }

    addInput() {
        this.state.value.push('')
        this.setState({ value: this.props.value })
    }

    deleteInput(index) {
        if (!index && this.state.errorMessage) return

        delete this.state.value[index]

        const data = this.state.value.filter(function (el) {
            return el != null
        })

        this.props.updateData(data)
        this.setState({ value: this.state.value })
    }

    render() {
        const pattern = this.state.options.pattern
            ? this.state.options.pattern.value
            : ''
        let errorMessage = this.state.options.errorMessage

        if (pattern && this.state.options.pattern.errorMessage) {
            errorMessage = this.state.options.pattern.errorMessage
        }

        return (
            <React.Fragment>
                <AvGroup>
                    <Label htmlFor={this.state.options.label}>
                        {this.state.options.label}
                    </Label>
                    {this.state.value.map((val, i) => {
                        let id = {}
                        if (this.state.selectOptions) {
                            for (const d in this.state.selectOptions) {
                                if (
                                    this.state.value[i].id ===
                                    this.state.selectOptions[d].id
                                ) {
                                    id = this.state.selectOptions[d]
                                }
                            }
                        }

                        return (
                            <div key={i}>
                                <AvGroup>
                                    <InputGroup>
                                        <Row style={{ width: '100%' }}>
                                            <Col>
                                                <AvField
                                                    label={
                                                        this.props.options
                                                            .labelInput
                                                    }
                                                    name={'date'}
                                                    data-index={i}
                                                    data-key={val}
                                                    data-object={'date'}
                                                    placeholder="Введите дату в формате ДД.ММ.ГГ"
                                                    value={
                                                        this.state.value[i].date
                                                    }
                                                    onInput={
                                                        this.handleInputChange
                                                    }
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage:
                                                                'Обязательно для заполнения',
                                                        },
                                                        pattern: {
                                                            value:
                                                                '^(0[1-9]|1[0-9]|2[0-9]|3[01])[.](0[1-9]|1[012])[.](2[0-9]|3[0-9])$',
                                                            errorMessage:
                                                                'Неверный формат даты “ДД.ММ.ГГ”',
                                                        },
                                                    }}
                                                />
                                            </Col>

                                            <Col>
                                                <AvSelect
                                                    label={
                                                        this.props.options
                                                            .labelSelect
                                                    }
                                                    name={'id'}
                                                    data-index={i}
                                                    data-key={val}
                                                    data-object={
                                                        'selectOptions'
                                                    }
                                                    isVisible={true}
                                                    options={
                                                        this.state.selectOptions
                                                    }
                                                    value={id}
                                                    handleSelectChange={this.handleSelectChange.bind(
                                                        this,
                                                        i,
                                                        'id'
                                                    )}
                                                    typeValueMulti="one"
                                                    errorMessage="Обязательно для заполнения"
                                                />
                                            </Col>

                                            <Col>
                                                {i != 0 ||
                                                !this.state.errorMessage ? (
                                                    <FormGroup>
                                                        <Label
                                                            htmlFor={'Удалить'}
                                                        >
                                                            {'Удалить'}
                                                        </Label>
                                                        <InputGroupAddon
                                                            addonType="append"
                                                            onClick={this.deleteInput.bind(
                                                                this,
                                                                i
                                                            )}
                                                        >
                                                            <div className="btn btn-primary">
                                                                <i className="mdi mdi-close"></i>
                                                            </div>
                                                        </InputGroupAddon>
                                                    </FormGroup>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </InputGroup>
                                </AvGroup>
                            </div>
                        )
                    })}
                    <div className="icon-demo-content row">
                        <div
                            className="col-xl-3 col-lg-4 col-sm-6"
                            onClick={this.addInput}
                        >
                            <i className="mdi mdi-plus"></i>
                            <span>Добавить</span>
                        </div>
                    </div>
                </AvGroup>
            </React.Fragment>
        )
    }
}

AvMultipleInputSelect.defaultProps = {
    value: [{ id: '', date: '' }],
    options: {
        label: 'input',
        name: 'name',
        labelInput: 'input',
        labelSelect: 'select',
        errorMessage: false,
        pattern: false,
        api: '',
    },
}

export default AvMultipleInputSelect
