import React, { Component, createRef } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    FormGroup,
    CustomInput,
    Button,
} from 'reactstrap'
import Select from 'react-select'
import AvSelect from '../../components/Common/AvSelect'
import {
    AvForm,
    AvGroup,
    AvField,
    AvInput,
    AvFeedback,
} from 'availity-reactstrap-validation'
import axios from 'axios'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateFromHTML } from 'draft-js-import-html'
import { renameToSelect } from '../../helpers/helpers'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import toastr from 'toastr'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.filter((el) => el).map((el) => ids.push(el.id))
    return ids
}

const formatData = (dates) => {
    const date = new Date(dates)
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        'T' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
    )
}

const translit = (str) => {
    var space = '-'
    var link = ''
    var transl = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'sh',
        ъ: space,
        ы: 'y',
        ь: space,
        э: 'e',
        ю: 'yu',
        я: 'ya',
    }

    if (str != '') {
        str = str.toLowerCase().substr(0, 100)
    }

    for (var i = 0; i < str.length; i++) {
        if (/[а-яё]/.test(str.charAt(i))) {
            link += transl[str.charAt(i)]
        } else if (/[a-z0-9]/.test(str.charAt(i))) {
            link += str.charAt(i)
        } else {
            if (link.slice(-1) !== space) link += space
        }
    }

    return link
}

const getDateToday = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    const hours =
        today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    const minutes =
        today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
    const seconds =
        today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()

    return (
        yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes + ':' + seconds
    )
}

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class SchedulerForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список планировщика ', link: '/scheduler' },
            ],

            name: '',
            command: '',
            cron_expression: '',
            last_execution: '',
            arguments: '',
            last_return_code: '0',
            log_file: '',
            priority: 500,
            execute_immediately: false,
            disabled: false,
            locked: false,

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios.get(`/scheduler/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data

                this.setState({
                    name: data.name || this.state.name,
                    command: data.command || this.state.command,
                    cron_expression:
                        data.cron_expression || this.state.cron_expression,
                    last_execution: data.last_execution.replace(' ', 'T'),
                    arguments: data.arguments || this.state.arguments,
                    last_return_code:
                        data.last_return_code || this.state.last_return_code,
                    log_file: data.log_file || this.state.log_file,
                    priority: data.priority || this.state.priority,
                    execute_immediately:
                        data.execute_immediately ||
                        this.state.execute_immediately,
                    disabled: data.disabled || this.state.disabled,
                    locked: data.locked || this.state.locked,
                })
            })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/scheduler/update'
                : '/scheduler/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/scheduler')
                    }
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    const errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            name: this.state.name,
            command: this.state.command,
            cronExpression: this.state.cron_expression,
            lastExecution: this.state.last_execution.replace('T', ' '),
            arguments: this.state.arguments,
            lastReturnCode: this.state.last_return_code,
            logFile: this.state.log_file,
            priority: this.state.priority,
            executeImmediately: this.state.execute_immediately,
            disabled: this.state.disabled,
            locked: this.state.locked,
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (typeof data[key] === 'object' && data[key] !== null) {
                    data[key].map((el) => fd.append(`${key}[]`, el))
                } else {
                    if (data[key] && data[key] !== '') {
                        fd.append(key, data[key])
                    }
                }
            }
        }

        return fd
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="associations"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="name"
                                name="name"
                                placeholder="name"
                                value={this.state.name}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="command"
                                name="command"
                                placeholder="command (app:command)"
                                value={this.state.command}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="cron_expression"
                                name="cron_expression"
                                placeholder="cron_expression (*/1 * * * *)"
                                value={this.state.cron_expression}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="last_execution"
                                name="last_execution"
                                type="datetime-local"
                                value={this.state.last_execution}
                                onInput={(event) =>
                                    this.setState({
                                        last_execution: formatData(
                                            event.target.value
                                        ),
                                    })
                                }
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="arguments"
                                name="arguments"
                                placeholder="arguments (--help)"
                                value={this.state.arguments}
                                onInput={this.handleInputChange}
                            />

                            <AvField
                                label="last_return_code"
                                name="last_return_code"
                                placeholder="last_return_code"
                                value={String(this.state.last_return_code)}
                                onInput={this.handleInputChange}
                                type="number"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="log_file"
                                name="log_file"
                                placeholder="log_file (namelog.log)"
                                value={this.state.log_file}
                                onInput={this.handleInputChange}
                            />

                            <AvField
                                label="priority"
                                name="priority"
                                placeholder="priority number"
                                value={this.state.priority}
                                onInput={this.handleInputChange}
                                type="number"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <div className="flex-shrink-0">
                                <div className="d-flex">
                                    <div className="custom-control custom-switch ml-4">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="execute_immediately"
                                            id="execute_immediately"
                                            checked={
                                                this.state.execute_immediately
                                            }
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="execute_immediately"
                                        >
                                            execute_immediately
                                        </Label>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-shrink-0">
                                <div className="d-flex">
                                    <div className="custom-control custom-switch ml-4">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="disabled"
                                            id="disabled"
                                            checked={this.state.disabled}
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="disabled"
                                        >
                                            disabled
                                        </Label>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-shrink-0">
                                <div className="d-flex">
                                    <div className="custom-control custom-switch ml-4">
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="locked"
                                            id="locked"
                                            checked={this.state.locked}
                                            onChange={this.handleInputChange}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="locked"
                                        >
                                            locked
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default SchedulerForm
