import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import HandbookList from '../HandbookList'

class ObjectType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Вид объекта', link: '/handbooks/object_type' },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Список"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookList
                            type="object_type"
                            create="Добавить вид объекта"
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ObjectType
