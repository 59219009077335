import { MDBDataTable } from 'mdbreact'
import React from 'react'
import PropTypes from 'prop-types'
import Pagination from './Pagination'
import { PaginationAmountEntityShow } from './PaginationAmountEntityShow'

const MDBDataTablePaginationWrapper = (props) => {
    // eslint-disable-next-line react/prop-types
    let { showPagingInfo, showPagination } = props
    // eslint-disable-next-line react/prop-types
    const { maxCount, currentPage, setCurrentPage, pageSize } = props
    const childProps = { ...props }

    showPagination = showPagination && maxCount > 0
    showPagingInfo = showPagingInfo && maxCount > 0

    const toDeleteProps = [
        'showPagingInfo',
        'showPagination',
        'maxCount',
        'currentPage',
        'setCurrentPage',
        'pageSize',
        'itemsQuantity',
    ]

    toDeleteProps.forEach((prop) => {
        if (
            typeof childProps[prop] !== 'undefined' ||
            childProps[prop] !== null
        ) {
            delete childProps[prop]
        }
    })

    const onPageChange = (page, pageSize) => {
        // todo implement usage of pageSize
        setCurrentPage(page)
    }

    return (
        <React.Fragment>
            <MDBDataTable {...childProps} />
            <div className="row">
                {showPagingInfo && (
                    <div
                        data-test="datatable-info"
                        className="col-sm-12 col-md-5"
                    >
                        <div
                            className="dataTables_info"
                            role="status"
                            aria-live="polite"
                        >
                            <PaginationAmountEntityShow
                                page={currentPage}
                                count={maxCount}
                                pageSize={pageSize}
                            />
                        </div>
                    </div>
                )}
                {showPagination && (
                    <Pagination
                        totalCount={maxCount}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
        </React.Fragment>
    )
}

MDBDataTablePaginationWrapper.propTypes = {
    maxCount: PropTypes.number,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    pageSize: PropTypes.number,
    itemsQuantity: PropTypes.number,
    showPagingInfo: PropTypes.bool,
    showPagination: PropTypes.bool,
}

export { MDBDataTablePaginationWrapper }
