import React, { Component } from 'react'
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Label,
    Button,
} from 'reactstrap'

import AvSelect from '../../components/Common/AvSelect'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from 'axios'
import { EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import toastr from 'toastr'

const getIdFromSelect = (arr) => {
    arr = arr || []
    const ids = []
    arr.map((el) => ids.push(el.id))
    return ids
}

const formatData = (dates) => {
    let date = new Date(dates)
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        'T' +
        hours +
        ':' +
        minutes
    )
}

const translit = (str) => {
    var space = '-'
    var link = ''
    var transl = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'sh',
        ъ: space,
        ы: 'y',
        ь: space,
        э: 'e',
        ю: 'yu',
        я: 'ya',
    }

    if (str != '') {
        str = str.toLowerCase().substr(0, 100)
    }

    for (var i = 0; i < str.length; i++) {
        if (/[а-яё]/.test(str.charAt(i))) {
            link += transl[str.charAt(i)]
        } else if (/[a-z0-9]/.test(str.charAt(i))) {
            link += str.charAt(i)
        } else {
            if (link.slice(-1) !== space) link += space
        }
    }

    return link
}

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class ProductsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список продукции', link: '/products' },
            ],

            isActive: true,
            nameRu: '',
            nameEn: '',
            lang: [],

            isUpdate: this.props.id,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentDidMount() {
        if (this.state.isUpdate) {
            axios.get(`/products/one?id=${this.props.id}`).then((response) => {
                const data = response.data.data

                this.setState({
                    isActive: data.active,
                    nameRu: data.name_ru,
                    nameEn: data.name_en,
                    code: data.code,
                    name: data.name,
                })

                if (data.lang.id) {
                    this.setState({ lang: [data.lang] })
                } else {
                    this.setState({ lang: [{ id: 'null', name: 'Все' }] })
                }
            })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value =
            target.type === 'checkbox' || target.type === 'radio'
                ? target.checked
                : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        if (name === 'nameRu' && !this.state.isUpdate) {
            this.setState({
                code: translit(value.trim()),
            })
        }
    }

    handleSelectChange(key, value, child) {
        this.setState({
            [key]: value,
        })

        if (child) {
            const ids = getIdFromSelect(value)

            const requests = ids.map((id) => {
                return axios.get(
                    `/handbook/${child.key}/input_list?${key}[]=${id}`
                )
            })

            const data = []

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        responses.map((request) => {
                            request.data.data.map((item) => data.push(item))
                        })
                    })
                )
                .finally(() => {
                    this.setState({
                        [child.options]: data,
                    })
                })
        }
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values })

        if (!this.state.errors.length) {
            const fd = this.getFormData()
            const url = this.state.isUpdate
                ? '/products/update'
                : '/products/create'

            axios
                .post(url, fd)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push('/products')
                    }
                })
                .catch((err) => {
                    if (
                        err.response.status === 409 &&
                        err.response.data.message == 'unique'
                    ) {
                        toastr.error(
                            `<b>Символьный код должен быть уникальным!</b>`
                        )
                    } else if (err.response.data.message) {
                        toastr.error(`<b>` + err.response.data.message + `</b>`)
                    }
                    let errors = err.response.data.errors
                    for (var key in errors) {
                        toastr.error(`<b>` + key + ':' + errors[key] + `</b>`)
                    }
                    console.log(err.message)
                })
        }
    }

    handleAcceptedPreview = (files) => {
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })

            this.setState({
                previewPicture: file,
                uploadedPreview: null,
            })
        })
    }

    getEditorContent(text) {
        const blocksFromHtml = htmlToDraft(text)
        const { contentBlocks, entityMap } = blocksFromHtml

        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    getDateToday() {
        let today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        const yyyy = today.getFullYear()
        const hours =
            today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
        const minutes =
            today.getMinutes() < 10
                ? '0' + today.getMinutes()
                : today.getMinutes()
        const seconds =
            today.getSeconds() < 10
                ? '0' + today.getSeconds()
                : today.getSeconds()

        return yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes
    }

    getFormData() {
        const fd = new FormData()

        const data = {
            createdBy: jsonUser.userId,
            name: this.state.name,
            code: this.state.code,
            name_ru: this.state.nameRu,
            name_en: this.state.nameEn,
            active: this.state.isActive,
            lang: getIdFromSelect(this.state.lang)[0],
        }

        if (this.state.isUpdate) {
            data.id = this.props.id
            data.updatedBy = jsonUser.userId

            // if (this.state.uploadedPreview) {
            //   data.previewPicture = this.state.uploadedPreview.id;
            // }
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (
                    typeof data[key] === 'object' &&
                    data[key] !== null &&
                    key !== 'previewPicture'
                ) {
                    data[key].map((el) => fd.append(`${key}[]`, el))
                } else {
                    fd.append(key, data[key])
                }
            }
        }

        return fd
    }

    onEditorStateChange(field, editorState) {
        this.setState({ [field]: editorState })
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }

    render() {
        return (
            <React.Fragment>
                <AvForm
                    onSubmit={this.handleSubmit}
                    ref="form"
                    id="associations"
                >
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h4 className="card-title mb-0">
                                        {this.props.name || this.state.name}
                                    </h4>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex">
                                        <div className="custom-control custom-switch ml-4">
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="isActive"
                                            >
                                                Активность
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <AvField
                                label="Название Рус."
                                name="nameRu"
                                placeholder="Введите название Рус."
                                value={this.state.nameRu}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Название Англ."
                                name="nameEn"
                                placeholder="Введите название Англ."
                                value={this.state.nameEn}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                }}
                            />

                            <AvField
                                label="Символьный код"
                                name="code"
                                placeholder="Введите символьный код"
                                value={this.state.code}
                                onInput={this.handleInputChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'Обязательно для заполнения',
                                    },
                                    pattern: {
                                        value: '^[a-z0-9--]{4,255}$',
                                        errorMessage: 'Неверный символьный код',
                                    },
                                }}
                            />

                            <AvSelect
                                label="Где показывать"
                                name="lang"
                                optionNew={[{ id: 'null', name: 'Все' }]}
                                value={this.state.lang}
                                api="/localization/lang/input_list?main=true"
                                handleSelectChange={this.handleSelectChange}
                                errorMessage="Обязательно для заполнения"
                            />
                        </CardBody>

                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1" />
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        className="waves-effect waves-light"
                                    >
                                        Сохранить{' '}
                                        <i className="ri-check-line align-middle ml-1"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </AvForm>
            </React.Fragment>
        )
    }
}

export default ProductsForm
