import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import EventsForm from './EventsForm'

class EventsEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Список мероприятий', link: '/events' },
                { title: 'Редактировать мероприятие', link: '/events/update' },
            ],

            id: this.props.match.params.id,
        }
    }

    render() {
        const data = {
            columns: [],
            rows: [],
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование мероприятия"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <EventsForm
                            id={this.state.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default EventsEdit
