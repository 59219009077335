import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookForm from '../HandbookForm'

class DischargesUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'Разряды', link: '/handbooks/discharges' },
                {
                    title: 'Редактировать разряд',
                    link: '/handbooks/discharges/update',
                },
            ],
            fields: [
                {
                    label: 'Код',
                    name: 'code',
                    placeholder: 'Введите код',
                    validate: {
                        required: {
                            value: true,
                            errorMessage: 'Обязательно для заполнения',
                        },
                        pattern: {
                            value: '^[0-9]+$',
                            errorMessage: 'Неверный код (Только цифры)',
                        },
                    },
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование разряда"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookForm
                            title="Редактирование разряда"
                            method="post"
                            api="/handbook/discharges/update"
                            redirect="/handbooks/discharges"
                            one="/handbook/discharges/one"
                            fields={this.state.fields}
                            id={this.props.match.params.id}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default DischargesUpdate
