import React, { Component } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from 'reactstrap'
import HandbookMainModuleNameForm from '../HandbookMainModuleNameForm'

class MainModuleNameCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Наименования главных модулей',
                    link: '/handbooks/main_module_name',
                },
                {
                    title: 'Добавить модуль',
                    link: '/handbooks/main_module_name/create',
                },
            ],
            fields: [],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Добавить"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <HandbookMainModuleNameForm
                            title="Создание"
                            method="post"
                            api="/handbook/main_module_name/create"
                            redirect="/handbooks/main_module_name"
                            fields={this.state.fields}
                            history={this.props.history}
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default MainModuleNameCreate
