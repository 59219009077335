import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import EventsForm from './EventsForm'
import axios from 'axios'

const user = localStorage.getItem('authUser')
const jsonUser = JSON.parse(user)

class EventsEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                { title: 'События', link: '/business_calendar/events' },
                {
                    title: 'Редактировать событие',
                    link: '/business_calendar/events/update',
                },
            ],

            id: this.props.match.params.id,
        }
    }

    componentDidMount() {}

    render() {
        const data = {
            columns: [],
            rows: [],
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Редактирование события"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <EventsForm
                            title={'Редактирование события'}
                            id={this.state.id}
                            typeForm="edit"
                            history={this.props.history}
                            method="post"
                            api="/business_calendar/events/update"
                            redirect="/business_calendar/events"
                            one="/business_calendar/events/one"
                        />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default EventsEdit
