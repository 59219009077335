import React, { Component } from 'react'
import {
    AvGroup,
    AvInput,
    AvFeedback,
    AvField,
} from 'availity-reactstrap-validation'
import { Label, Button, InputGroup, InputGroupAddon } from 'reactstrap'

class AvMultipleInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: this.props.value,
            options: this.props.options,
            errorMessage: !!this.props.options.errorMessage,
        }

        this.addInput = this.addInput.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
        this.setState({ options: this.props.options })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.value.length === 1 &&
            prevProps.value !== this.props.value
        ) {
            this.setState({ value: this.props.value })
        }
        if (prevProps.value.length !== this.props.value.length) {
            this.setState({ value: this.props.value })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const index = target.dataset.index
        const value = target.value

        if (typeof this.state.value[index] !== 'undefined') {
            this.state.value[index] = value
            this.props.updateData(this.state.value)
        }
    }

    addInput() {
        this.state.value.push('')
        this.setState({ value: this.props.value })
    }

    deleteInput(index) {
        if (!index && this.state.errorMessage) return

        delete this.state.value[index]

        const data = this.state.value.filter(function (el) {
            return el != null
        })

        this.props.updateData(data)
        this.setState({ value: this.state.value })
    }

    render() {
        const pattern = this.state.options.pattern
            ? this.state.options.pattern.value
            : ''
        let errorMessage = this.state.options.errorMessage

        if (pattern && this.state.options.pattern.errorMessage) {
            errorMessage = this.state.options.pattern.errorMessage
        }

        return (
            <React.Fragment>
                <AvGroup>
                    <Label htmlFor={this.state.options.label}>
                        {this.state.options.label}
                    </Label>
                    {Array.isArray(this.state.value) && this.state.value.map((val, i) => {
                        return (
                            <div key={i}>
                                <AvGroup>
                                    <InputGroup>
                                        <AvInput
                                            name={
                                                this.state.options.name +
                                                '[' +
                                                i +
                                                ']'
                                            }
                                            value={val}
                                            onInput={this.handleInputChange}
                                            data-index={i}
                                            required={!!errorMessage}
                                            pattern={pattern}
                                        ></AvInput>
                                        {i != 0 || !this.state.errorMessage ? (
                                            <InputGroupAddon
                                                addonType="append"
                                                onClick={this.deleteInput.bind(
                                                    this,
                                                    i
                                                )}
                                            >
                                                <div className="btn btn-primary">
                                                    <i className="mdi mdi-close"></i>
                                                </div>
                                            </InputGroupAddon>
                                        ) : null}
                                        <AvFeedback>{errorMessage}</AvFeedback>
                                    </InputGroup>
                                </AvGroup>
                            </div>
                        )
                    })}
                    <div className="icon-demo-content row">
                        <div
                            className="col-xl-3 col-lg-4 col-sm-6"
                            onClick={this.addInput}
                        >
                            <i className="mdi mdi-plus"></i>
                            <span>Добавить</span>
                        </div>
                    </div>
                </AvGroup>
            </React.Fragment>
        )
    }
}

AvMultipleInput.defaultProps = {
    value: [''],
    options: {
        label: 'input',
        name: 'name',
        errorMessage: false,
        pattern: false,
    },
}

export default AvMultipleInput
