import React, { Component } from 'react'
import { MDBDataTable, MDBPagination } from 'mdbreact'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DropdownControls from '../../components/Common/DropdownControls'
import axios from 'axios'
import toastr from 'toastr'
import { logoutUser } from '../../store/actions'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './style.css'
import store from '../../store'
import {
    setMax,
    setPage,
    setQuantity,
    setSearch,
    setSort,
    isNeedRefresh,
} from '../../store/constant-page/actionsCreators'
import { connect } from 'react-redux'

const user = localStorage.getItem('authUser')
let jsonUser = JSON.parse(user)

class LangConstant extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: 'ExportLink', link: '/' },
                {
                    title: 'Список констант',
                    link: '/lang/' + this.props.match.params.module,
                },
            ],
            langName: 'Список констант',
            isNeedRerender: false,
            rows: [],
        }
    }

    refreshData = () => {
        const pageStore = store.getState().constantPageReducer
        axios
            .get(
                '/localization/constant/list?module=' +
                    this.props.match.params.module +
                    `&quantity=${pageStore.entryQuantity}&` +
                    `page=${pageStore.page}&` +
                    `search=${pageStore.search}&` +
                    `sort_by=${pageStore.currentSortBy || ''}&` +
                    `sort_order=${pageStore.currentSortOrder || ''}`
            )
            .then((response) => {
                if (response.status >= 200) {
                    const data = response.data.data
                    store.dispatch(setMax(response.data.maxCount))

                    for (let i = 0; i < data.length; i++) {
                        data[i].controls = (
                            <DropdownControls
                                id={data[i].id}
                                data={this.state.rows}
                                actions={{
                                    update:
                                        `/lang/` +
                                        this.props.match.params.module +
                                        `/update/${data[i].id}`,
                                    delete: `/localization/constant/delete`,
                                }}
                            />
                        )

                        if (data[i].active) {
                            data[i].active = 'Да'
                        } else {
                            data[i].active = 'Нет'
                        }
                    }

                    this.initMenu()
                    this.setState({ rows: data })
                }
            })
            .catch((err) => {
                // let message;
                // if (err.response && err.response.status) {
                //   switch (err.response.status) {
                //     case 401: message = "Пользователь с введенными данными не найден"; break;
                //     case 403: message = "Не хватает прав. Обратитесь к администратору"; break;
                //     default: message = err[1]; break;
                //   }
                //   window.location.href = '/logout';
                // }
                // throw message;
            })
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate(prevProps) {
        if (store.getState().constantPageReducer.isNeedRefresh) {
            store.dispatch(isNeedRefresh(false))
            this.refreshData()
        }

        if (prevProps !== this.props) {
            this.refreshData()
        }
    }

    initMenu() {
        var matchingMenuItem = null
        var ul = document.getElementById('side-menu')
        var items = ul.getElementsByTagName('a')
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                //break;
            }
            items[i].classList.remove('active')
        }

        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem)
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add('active')
        const parent = item.parentElement

        if (parent) {
            parent.classList.add('mm-active')
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add('mm-show')

                const parent3 = parent2.parentElement

                if (parent3) {
                    parent3.classList.add('mm-active') // li
                    parent3.childNodes[0].classList.add('mm-active') //a
                    const parent4 = parent3.parentElement

                    if (parent4) {
                        parent4.classList.add('mm-active')
                        parent4.classList.add('mm-show')
                        const parent5 = parent4.parentElement

                        if (parent5) {
                            parent5.classList.add('mm-active')
                        }
                    }
                }
            }
            return false
        }
        return false
    }

    onChangePage = (current) => {
        store.dispatch(setPage(Number(current)))
        store.dispatch(isNeedRefresh(true))
        // this.setState({ isNeedRerender: true });
    }

    onChangePerPage = (value) => {
        store.dispatch(setQuantity(Number(value.target.value)))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
        this.setState({ isNeedRerender: true })
    }

    onChangeSearch = (value) => {
        store.dispatch(setSearch(value.target.value))
        store.dispatch(setPage(1))
        store.dispatch(isNeedRefresh(true))
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Дата создания',
                    field: 'date_create',
                    sort: store.getState().constantPageReducer.date_create,
                    width: 150,
                },
                {
                    label: 'Дата обновления',
                    field: 'date_update',
                    sort: store.getState().constantPageReducer.date_update,
                    width: 150,
                },
                {
                    label: 'Наименование',
                    field: 'name',
                    sort: store.getState().constantPageReducer.name,
                    width: 150,
                },
                {
                    label: 'Автор',
                    field: 'user_name',
                    sort: 'disabled',
                    width: 150,
                },
                {
                    label: 'Последнее изменение',
                    field: 'user_update',
                    sort: 'disabled',
                    width: 150,
                },             
                {
                    label: 'Активность',
                    field: 'active',
                    sort: store.getState().constantPageReducer.active,
                    width: 150,
                },
                {
                    label: '',
                    field: 'controls',
                    sort: 'disabled',
                    width: 150,
                },
            ],
            rows: this.state.rows,
        }

        const Table = () => {
            if (this.state.isNeedRerender) {
                setTimeout(() => {
                    this.setState({ isNeedRerender: false })
                }, 10)
                return <></>
            }

            return (
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    sortable
                    btn
                    displayEntries={false}
                    searching={false}
                    onSort={(res) => {
                        if (res.direction && res.column) {
                            const data = {
                                currentSortBy: res.column,
                                currentSortOrder: res.direction,
                                [res.column]:
                                    res.direction === 'asc' ? 'desc' : 'asc',
                            }
                            for (const key in store.getState()
                                .constantPageReducer) {
                                if (
                                    key === 'date_update' ||
                                    key === 'date_create' ||
                                    key === 'name' ||
                                    key === 'active'
                                ) {
                                    if (key !== res.column) {
                                        data[key] = ''
                                    }
                                }
                            }

                            store.dispatch(setSort(data))
                            store.dispatch(isNeedRefresh(true))
                        }
                    }}
                    entries={store.getState().constantPageReducer.entryQuantity}
                    data={data}
                    info={false}
                    paginationLabel={['']}
                />
            )
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.langName}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Link
                                            to={
                                                '/lang/' +
                                                this.props.match.params.module +
                                                '/create'
                                            }
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Добавить константу
                                        </Link>
                                    </CardHeader>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <div>{'Show entries'}</div>
                                            <select
                                                className="browser-default custom-select"
                                                style={{ width: '100px' }}
                                                value={
                                                    store.getState()
                                                        .constantPageReducer
                                                        .entryQuantity
                                                }
                                                onChange={this.onChangePerPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                            </select>
                                        </div>
                                        <div
                                            style={{
                                                margin: '20px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <input
                                                style={{ width: '150px' }}
                                                value={
                                                    store.getState()
                                                        .constantPageReducer
                                                        .search
                                                }
                                                onChange={this.onChangeSearch}
                                                placeholder={'search'}
                                            />
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className={'constant-table'}>
                                            {Table()}
                                        </div>
                                        <MDBPagination>
                                            <Pagination
                                                defaultPageSize={
                                                    store.getState()
                                                        .constantPageReducer
                                                        .entryQuantity
                                                }
                                                pageSize={
                                                    store.getState()
                                                        .constantPageReducer
                                                        .entryQuantity
                                                }
                                                current={
                                                    store.getState()
                                                        .constantPageReducer.page
                                                }
                                                defaultCurrent={
                                                    store.getState()
                                                        .constantPageReducer.page
                                                }
                                                onChange={this.onChangePage}
                                                total={
                                                    store.getState()
                                                        .constantPageReducer
                                                        .maxCount
                                                }
                                            />
                                        </MDBPagination>
                                        <MDBPagination>
                                            {`Показаны ${
                                                1 +
                                                (store.getState()
                                                    .constantPageReducer.page -
                                                    1) *
                                                    store.getState()
                                                        .constantPageReducer
                                                        .entryQuantity
                                            }` +
                                                ` - ${
                                                    1 +
                                                        store.getState()
                                                            .constantPageReducer
                                                            .page *
                                                            store.getState()
                                                                .constantPageReducer
                                                                .entryQuantity >
                                                    store.getState()
                                                        .constantPageReducer
                                                        .maxCount
                                                        ? store.getState()
                                                              .constantPageReducer
                                                              .maxCount
                                                        : store.getState()
                                                              .constantPageReducer
                                                              .page *
                                                          store.getState()
                                                              .constantPageReducer
                                                              .entryQuantity
                                                } записи из ${
                                                    store.getState()
                                                        .constantPageReducer
                                                        .maxCount
                                                }`}
                                        </MDBPagination>
                                    </CardBody>

                                    <CardFooter>
                                        <Link
                                            to={
                                                '/lang/' +
                                                this.props.match.params.module +
                                                '/create'
                                            }
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Добавить константу
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => state.constantPageReducer
export default connect(mapStateToProps, {
    setPage,
    setQuantity,
    setSort,
    setSearch,
    setMax,
    isNeedRefresh,
})(LangConstant)
