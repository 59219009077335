import React, { Component } from "react"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Form from './form'
import {
  Container,
} from "reactstrap"

const user = localStorage.getItem('authUser');
let jsonUser = JSON.parse(user);

class RoadmapTemplateAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems : [
        { title : "ExportLink", link : "/" },
        { title : "Шаблоны дорожной карты", link : "/roadmap-template" },
        { title : "Добавить элемент", link : "/roadmap-template/create" },
      ],
      toggleSwitch: true,
    };
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title="Новый элемент" breadcrumbItems={this.state.breadcrumbItems} />

            <Form 
              title="Создание элемента" 
              typeForm="add" 
              method="post"
              api="/roadmap-template/create"
              redirect="/roadmap-template"
              history={this.props.history}
            />

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RoadmapTemplateAdd
