import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

const SearchWidget = ({ value, placeholder, onChangeSearch }) => {
    const [search, setSearch] = useState(value)

    const debounce = (func) => {
        let timer
        return function (...args) {
            const context = this
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 300)
        }
    }

    const optimizedFn = useCallback(debounce(onChangeSearch), [])

    const handleChange = (value) => {
        setSearch(value)
        optimizedFn(value)
    }

    return (
        <React.Fragment>
            <input
                style={{ width: '150px' }}
                className={'form-control'}
                value={search}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
            />
        </React.Fragment>
    )
}

SearchWidget.defaultProps = {
    placeholder: 'search',
    value: '',
    onChangeSearch: () => {},
}

SearchWidget.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeSearch: PropTypes.func,
}

export { SearchWidget }
